import {
   Card,
   CardHeader,
   CardContent,
   CardMedia,
   IconButton,
   Skeleton,
   TextareaAutosize,
} from "@mui/material";
import React from "react";
import { Avatar, Typography, Divider } from "@mui/material";
import {
   FavoriteBorderOutlined,
   MoneyOutlined,
   Send
} from "@material-ui/icons";

import { commentDuration, API_URL } from "../Utilities/index";
import { CheckCircleOutlineOutlined, Money } from "@mui/icons-material";
import { getGiveawayWinners } from "../services/giveaway"  // get those who benefited from giveaway
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';



function separator(numb) {
   var str = numb.toString().split(".");
   str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   return str.join(".");
}



// render giveaways
export function ALLPOSTS(props) {
   const {
      loading = false,
      data,
      // handleUnlikes,
      // handleLike,
      history,
      toggleDrawer,
      state,
      dispFeedView
   } = props;

   let label = "";

   if (data) {
      if (data.type == "GIVEAWAY" && data.data.post.amount != null) {
         label = `Giveaway`;
      } else if (data.type == "POST") {
      } else if (data.type == "EVENT") {
         label = `${data.data.post.date.date} |  ${data.data.post.date.time}`;
      }
   }
   return (
      <Card
         style={{
            width: "100%",
            marginLeft: "0px",
            borderRadius: "0px",
            padding: "0px",
            background: "rgb(244 244 244)"
         }}
         sx={{ m: 2 }}
      >
         <CardHeader
            avatar={
               loading ? (
                  <Skeleton
                     animation="wave"
                     variant="circular"
                     width={40}
                     height={40}
                  />
               ) : (
                  <Avatar
                     onDoubleClick={() => {
                        // console.log("hello");
                     }}
                     alt="Ted talk"
                  // src={data.poster.avater}
                  />
               )
            }
            action={
               loading ? null : (
                  <>
                     {" "}
                     {/* <MoreHorizOutlined style={{ fontSize: " 30px" }} />{" "} */}
                  </>
               )
            }
            title={
               loading ? (
                  <Skeleton
                     animation="wave"
                     height={10}
                     width="80%"
                     style={{ marginBottom: 6 }}
                  />
               ) : (
                  <div><b>{data.data.user.name}</b></div>
               )
            }
            subheader={
               loading ? (
                  <Skeleton animation="wave" height={10} width="40%" />
               ) : (
                  <>
                     {" "}
                     {/* <small>@{data.school}</small>{" "} */}
                     <span style={{ fontSize: "12px" }}>
                        {" "}
                        &nbsp;&nbsp;
                        {/* {commentDuration(data.post.time)} */}
                     </span>
                     {/* .<PublicOutlined style={{ fontSize: "15px" }} /> <br /> */}
                     <b style={{ color: "black", fontSize: "14px" }}>
                        {data.type == "EVENT" && (
                           <div>
                              {/* <b>{data.post.meta.event.title && data.post.meta.event.title}</b> */}
                           </div>
                        )}
                     </b>{" "}
                     {label}
                     <br />
                  </>
               )
            }
         />
         {loading ? (
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
         ) : (
            <div
               style={{}}
               onClick={() => {
                  history.push("/view-feed");
                  dispFeedView(data)
               }}
            >
               {data.data.post.image != undefined ? (

                  <CardMedia
                     style={{
                        objectFit: "cover",
                        objectPosition: "90% 10%",
                        maxHeight: "300px",
                     }}
                     onClick={() => {
                        history.push("/view-feed");
                        dispFeedView(data)
                     }}
                     component="img"
                     // height="350"
                     image={`${API_URL}/${data.data.post.image}`}
                     // image={data.post.file.secure_url}
                     alt="image"
                  />
               ) :
                  <>



                     <div style={{ background: " ", height: "150px", textAlign: "center", padding: "", position: "relative" }}>
                        <svg width="309" height="154" viewBox="0 0 309 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_1376_18991)">
                              <rect width="325" height="179" rx="20" fill="#0C1825" />
                              <circle cx="19" cy="79" r="45" fill="#FFCB66" fill-opacity="0.2" />
                              <circle cx="228.5" cy="33.5" r="96.5" fill="#F6BDE9" fill-opacity="0.2" />
                           </g>
                           <defs>
                              <clipPath id="clip0_1376_18991">
                                 <rect width="309" height="154" rx="20" fill="white" />
                              </clipPath>
                           </defs>
                        </svg>
                        {/* PeopleAltIcon */}
                        <div style={{ position: "absolute", top: "15px", color: "mediumseagreen", left: "40px" }} >
                           <PeopleAltIcon style={{ fontSize: "18px" }} /> {data.data.post.beneficiaries}
                        </div>

                        <div style={{ position: "absolute", top: "15px", color: "crimson", right: "40px" }} >
                           <PeopleAltIcon style={{ fontSize: "18px" }} /> {data.giveaway_lucky_winners.length}
                        </div>

                        <div style={{ position: "absolute", bottom: "10px", color: "white", right: "40px" }} >
                           <small>
                              {data.comments.length}  Reactions
                           </small>
                        </div>

                        <div style={{ position: "absolute", top: "35%", color: "white", width: "100%", left: "0px", textAlign: "center" }} >
                           ₦<b style={{ fontSize: "40px" }}> {separator(data.data.post.amount)} </b>
                           <div><span>Giveaway</span></div>
                        </div>
                     </div>

                  </>}
               {console.log(data)}
            </div>
         )}
         {/* {console.log(loading)} */}
         <CardContent>
            {loading ? (
               <React.Fragment>
                  <Skeleton
                     animation="wave"
                     height={10}
                     style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
               </React.Fragment>
            ) : (
               <Typography variant="body2" color="text.secondary" component="p">
                  <div><b>{data.data.user.name}</b></div>
                  {/* &nbsp; &nbsp; */}

                  <span
                     onClick={() => {
                        history.push("/view-feed");
                        dispFeedView(data)
                     }}
                     style={{ fontFamily: "" }}
                  >
                     {data.data.post.post}

                  </span>
                  <br />
                  <br />
                  <div style={{ textAlign: "right ", fontSize: "12px", color: "black" }}>
                     {/* <small>
                        {data.comments.length}  Reactions
                     </small> */}
                     {/* 
              <FavoriteBorderOutlined   style={{fontSize:"18px",color:"#0a3d62",marginLeft:"-5px"}} />
                <CommentOutlined style={{ fontSize: "18px", color: "#0a3d62", marginLeft: "-5px" }} />
                &nbsp; {parseInt(data.likes.length)+ parseInt(data.unlikes.length)+parseInt(data.comments.length)} */}
                  </div>
                  {/* <Divider /> <br /> */}

                  {/* <div
                     style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        position: "absolute",
                        width: " ",
                        height: "40px",
                        background: " ",
                        textAlign: " ",
                        display: "inline-block",
                        marginLeft: "10px", float: "right",
                     }}
                  >
                     <small></small>
                     <b className="link" style={{ fontSize: "15px" }}>
                AMC - 110
              </b>
                  </div> */}
               </Typography>
            )}
            {props.loading ? (
               ""
            ) :
               <div
                  style={{
                     position: "relative",
                     // height: "44px",
                     background: " #f0f0f0",
                     borderRadius: "27px",
                     padding: "15px",
                     marginTop: "6px",
                  }}
                  onClick={() => {
                     history.push("/view-feed");
                     dispFeedView(data)
                  }}
               // onClick={toggleDrawer("bottom", true, { data })}
               >
                  <span>Write a comment....</span>
               </div>}
         </CardContent>
      </Card>
   );
}



// render if benefited or not
const ifBenefited = (post, comment, sessionUser, buzUsers) => {
   // style for the button which allows users to login
   const buzBTN = {
      marginTop: "4px",
      fontSize: "12px",
      position: "relative",
      width: " ",
      height: "40px",
      background: " ",
      textAlign: "center",
      display: "inline-block",
      marginLeft: "40px",
      color: "#0a3d62",
   };

   // check if who is browsing is same with who posted 
   if (post.poster.buzzid == sessionUser.buzzID) {

      if (comment.user.buzzID == sessionUser.buzzID) {

      } else {
         const ifBen = post.giveaway_lucky_winners.filter(e => e.beneficiaryId == sessionUser.buzzID)
         // console.log(ifBen)
         if (ifBen.length > 0) {
            return (<>
               <div style={{
                  background: "orange", padding: "3px 7px",
                  marginTop: "10px", width: "105px", textAlign: "center",
                  display: "inline-block", borderRadius: "7px", color: "white"
                  // marg:"right"
               }} >
                  Received &nbsp; 😊
               </div>
            </>)
         } else {
            return (<>
               <div
                  onClick={() => {
                     buzUsers({
                        giveawayData: comment,
                        luckyWinner: sessionUser,
                        postId: post.id,
                        poster: post.poster,
                        getGiveawayWinners
                     });
                  }}
                  style={{
                     background: "mediumseagreen", padding: "3px 7px",
                     marginTop: "10px", width: "95px", textAlign: "center",
                     display: "inline-block", borderRadius: "7px", color: "white"
                     // marg:"right"
                  }} >Buz me &nbsp; 🙏</div>
            </>)

         }
      }
   } else {
      const ifBen = post.giveaway_lucky_winners.filter(e => e.beneficiaryId)
      if (ifBen) {
         return (<>
            <div style={{
               background: "orange", padding: "3px 7px",
               marginTop: "10px", width: "105px", textAlign: "center",
               display: "inline-block", borderRadius: "7px", color: "white"
               // marg:"right"
            }} >
               Received &nbsp; 😊
            </div>
         </>)
      }
   }
}


// display comments
function comments(comments, state, data, buzUsers) {
   comments.sort(function (a, b) {
      return parseFloat(b.id) - parseFloat(a.id);
   });
   // console.log(comments)
   // console.log(state.userData)
   // console.log(data)
   return comments.map((e, index) => {
      return (
         <>
            <div style={{ height: " ", background: "#fffcfc", padding: "16px 10px", margin: "10px 0px", borderRadius: "5px" }}>
               <b>{e.user.name}</b>
               <div>
                  <Typography variant="body2" color="text.secondary" component="p">{e.comment}</Typography>
               </div>

               {/* {ifBenefited(data, e, state.userData, buzUsers)} */}
               <Divider style={{ marginLeft: "0px", marginTop: "10px" }} />
            </div>
         </>
      )
   })
}


export function SinglePost(props) {
   const {
      loading = false,
      data,
      // handleUnlikes,
      // handleLike, 
      toggleDrawer,
      state,
      dispFeedView,
      comment,
      setComment, 
      buzUsers
   } = props;

   let label = "";

   if (data) {
      if (data.type == "GIVEAWAY" && data.data.post.amount != null) {
         label = `GIVE AWAY  -  NGN ${data.data.post.amount}`;
      } else if (data.type == "POST") {
      } else if (data.type == "EVENT") {
         label = `${data.data.post.date.date} |  ${data.data.post.date.time}`;
      }
   }
   return (
      <div
         style={{
            width: "100%",
            marginLeft: "0px",
            borderRadius: "0px",
            padding: "0px",
         }}
         sx={{ m: 2 }}
      >
         <CardHeader
            avatar={
               loading ? (
                  <Skeleton
                     animation="wave"
                     variant="circular"
                     width={40}
                     height={40}
                  />
               ) : (
                  <Avatar
                     onDoubleClick={() => {
                        // console.log("hello");
                     }}
                     alt="Ted talk"
                  // src={data.poster.avater}
                  />
               )
            }
            action={
               loading ? null : (
                  <>
                     {" "}
                     {/* <MoreHorizOutlined style={{ fontSize: " 30px" }} />{" "} */}
                  </>
               )
            }
            title={
               loading ? (
                  <Skeleton
                     animation="wave"
                     height={10}
                     width="80%"
                     style={{ marginBottom: 6 }}
                  />
               ) : (
                  <b>{data.data.user.name}</b>
               )
            }
            subheader={
               loading ? (
                  <Skeleton animation="wave" height={10} width="40%" />
               ) : (
                  <>
                     {" "}
                     {/* <small>@{data.school}</small>{" "} */}
                     <span style={{ fontSize: "12px" }}>
                        {" "}
                        &nbsp;&nbsp;
                        {/* {commentDuration(data.post.time)} */}
                     </span>
                     {/* .<PublicOutlined style={{ fontSize: "15px" }} /> <br /> */}
                     <b style={{ color: "black", fontSize: "14px" }}>
                        {data.type == "EVENT" && (
                           <div>
                              {/* <b>{data.post.meta.event.title && data.post.meta.event.title}</b> */}
                           </div>
                        )}
                        {label}
                     </b>{" "}
                     <br />
                  </>
               )
            }
         />
         {loading ? (
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
         ) : (
            <div>
               {data.data.post.image != undefined ? (

                  <CardMedia
                     style={{
                        objectFit: "cover",
                        objectPosition: "90% 10%",
                        maxHeight: "300px",
                     }}
                     component="img"
                     // height="350"
                     image={`${API_URL}/${data.data.post.image}`}
                     // image={data.post.file.secure_url}
                     alt="image"
                  />
               ) :
                  <>

                     <div style={{ background: " ", height: "150px", textAlign: "center", padding: "", position: "relative" }}>
                        <svg width="309" height="154" viewBox="0 0 309 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_1376_18991)">
                              <rect width="325" height="179" rx="20" fill="#0C1825" />
                              <circle cx="19" cy="79" r="45" fill="#FFCB66" fill-opacity="0.2" />
                              <circle cx="228.5" cy="33.5" r="96.5" fill="#F6BDE9" fill-opacity="0.2" />
                           </g>
                           <defs>
                              <clipPath id="clip0_1376_18991">
                                 <rect width="309" height="154" rx="20" fill="white" />
                              </clipPath>
                           </defs>
                        </svg>
                        {/* PeopleAltIcon */}
                        <div style={{ position: "absolute", top: "15px", color: "mediumseagreen", left: "40px" }} >
                           <PeopleAltIcon style={{ fontSize: "18px" }} /> {data.data.post.beneficiaries}
                        </div>

                        <div style={{ position: "absolute", top: "15px", color: "crimson", right: "40px" }} >
                           <PeopleAltIcon style={{ fontSize: "18px" }} /> {data.giveaway_lucky_winners.length}
                        </div>

                        <div style={{ position: "absolute", bottom: "10px", color: "white", right: "40px" }} >
                           <small>
                              {data.comments.length}  Reactions
                           </small>
                        </div>

                        <div style={{ position: "absolute", top: "35%", color: "white", width: "100%", left: "0px", textAlign: "center" }} >
                           ₦<b style={{ fontSize: "40px" }}> {separator(data.data.post.amount)} </b>
                           <div><span>Giveaway</span></div>
                        </div>
                     </div>

                  </>}
               {/* {console.log(data.post.photo)} */}
            </div>
         )}
         {/* {console.log(loading)} */}
         <CardContent>
            {loading ? (
               <React.Fragment>
                  <Skeleton
                     animation="wave"
                     height={10}
                     style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
               </React.Fragment>
            ) : (
               <Typography variant="body2" color="text.secondary" component="p">
                  {/* <b>{data.data.user.name}</b> */}
                  &nbsp; &nbsp;

                  <span
                     onClick={() => {
                        // history.push("/view-feed");
                        dispFeedView(data)
                     }}
                     style={{ fontFamily: "" }}
                  >
                     {data.data.post.post}

                  </span>
                  <br />
                  <br />
                  <div style={{ textAlign: "right ", fontSize: "12px", }}>
                     <small>
                        {data.comments.length}  Reactions
                     </small>
                  </div>
               </Typography>
            )}
         </CardContent>


         <div style={{ height: " ", background: " ", padding: "10px" }}>
            {data.comments.length > 0 && <small>Comments</small>}
            {comments(data.comments, state, data, buzUsers)}
         </div>  <br />
         <div style={{ height: "60px", background: " " }}></div>
         <div id="view" style={{ height: "50px", background: " " }} > </div>
      </div>
   );
}