import {
   send_buzz_alert,
   buzz_request,
} from "../../services/apiservice"

import { insertBuzzRequestService } from "../../services/buzme"


export const initializeBuzzRequesrController = (payload) => {

   console.log(payload)
   payload.setStage(null)
   payload.setStates({ ...payload.compState, loading: true });

   for (let i = 0; i < payload.sendTo.length; i++) {
      let newPayload = {
         ...payload,
         sendTo: payload.sendTo[i].value,
         data: {
            ref: new Date().getTime()
         },
      }

      // console.log(newPayload)

      insertBuzzRequestService(newPayload).then(suc => {

         console.log(suc)
         payload.setPin("")
         payload.setStage("REQUEST-SUCCESS")
         payload.setStates({ ...payload.compState, loading: false }); 

      })
         .catch(error => {
            console.log(error)
            payload.setStage("SENDBUZZ-ERROR")
            payload.setPin("")
            payload.setStates({ ...payload.compState, loading: false });
         })
   }


   let sendToPhones = [];
   for (let i = 0; i < payload.sendTo.length; i++) {
      sendToPhones.push(`+234${payload.sendTo[i].phone.substring(1, 11)}`);
   }
   let smsPayload = {
      phones: sendToPhones, // array[]
      sender: payload.from.name,
      amount: parseInt(payload.amount),
      desc: payload.reason,
      ref: new Date().getTime()
   };

}