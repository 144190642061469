import React, { useState } from "react";
import { connect } from "react-redux";

import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { userSessionService, } from '../../services/auth'
import { updateuserService } from "../../services/auth"
import Logo from "../../statics/assets/logo.png"
import { KeyboardBackspace } from "@material-ui/icons";
import { Redirect, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { session, clearCounterController } from "../../controllers/checksession"
import CircularProgress from '@mui/material/CircularProgress';
import {
   login,
   disp_session,
   logOut
} from "../../redux";
import { TurnedInNotRounded } from "@mui/icons-material";


const inputStyle = {
   height: "55px",
   width: "100%",
   padding: "10px"
}
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   login_suc,
   set_session,
   log_out
}) {




   const [values, setValues] = React.useState({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
   });



   const handleClickShowPassword = () => {
      setValues({
         ...values,
         showPassword: !values.showPassword,
      });
   };





   const [currentpin, setcurrentpin] = useState("");
   const [newpin1, setnewpin1] = useState("");
   const [newpin2, setnewpin2] = useState("")
   const [compState, setStates] = useState(0)
   const [error, seterror] = useState({
      msg: "",
      status: false
   })
   const state = appState;
   let history = useHistory();
   let User = userSessionService().user.user_metadata
   let update = () => {
      if (newpin1.length < 1 || newpin2.length < 1 || currentpin.length < 1) {
         seterror({
            msg: "You missed a field",
            status: true
         })
      }
      else if (newpin1 != newpin2) {
         seterror({
            msg: "New pins not the same",
            status: true
         })
      } else if (currentpin != User.transactionPin) {
         seterror({
            msg: "Current pin not correct",
            status: true
         })
      } else if (newpin1 == "0000") {
         seterror({
            msg: "You can not use a default pin 0000",
            status: true
         })
      }
      else {
         seterror({
            msg: "",
            status: false
         })
         let payload = {
            transactionPin: newpin1,
         }

         setStates({
            ...compState,
            loading: true
         })

         updateuserService(payload).then(res => {
            if (res.data != null) {
               setcurrentpin("")
               setnewpin1("")
               setnewpin2("")
               toast.success('Updated successfully', {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  onClose: () => {
                     log_out()
                     history.push("/")
                  }
               });
               setStates({
                  ...compState,
                  loading: false
               })
            } else {
               toast.error('An error occured', {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });
            }
         })
            .catch(error => {
               toast.error('An error occured', {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });
            })
      }


   }
   React.useEffect((compState) => {
      window.scrollTo(0, 0);

      // clearCounterController()
      // session(history)
   }, []);



   return (
      <>
         {/* {compState.loading == true && loader()} */}
         {console.log(User)}
         {/* {clearCounterController()}
         {session(history)} */}
         <form style={formSTyle} noValidate autoComplete="off" >
            <div style={{ marginBottom: "30px", height: "30px", background: " " }}>
               <KeyboardBackspace onClick={() => {
                  history.goBack()
               }} style={{ float: "left", fontSize: "30px" }} />
               <img src={Logo} style={{ width: "80px", float: "right" }} />
            </div>
            <b style={{
               position: 'static',
               left: '0%',
               right: ' 42.5%',
               top: '0%',
               bottom: ' 62.03%',
               fontStyle: 'normal',
               fontWeight: ' 700',
               fontSize: ' 17px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
            }}>
               Transaction pin
            </b> <br />
            <small>
               Manage your transaction pin
            </small>
            <br />


            {error.status == true && <><br /><br /><div style={{ color: "crimson", textAlign: "center", marginTop: "" }}>{error.msg}</div></>}

            {/* {compState.loading == true && <center>
               <CircularProgress style={{ width: "20px", height: "20px" }} />
            </center>} */}
            <ToastContainer
               position="top-right"
               autoClose={5000}
               hideProgressBar={false}
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               pauseOnHover
            />
            <FormControl style={{ width: "100%", marginTop: "60px" }}>
               <InputLabel htmlFor="component-outlined">Current Pin</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  id="component-outlined"
                  onChange={(e) => {
                     setcurrentpin(e.target.value);
                  }}
                  value={currentpin}
                  // disabled
                  label="Current Pin"
                  type="number"
               />
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "20px" }}>
               <InputLabel htmlFor="component-outlined">New Pin</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  id="component-outlined"
                  onChange={(e) => {
                     setnewpin1(e.target.value);
                  }}
                  value={newpin1}
                  // disabled
                  label="New Pin"
                  type="number"
               />
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "20px" }}>
               <InputLabel htmlFor="component-outlined">Confirm New Pin</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  onChange={(e) => {
                     setnewpin2(e.target.value);
                  }}
                  id="component-outlined"
                  value={newpin2}

                  label="Confirm New Pin"
                  type="number"
               />
            </FormControl>


            <br /><br />
            {compState.error == true && <><div style={{ color: "crimson", textAlign: "center", marginTop: "" }}>{compState.msg}</div></>}
            <br /><br />
            <button
               type="button"
               style={{
                  background: "#0C1825",
                  color: "white",
                  border: "none",
                  outline: "none",
                  padding: "7px 50px",
                  marginTop: "5px",
                  borderRadius: "6px",
                  width: "100%",
                  height: "42px",
                  opacity: compState.loading == true ? "0.4" : "1"
               }}
               onClick={(e) => {
                  update();
               }}
            >
               {" "}
               {" "}
               {compState.loading == true ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : " Update"}

            </button>
         </form>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      log_out: () => dispatch(logOut()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
