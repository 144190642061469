import { allTransactionsService } from "../services/transactions"
import { markIsReadService, SelectmarkIsReadService } from "../services/notification"

export const allNotificationsController = (user, setStates, compState, disp_transactions) => {
   allTransactionsService(user).then(res => {
      console.log(res)

      if (res.data == null) {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         disp_transactions([])
      } else {
         setStates({
            ...compState,
            loading: false,
            error: false
         })
         disp_transactions(res.data)
         console.log('ended getting notifications')
         console.log(res.data)
      }
   })
      .catch(err => {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         disp_transactions([])
      })
}

export const markIsRead = (id, user) => {
   SelectmarkIsReadService(id).then(res => {
      let newD = { user: user }
      if (res.data[0].isRead.filter(e => e.user == user) < 1) {
         res.data[0].isRead.push(newD)
         let datas = res.data[0].isRead;
         markIsReadService(id, datas).then(res1 => {
            console.log(res1)
         })
      }
   })
   // console.log(id)
}