import React, { useState } from "react";
import { connect } from "react-redux";
import {
   HomeOutlined,
   SchoolOutlined,
   AddBoxOutlined,
   SignalCellularConnectedNoInternet0Bar,
   RefreshOutlined,
   KeyboardBackspace
} from "@material-ui/icons";
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect, useHistory, Link } from "react-router-dom";
import { loader } from "../../components/loader"
import { session, clearCounterController } from "../../controllers/checksession"
import { getFeedsController } from "../../controllers/feeds"
import { userSessionService, } from '../../services/auth'
import { transactionComponent } from "../../components/transactions"
import { createCommentController } from "../../controllers/feeds"
import Logo from "../../statics/assets/logo.png"
import {
   transactions,
   transactionsToView,
   disp_session,
   re_auth,
   disp_realtime,
   disp_noti,
   login,
   disp_feeds,
   viewFeed
} from "../../redux";
import { List, Drawer, Box, Avatar, Typography, TextareaAutosize } from "@mui/material";
import { createGiveawayController } from "../../controllers/feeds"
import {
   NativeSelect,
   TextField,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Card,
   CardHeader,
   CardContent,
   CardMedia,
   IconButton,
   Skeleton,

} from "@mui/material";
import ImageResize from "image-resize";

import PropTypes from "prop-types";
import { ALLPOSTS } from "../../components/giveaway"
import {
   FavoriteBorderOutlined,
   CommentOutlined,
   ThumbDownOutlined,
   Send,
   DialpadOutlined,
   AddPhotoAlternateOutlined,
   CloseOutlined
} from "@material-ui/icons";

import { successAlert } from "../../components/alert"
// import io from "socket.io-client";
import { cashbackchargecentage, buzzPayChargeOnKashbak, cashbackRegEx, formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"
// const socket = io.connect("https://buzpaysucket.harvoxx.com/");


const formSTyle = {
   width: "100%",
   marginLeft: "0%",
   marginTop: "30px"
}
function Login({
   appState,
   disp_transactions,
   loadFeeds,
   set_session,
   login_suc,
   realtime,
   reauth,
   dispNoti,
   dispFeedView


}) {


   const [blob, setBlob] = useState("");
   const [giveaway, setGiveaway] = useState({
      amount: null,
      // delaysecond: null,
      beneficiaries: null,
      beneficiariesList: [],
   });

   let User = userSessionService()
   const [postText, setPostText] = useState("");

   const [compState, setStates] = useState({
      error: false,
   })
   const state = appState;
   let history = useHistory();
   React.useEffect((compState) => {
      window.scrollTo(0, 0);

      if (User == null) {
         // logOut()
         history.push("/login")
      } else if (state.userData == null) {
         reauth()
      } else {
         // reauth
         refreshTransactions()
      }
   }, []);

   const refreshTransactions = () => {
      if (User !== null) {

         setStates({
            ...compState,
            loading: true
         })
         if (state.feeds.length < 1) {
         }

         getFeedsController("", loadFeeds, setStates, compState)
      }
   }

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }

   const clearRealtime = () => {
      let userNewData = {
         ...state.userData,
         wallet: parseInt(state.userData.wallet) + parseInt(state.realtime[0].data.data.amount)
      }
      login_suc(userNewData)
      realtime([])
   }
   const realtimeMsg = () => {
      if (state.realtime[0].data) {

         return (
            <>
               <b>{state.realtime[0].data.type} Alert</b> <br />
               {state.realtime[0].data.type == "SCANPAY" && <><p>₦{separator(parseInt(state.realtime[0].data.data.amountPlusCharge) - parseInt(state.realtime[0].data.data.adminCharge))}</p></>}
               {state.realtime[0].data.type == "BUZ ALERT" && <><p>₦{separator(state.realtime[0].data.data.amount)}</p></>}
               <p>  from {state.realtime[0].data.sender.fullname}</p>
            </>
         )
      }
   }

   const tryAgain = () => {
      if (User !== null) {
         setStates({
            ...compState,
            loading: true
         })
         // getFeedsController(User.user.user_metadata.buzzID, setStates, compState, disp_transactions)
      } else {

      }
   }
   const [comment, setComment] = useState("");
   const [createpost, setcreatepost] = useState(false);
   const [postToComment, setPostToComment] = useState("");
   const [max, setMax] = useState("");

   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });




   const toggleDrawer = (anchor, open, post) => (event) => {
      if (post != false) {
         setPostToComment(post.data);
      }
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }
      setcreatepost(false)
      setDrawerState({ ...drawerState, [anchor]: open });
   };



   const allGiveAwayPosts = state.feeds.filter((e) => e.type == "GIVEAWAY");
   function renderFeeds(allFeeds) {
      // console.log(allGiveAwayPosts)
      allFeeds.sort(function (a, b) {
         return parseFloat(b.id) - parseFloat(a.id);
      });

      // console.log(allGiveAwayPosts);
      return allGiveAwayPosts.map((feeds) => {
         return (
            <ALLPOSTS 
               loading={false}
               data={feeds}
               //  handleUnlikes={handleUnlikes}
               //  handleLike={handleLike}
               history={history}
               toggleDrawer={toggleDrawer}
               state={state}
               dispFeedView={dispFeedView}
            />
         );
      });
   }
   ALLPOSTS.propTypes = {
      loading: PropTypes.bool,
   };

   const handleComment = (postToComment) => {
      document.getElementById("view").scrollIntoView();
      // console.log(postToComment)
      const payload = {
         user: {
            name: User.user.user_metadata.name,
            buzzID: User.user.user_metadata.buzzID
         },
         comment,
         post: {
            ...postToComment,
            id: postToComment.id
         },
         dispFeedView,
         allGiveAwayPosts:postToComment

      }
      createCommentController(payload)
   }


   const list = (anchor, data) => (
      <Box
         sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
         role="presentation"
      >
         <List>
            <div
               style={{
                  position: "relative",
                  height: " ",
                  background: " #f0f0f0",
                  borderRadius: "27px",
                  padding: "3px",
               }}
            >
               <TextareaAutosize
                  autoFocus
                  id="commentInput"
                  onChange={(e) => {
                     setComment(e.target.value);
                  }}
                  value={comment}
                  aria-label="minimum height"
                  minRows={1}
                  maxRows={4}
                  placeholder="Write a comment...."
                  style={{
                     width: "86%",
                     outline: "none",
                     borderRadius: "27px 0px 0px 27px",
                     border: "none",
                     background: "#f0f0f0",
                     padding: "10px",
                     paddingRight: " ",
                     resize: "none",
                  }}
               />

               <Send
                  onClick={() => {
                     setDrawerState({ ...drawerState, ["bottom"]: false });
                     handleComment(postToComment);
                  }}
                  style={{ position: "absolute", right: "10px", bottom: "10px" }}
               />
            </div>
         </List>
      </Box>
   );
   // handleComment


   const preview = (event) => {
      let files = event.target.files[0];
      let image = document.getElementById("upload");

      var imageResize = new ImageResize();
      imageResize
         .play(image)
         .then((response) => {
            function dataURLtoFile(dataurl, filename) {
               var arr = dataurl.split(","),
                  mime = arr[0].match(/:(.*?);/)[1],
                  bstr = atob(arr[1]),
                  n = bstr.length,
                  u8arr = new Uint8Array(n);

               while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
               }

               return new File([u8arr], filename, { type: mime });
            }

            //Usage example:
            var file = dataURLtoFile(response, files.name);
            // console.log(response)
            setBlob({
               ...blob,
               file: file,
               file2: response,
               url2: URL.createObjectURL(file),
            });

            // window.scrollTo(0, document.body.scrollHeight);
            // console.log("scroll")
         })
         .catch((error) => {
            // console.log(error);
         });
   };

   let url = blob.url2;
   // clear file input
   const clear = () => {
      setBlob("");
   };

   const createGiveaway = () => {
      let postId = new Date().getTime() + "@" + "@" + new Date().getTime();
      let eachUserGets = giveaway.amount / giveaway.beneficiaries; //AMOUNT EACH BENEFICIARIES GETS

      // subtrct giveaway amount from user
      //wallet to get user's actual wallet balance

      let userNewWalletBalance = parseInt(state.userData.wallet) - parseInt(giveaway.amount)
      let payload = {
         login_suc,
         userNewWalletBalance,
         loadFeeds,
         postId,
         userData: state.userData,
         type: "GIVEAWAY",
         school: "Rivers state university",
         data: {
            post: {
               post: postText,
               beneficiaryList: [],
               beneficiaries: giveaway.beneficiaries,
               amount: giveaway.amount,
               image: blob.file,
               eachUserGets,

               date: {
                  day: daysOfTheWeek(new Date),
                  month: monthsOfTheYear(),
                  year: new Date().getFullYear(),
                  date: new Date().getDate(),
                  time: formatAMPM(new Date())
               },
            },
            user: {
               buzzid: state.userData.buzzID,
               phone: state.userData.phone,
               name: state.userData.data.name,
            },
            comments: []
         },
         setDrawerState, drawerState,
         setStates, compState
      }
      setStates({
         ...compState,
         loading: true
      })
      window.scrollTo(0, 0);
      createGiveawayController(payload)


      // console.log(payload)
   }

   return (
      <>
         {/* {console.log(state)} */}
         {state.realtime.length > 0 && successAlert(clearRealtime, 'hello man', realtimeMsg)}
         {state.loggedIn === false && history.push("/")}
         {/* {compState.loading == true && loader()} */}
         {clearCounterController()}
         {session(history, reauth)}


         <div style={{ position: "sticky", top: "0px", backgroundColor: "rgb(244 244 244)", padding: "20px", zIndex: "1000" }}>
            <KeyboardBackspace style={{
               fontWeight: ' 700',
               fontSize: ' 30px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
            }}
               onClick={() => {
                  history.push("/dashboard")
               }}
            />

            <b style={{
               fontWeight: ' 700',
               fontSize: ' 17px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
               float: "right",
            }}>
               BuzPay Giveaway
            </b>
         </div>
         <form style={formSTyle} noValidate autoComplete="off" >
            {compState.loading == true &&

               <div style={{ position: "relative", top: "40%", textAlign: " ", width: "100%", background: "rgb(244 244 244)", left: "0px" }}>
                  <center>
                     <CircularProgress style={{ width: "20px", height: "20px" }} />
                  </center>
               </div>
            }


            <div className="" style={{ background: " " }}>
               
               {renderFeeds(state.feeds)}

               {compState.loading != true &&
                  <>
                     {
                        allGiveAwayPosts.length < 1 && (
                           <div style={{ textAlign: "center", marginTop: "40%" }}>No avilable give away ! <br /><br />
                              <b style={{ textDecoration: "none" }} onClick={() => {
                                 // history.push("/create-giveaway")
                                 setDrawerState({ ...drawerState, bottom: true });
                                 setcreatepost(true);
                              }} >Create one</b>

                           </div>
                        )
                     }</>}
            </div>



            {/* <ALLPOSTS loading />  */}


         </form>

         <React.Fragment key="bottom">
            <Drawer
               anchor="bottom"
               open={drawerState["bottom"]}
               onClose={toggleDrawer("bottom", false, false)}
            >

               {createpost == true ?
                  <>
                     <div style={{ height: " ", background: "white" }}>

                        <div style={{ marginBottom: "10px", height: "30px", background: " ", padding: "15px" }}>
                           <KeyboardBackspace onClick={() => {
                              setDrawerState({ ...drawerState, bottom: false });
                              setcreatepost(false);
                              clear();
                           }} style={{ float: "left", fontSize: "30px" }} />
                           <img src={Logo} style={{ width: "80px", float: "right" }} />

                           {/* <label id="postArea1">
                              <input
                                 onChange={(event) => {
                                    preview(event);
                                 }}
                                 accept="image/png, image/gif, image/jpeg"
                                 name="image"
                                 id="upload"
                                 type="file"
                                 style={{ display: "none" }}
                              />
                              <b style={{ fontSize: "20px", color: "#0C1825", float: "right" }}><AddPhotoAlternateOutlined /></b>
                           </label> */}



                        </div>

                        <div style={{ color: "crimson", textAlign: "center", marginBottom: "15px" }}>{max}</div>


                        <div style={{ height: "130px", background: " ", position: "relative", width: " ", padding: " " }} >
                           <TextareaAutosize
                              autoFocus
                              onChange={(e) => {
                                 if (postText.length > 179) {
                                    e.target.value = e.target.value.substring(0, 180);
                                 } else {
                                    e.target.value = e.target.value;
                                 }
                                 setPostText(e.target.value);
                                 // console.log(postText.length);
                              }}
                              //  onKeyUp={(e) => {
                              //    do_resize(e.target);
                              //  }}
                              id="postArea"
                              value={postText}
                              aria-label="minimum height"
                              minRows={5}
                              maxRows={7}
                              // placeholder="What is happening in your campus...."
                              placeholder="Describe your giveaway."
                              style={{
                                 width: "95%",
                                 outline: "none",
                                 borderRadius: "4px",
                                 border: "none",
                                 background: "#f0f0f0",
                                 // paddingRight: "10px",
                                 resize: "none",
                                 // margin: "1px 0%",
                                 //  float:"right"
                                 // paddingRight: "40px",
                                 height: "100%",
                                 position: "absolute",
                                 left: "5px"

                              }}
                           />
                           <div
                              style={{
                                 position: "absolute",
                                 bottom: "0px",
                                 right: "1px",
                                 width: "40px",
                                 background: " ",
                              }}
                              id="postArea1"
                              className="top-nav-pills-holder"
                           >
                              <span
                                 id="postArea1"
                                 style={{
                                    background: "none",
                                    color:
                                       180 - postText.length < 10
                                          ? "crimson"
                                          : "mediumseagreen",
                                 }}
                                 className="top-nav-pills"
                              >
                                 {" "}
                                 <DialpadOutlined id="postArea1" />{" "}
                              </span>{" "}
                              <br />
                              <b
                                 className="top-nav-pills-title"
                                 style={{
                                    background: "none",
                                    color:
                                       180 - postText.length < 10
                                          ? "crimson"
                                          : "mediumseagreen",
                                    fontSize: "12px",
                                 }}
                              >
                                 {" "}
                                 {180 - postText.length}
                              </b>
                           </div>




                        </div>


                        {/* Preview */}
                        <div style={{ padding: "15px" }}>
                           {blob.url2 != null && (
                              <>
                                 <Card
                                    id="postArea1"
                                    style={{
                                       width: "30%",
                                       marginLeft: "0px",
                                       borderRadius: "0px",
                                       position: "relative",
                                    }}
                                    sx={{ m: 2 }}
                                 >
                                    {" "}
                                    {/* <span
                                       onClick={() => {
                                          clear();
                                       }}
                                       style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                          color: "white",
                                          background: "red",
                                       }}
                                    >
                                       <CloseOutlined />
                                    </span> */}
                                    <CardMedia
                                       id="postArea1"
                                       component="img"
                                       height=" "
                                       // image={'https://i1.sndcdn.com/artworks-000371577855-entfet-t500x500.jpg'}
                                       // image={  "https://usercontent.one/wp/zonknews.com/wp-content/uploads/2021/01/GHANIAN-SOCIALITEHAJIA4REAL-DROPS-FINEGIRLAFTER-RELEASING-BADDERTHAN.jpg" }
                                       image={url}
                                       alt="image"
                                    />
                                 </Card>
                                 {/* <img style={{width:"50%"}} src = {url} /> */}
                              </>
                           )}
                        </div>

                        <div style={{ marginTop: " " }}>
                           <div
                              style={{
                                 // width: "100%",
                                 background: " ",
                                 display: "inline-block",
                                 height: " ",
                                 padding: "10px",
                                 textAlign: "center",
                                 float: "left"
                              }}>


                              <FormControl
                                 id="postArea1"
                                 variant="standard"
                                 sx={{ m: 0, minWidth: 40 }}
                              >
                                 <TextField
                                    style={{ width: "140px", marginRight: "20px" }}
                                    id="postArea1"
                                    value={giveaway.amount}
                                    label="Giveaway amount"
                                    variant="standard"
                                    onChange={(e) => {
                                       setGiveaway({
                                          ...giveaway,
                                          amount: e.target.value,
                                       });
                                    }}
                                 />
                              </FormControl>
                           </div>
                           <div
                              style={{
                                 // width: "100%",
                                 background: " ",
                                 display: "inline-block",
                                 height: " ",
                                 padding: "10px",
                                 textAlign: "center",
                                 float: "right"
                              }}
                           >


                              <FormControl
                                 id="postArea1"
                                 variant="standard"
                                 sx={{ m: 0, minWidth: 120 }}
                              >
                                 <InputLabel id="demo-simple-select-label">
                                    Beneficiaries
                                 </InputLabel>
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={giveaway.beneficiaries}
                                    label="Age"
                                    onChange={(e) => {
                                       setGiveaway({
                                          ...giveaway,
                                          beneficiaries: e.target.value,
                                       });
                                    }}
                                 >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                 </Select>
                              </FormControl>
                           </div>
                        </div>

                        <input
                           type="button"
                           value="Create Giveaway"
                           style={{
                              padding: "13px 17px",
                              outline: "none",
                              width: "90%",
                              background: " #073F74",
                              border: "0.5px solid #0C1825",
                              color: "white",
                              borderRadius: "6px",
                              margin: "10% 5%",
                              fontWeight: "bold",
                              // position: "absolute",
                              bottom: "30px"
                           }}
                           onClick={() => {
                              // getContacts()
                              if (postText.length < 1) {
                                 setMax("Describe your giveaway");
                              }
                              else if (giveaway.amount < 1000) {
                                 setMax("Minimum amount is NGN 1000");
                              } else {
                                 if (giveaway.amount > state.userData.wallet) {
                                    setMax("Insufficient wallet balance");
                                 }
                                 else {
                                    setMax("");
                                    createGiveaway()
                                    setDrawerState({ ...drawerState, bottom: false });
                                 }
                              }

                           }}
                        />
                     </div>
                  </> : <> {list("bottom")}</>}
            </Drawer>
         </React.Fragment>



         <br /><br /><br /> <br /><br />

         <div style={{ height: "64px", width: "100%", position: "fixed", background: "rgb(244 244 244)", bottom: "0px", left: "0px", borderTop: "1px solid rgba(12, 24, 37, 0.18)", textAlign: "center" }}>
            <center onClick={() => {
               history.push("/dashboard")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", float: "left", marginLeft: "10px" }}>
               <HomeOutlined style={{ float: "" }} />
               <div><small>Home </small></div>
            </center>
            <center onClick={() => {
               // history.push("/create-giveaway")
               setDrawerState({ ...drawerState, bottom: true });
               setcreatepost(true);
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", color: " ", float: "right", marginRight: "20px" }}>
               <AddBoxOutlined style={{ float: "" }} />
               <div><small>Create Giveaway</small></div>
            </center>
            {/* <center onClick={() => {
               history.push("/account")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px" }}>
               <SchoolOutlined style={{ float: "" }} />
               <div><small>Tour schools</small></div>
            </center> */}
         </div>

      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      disp_transactions: (payload) => dispatch(transactions(payload)),
      dispTransactionToView: (payload) => dispatch(transactionsToView(payload)),
      set_session: (time) => dispatch(disp_session(time)),
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      loadFeeds: (payload) => dispatch(disp_feeds(payload)),
      reauth: () => dispatch(re_auth()),
      dispFeedView: (payload) => dispatch(viewFeed(payload)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
