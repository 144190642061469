import debit_icon from "../statics/assets/credit-icon.png"
import credit_icon from "../statics/assets/debit-icon.png"


function separator(numb) {
   var str = numb.toString().split(".");
   str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   return str.join(".");
}


export const notificationComponent = (state, history, User, dispTransactionToView,markIsRead) => {
   // console.log(User)
   // console.log(state)

   if (state.transactions.length < 1) {
      return (
         <>
            <center style={{ marginTop: "40%", background: "" }}>
               <svg width="250" viewBox="0 0 550 542" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M550 475.525H0V475.888H550V475.525Z" fill="#EBEBEB" />
                  <path d="M494.89 498.895H458.458V499.258H494.89V498.895Z" fill="#EBEBEB" />
                  <path d="M364.342 502.846H354.783V503.209H364.342V502.846Z" fill="#EBEBEB" />
                  <path d="M457.358 485.416H436.249V485.779H457.358V485.416Z" fill="#EBEBEB" />
                  <path d="M105.215 487.856H57.706V488.22H105.215V487.856Z" fill="#EBEBEB" />
                  <path d="M121.979 487.856H115.016V488.22H121.979V487.856Z" fill="#EBEBEB" />
                  <path d="M247.665 493.986H144.617V494.349H247.665V493.986Z" fill="#EBEBEB" />
                  <path d="M260.7 410.747H48.301C46.6371 410.743 45.0423 409.868 43.8668 408.313C42.6912 406.758 42.031 404.651 42.031 402.453V8.22075C42.0455 6.03633 42.7121 3.94767 43.8861 2.40844C45.0601 0.869218 46.6466 0.00374202 48.301 0H260.7C262.366 0 263.963 0.873763 265.141 2.42907C266.319 3.98438 266.981 6.09383 266.981 8.29337V402.453C266.981 404.653 266.319 406.763 265.141 408.318C263.963 409.873 262.366 410.747 260.7 410.747V410.747ZM48.301 0.290487C46.71 0.294334 45.1852 1.13154 44.0612 2.61833C42.9373 4.10513 42.306 6.12002 42.306 8.22075V402.453C42.306 404.554 42.9373 406.569 44.0612 408.056C45.1852 409.543 46.71 410.38 48.301 410.384H260.7C262.292 410.38 263.818 409.543 264.944 408.057C266.069 406.57 266.703 404.556 266.706 402.453V8.22075C266.703 6.11869 266.069 4.10383 264.944 2.61745C263.818 1.13106 262.292 0.294326 260.7 0.290487H48.301Z" fill="#EBEBEB" />
                  <path d="M498.641 410.747H286.231C284.566 410.743 282.97 409.868 281.793 408.314C280.616 406.759 279.953 404.652 279.95 402.453V8.22075C279.967 6.03501 280.636 3.94636 281.812 2.40754C282.988 0.86871 284.575 0.00370121 286.231 0H498.641C500.293 0.00755916 501.877 0.874724 503.049 2.41354C504.22 3.95236 504.885 6.03884 504.9 8.22075V402.453C504.9 404.648 504.241 406.753 503.068 408.308C501.895 409.862 500.303 410.739 498.641 410.747V410.747ZM286.231 0.290487C284.639 0.294326 283.113 1.13106 281.987 2.61745C280.862 4.10383 280.228 6.11869 280.225 8.22075V402.453C280.228 404.556 280.862 406.57 281.987 408.057C283.113 409.543 284.639 410.38 286.231 410.384H498.641C500.233 410.38 501.759 409.543 502.885 408.057C504.01 406.57 504.644 404.556 504.647 402.453V8.22075C504.644 6.11869 504.01 4.10383 502.885 2.61745C501.759 1.13106 500.233 0.294326 498.641 0.290487H286.231Z" fill="#EBEBEB" />
                  <path d="M318.659 172.927L470.217 172.927V41.8741L318.659 41.8741V172.927Z" fill="#E6E6E6" />
                  <path d="M314.028 172.927L468.05 172.927V41.8741L314.028 41.8741V172.927Z" fill="#F0F0F0" />
                  <path d="M318.659 198.635L470.217 198.635V172.912L318.659 172.912V198.635Z" fill="#E6E6E6" />
                  <path d="M306.339 198.635L460.361 198.635V172.912L306.339 172.912V198.635Z" fill="#F0F0F0" />
                  <path d="M461.582 164.385V50.4277L320.507 50.4277V164.385H461.582Z" fill="#FAFAFA" />
                  <path d="M429.77 164.371L411.752 50.4277H383.636L401.654 164.371H429.77Z" fill="white" />
                  <path d="M458.59 155.875C458.652 155.875 458.713 155.858 458.769 155.827C458.826 155.795 458.878 155.749 458.921 155.691C458.964 155.633 458.997 155.564 459.02 155.488C459.043 155.412 459.053 155.331 459.052 155.25V55.9911C459.052 55.8293 459.003 55.6741 458.917 55.5597C458.83 55.4453 458.713 55.381 458.59 55.381C458.529 55.379 458.468 55.3935 458.411 55.4234C458.355 55.4534 458.303 55.4982 458.26 55.5553C458.217 55.6123 458.183 55.6804 458.16 55.7553C458.137 55.8302 458.126 55.9104 458.128 55.9911V155.25C458.127 155.331 458.137 155.412 458.16 155.488C458.183 155.564 458.216 155.633 458.259 155.691C458.302 155.749 458.354 155.795 458.411 155.827C458.467 155.858 458.528 155.875 458.59 155.875V155.875Z" fill="#F0F0F0" />
                  <path d="M395.615 164.371L377.597 50.4277H366.63L384.659 164.371H395.615Z" fill="white" />
                  <path d="M321.332 164.385V50.4277H320.507V164.385H321.332Z" fill="#E6E6E6" />
                  <path opacity="0.6" d="M312.51 63.3117H464.068L464.662 53.7402H313.104L312.51 63.3117Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M312.51 78.9974H464.068L464.662 69.4404H313.104L312.51 78.9974Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M312.51 94.6839H464.068L464.662 85.127H313.104L312.51 94.6839Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M312.51 110.385H464.068L464.662 100.813H313.104L312.51 110.385Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M312.51 126.071H464.068L464.662 116.499H313.104L312.51 126.071Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M312.51 141.757H464.068L464.662 132.186H313.104L312.51 141.757Z" fill="#EBEBEB" />
                  <path d="M448.459 380.217H416.68V388.496H448.459V380.217Z" fill="#E6E6E6" />
                  <path d="M356.741 475.525H362.604V285.156H356.741V475.525Z" fill="#E6E6E6" />
                  <path d="M336.413 388.495H416.669V380.216H336.413V388.495Z" fill="#F5F5F5" />
                  <path d="M448.459 425.489H416.68V433.768H448.459V425.489Z" fill="#E6E6E6" />
                  <path d="M336.413 433.753H416.669V425.474H336.413V433.753Z" fill="#F5F5F5" />
                  <path d="M448.459 289.687H416.68V297.965H448.459V289.687Z" fill="#E6E6E6" />
                  <path d="M336.413 297.951H416.669V289.672H336.413V297.951Z" fill="#F5F5F5" />
                  <path d="M448.459 334.945H416.68V343.224H448.459V334.945Z" fill="#E6E6E6" />
                  <path d="M336.413 343.224H416.669V334.945H336.413V343.224Z" fill="#F5F5F5" />
                  <path d="M437.008 475.525H442.871V285.156H437.008V475.525Z" fill="#E6E6E6" />
                  <path d="M410.806 475.525H416.669V285.156H410.806V475.525Z" fill="#F5F5F5" />
                  <path d="M336.413 475.525H342.276V285.156H336.413V475.525Z" fill="#F5F5F5" />
                  <path d="M71.9179 475.525H131.956L131.956 321.728H71.9179L71.9179 475.525Z" fill="#E6E6E6" />
                  <path d="M87.945 475.526H71.907V454.291H104.753L87.945 475.526Z" fill="#FAFAFA" />
                  <path d="M235.598 475.525H295.636V321.728H235.598V475.525Z" fill="#E6E6E6" />
                  <path d="M71.918 468.205L249.601 468.205V321.713H71.918V468.205Z" fill="#FAFAFA" />
                  <path d="M233.563 475.526H249.59V454.291H216.755L233.563 475.526Z" fill="#FAFAFA" />
                  <path d="M84.348 412.97H237.16V376.31H84.348V412.97Z" fill="#F0F0F0" />
                  <path d="M84.348 457.457H237.16V420.798H84.348V457.457Z" fill="#F0F0F0" />
                  <path d="M208.12 380.231H113.399C112.063 380.231 110.781 379.531 109.837 378.283C108.892 377.036 108.361 375.344 108.361 373.579V373.129H213.147V373.579C213.147 375.341 212.618 377.031 211.675 378.278C210.733 379.525 209.454 380.228 208.12 380.231Z" fill="#FAFAFA" />
                  <path d="M84.348 368.467H237.16V331.807H84.348V368.467Z" fill="#F0F0F0" />
                  <path d="M208.12 335.729H113.399C112.063 335.729 110.781 335.028 109.837 333.78C108.892 332.533 108.361 330.841 108.361 329.076V328.626H213.147V329.076C213.147 330.838 212.618 332.528 211.675 333.775C210.733 335.022 209.454 335.725 208.12 335.729Z" fill="#FAFAFA" />
                  <path d="M208.12 424.733H113.399C112.063 424.733 110.781 424.033 109.837 422.785C108.892 421.538 108.361 419.846 108.361 418.081V417.631H213.147V418.081C213.147 419.843 212.618 421.533 211.675 422.78C210.733 424.027 209.454 424.73 208.12 424.733Z" fill="#FAFAFA" />
                  <path d="M81.477 172.927L233.035 172.927V41.8741L81.477 41.8741V172.927Z" fill="#E6E6E6" />
                  <path d="M76.857 172.927L230.879 172.927V41.8741L76.857 41.8741V172.927Z" fill="#F0F0F0" />
                  <path d="M81.477 198.635L233.035 198.635V172.912L81.477 172.912V198.635Z" fill="#E6E6E6" />
                  <path d="M69.146 198.635L223.168 198.635V172.912L69.146 172.912V198.635Z" fill="#F0F0F0" />
                  <path d="M224.4 164.385V50.4277L83.325 50.4277V164.385H224.4Z" fill="#FAFAFA" />
                  <path d="M192.588 164.371L174.57 50.4277H146.454L164.472 164.371H192.588Z" fill="white" />
                  <path d="M221.397 155.875C221.459 155.875 221.52 155.858 221.576 155.827C221.633 155.795 221.685 155.749 221.728 155.691C221.771 155.633 221.804 155.564 221.827 155.488C221.85 155.412 221.86 155.331 221.859 155.25V55.9911C221.861 55.9104 221.85 55.8302 221.827 55.7553C221.804 55.6804 221.77 55.6123 221.727 55.5553C221.684 55.4982 221.632 55.4534 221.576 55.4234C221.519 55.3935 221.458 55.379 221.397 55.381C221.274 55.381 221.157 55.4453 221.07 55.5597C220.984 55.6741 220.935 55.8293 220.935 55.9911V155.25C220.934 155.331 220.944 155.412 220.967 155.488C220.99 155.564 221.023 155.633 221.066 155.691C221.109 155.749 221.161 155.795 221.218 155.827C221.274 155.858 221.335 155.875 221.397 155.875Z" fill="#F0F0F0" />
                  <path d="M158.433 164.371L140.415 50.4277H129.448L147.466 164.371H158.433Z" fill="white" />
                  <path d="M84.15 164.385V50.4277H83.325V164.385H84.15Z" fill="#E6E6E6" />
                  <path opacity="0.6" d="M75.317 63.3117H226.875L227.469 53.7402H75.911L75.317 63.3117Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M75.317 68.7141H226.875L227.469 59.1426H75.911L75.317 68.7141Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M75.317 74.1029H226.875L227.469 64.5459H75.911L75.317 74.1029Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M75.317 79.5061H226.875L227.469 69.9346H75.911L75.317 79.5061Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M75.317 84.8949H226.875L227.469 75.3379H75.911L75.317 84.8949Z" fill="#EBEBEB" />
                  <path opacity="0.6" d="M75.317 90.2981H226.875L227.469 80.7266H75.911L75.317 90.2981Z" fill="#EBEBEB" />
                  <path d="M105.622 317.022H110.858L110.858 239.913H105.622V317.022Z" fill="#F5F5F5" />
                  <path d="M106.282 316.979H107.767L107.767 239.869H106.282V316.979Z" fill="#FAFAFA" />
                  <path d="M108.383 316.979H108.966L108.966 239.869H108.383V316.979Z" fill="#FAFAFA" />
                  <path d="M127.633 321.728H88.869C88.869 320.017 89.3836 318.377 90.2995 317.168C91.2154 315.958 92.4577 315.279 93.753 315.279H122.738C124.033 315.279 125.276 315.958 126.192 317.168C127.107 318.377 127.622 320.017 127.622 321.728H127.633Z" fill="#F0F0F0" />
                  <path d="M98.824 287.784C99.2228 287.781 99.6045 287.57 99.8865 287.197C100.168 286.825 100.328 286.321 100.331 285.795V235.294C100.331 234.766 100.172 234.26 99.8896 233.887C99.607 233.513 99.2237 233.304 98.824 233.304V233.304C98.6252 233.302 98.4281 233.352 98.2439 233.451C98.0598 233.55 97.8924 233.696 97.7513 233.881C97.6102 234.066 97.4982 234.286 97.4218 234.529C97.3454 234.771 97.306 235.031 97.306 235.294V285.853C97.3203 286.372 97.4865 286.863 97.7696 287.223C98.0528 287.584 98.4308 287.785 98.824 287.784Z" fill="#F0F0F0" />
                  <path d="M84.777 258.241H131.692L126.291 214.959H90.178L84.777 258.241Z" fill="#E0E0E0" />
                  <path d="M275 541.115C392.791 541.115 488.279 533.754 488.279 524.674C488.279 515.593 392.791 508.232 275 508.232C157.209 508.232 61.721 515.593 61.721 524.674C61.721 533.754 157.209 541.115 275 541.115Z" fill="#F5F5F5" />
                  <path d="M114.531 214.027L113.433 214.132L114.895 240.918L115.992 240.814L114.531 214.027Z" fill="#407BFF" />
                  <path d="M116.737 254.641L115.64 254.745L116.19 264.828L117.287 264.724L116.737 254.641Z" fill="#407BFF" />
                  <path d="M371.481 377.863H135.905C133.283 377.8 130.771 376.459 128.853 374.096C126.936 371.733 125.749 368.517 125.521 365.067L113.949 152.823C113.841 151.186 113.995 149.538 114.399 147.984C114.803 146.43 115.449 145.005 116.296 143.801C117.143 142.597 118.171 141.64 119.314 140.993C120.458 140.346 121.692 140.022 122.936 140.042H358.512C361.133 140.104 363.643 141.444 365.56 143.803C367.477 146.163 368.666 149.376 368.896 152.823L380.468 365.067C380.576 366.704 380.422 368.353 380.018 369.908C379.614 371.463 378.968 372.888 378.122 374.094C377.275 375.299 376.247 376.257 375.103 376.906C373.96 377.555 372.726 377.881 371.481 377.863V377.863Z" fill="#407BFF" />
                  <path d="M372.383 377.863H136.807C134.185 377.8 131.673 376.459 129.755 374.096C127.838 371.733 126.651 368.517 126.423 365.067L114.851 152.823C114.743 151.186 114.897 149.538 115.301 147.984C115.705 146.43 116.351 145.005 117.198 143.801C118.045 142.597 119.073 141.64 120.216 140.993C121.36 140.346 122.594 140.022 123.838 140.042H359.414C362.035 140.104 364.545 141.444 366.462 143.803C368.379 146.163 369.568 149.376 369.798 152.823L381.37 365.067C381.479 366.705 381.327 368.355 380.924 369.91C380.52 371.466 379.874 372.893 379.028 374.099C378.181 375.304 377.152 376.262 376.007 376.911C374.863 377.559 373.628 377.883 372.383 377.863V377.863Z" fill="#407BFF" />
                  <path opacity="0.5" d="M372.383 377.863H136.807C134.185 377.8 131.673 376.459 129.755 374.096C127.838 371.733 126.651 368.517 126.423 365.067L114.851 152.823C114.743 151.186 114.897 149.538 115.301 147.984C115.705 146.43 116.351 145.005 117.198 143.801C118.045 142.597 119.073 141.64 120.216 140.993C121.36 140.346 122.594 140.022 123.838 140.042H359.414C362.035 140.104 364.545 141.444 366.462 143.803C368.379 146.163 369.568 149.376 369.798 152.823L381.37 365.067C381.479 366.705 381.327 368.355 380.924 369.91C380.52 371.466 379.874 372.893 379.028 374.099C378.181 375.304 377.152 376.262 376.007 376.911C374.863 377.559 373.628 377.883 372.383 377.863V377.863Z" fill="white" />
                  <path d="M359.766 146.433H124.19H123.398C117.381 147.086 118.613 159.185 124.685 159.185H360.657C366.74 159.185 366.652 147.086 360.558 146.433H359.766Z" fill="#407BFF" />
                  <path d="M130.328 152.823C130.351 153.198 130.315 153.574 130.221 153.929C130.127 154.283 129.977 154.608 129.783 154.882C129.588 155.156 129.351 155.372 129.089 155.518C128.827 155.664 128.545 155.735 128.26 155.728C127.662 155.717 127.089 155.414 126.65 154.878C126.211 154.341 125.938 153.609 125.884 152.823C125.861 152.45 125.897 152.074 125.991 151.721C126.084 151.367 126.232 151.043 126.426 150.77C126.62 150.496 126.854 150.279 127.115 150.133C127.376 149.986 127.657 149.913 127.941 149.918C128.541 149.926 129.117 150.227 129.558 150.764C129.999 151.301 130.274 152.034 130.328 152.823Z" fill="#FAFAFA" />
                  <path d="M137.885 152.823C137.908 153.198 137.872 153.574 137.778 153.929C137.684 154.283 137.534 154.608 137.34 154.882C137.145 155.156 136.908 155.372 136.646 155.518C136.384 155.664 136.102 155.735 135.817 155.728C135.219 155.717 134.646 155.414 134.207 154.878C133.768 154.341 133.495 153.609 133.441 152.823C133.418 152.45 133.454 152.074 133.548 151.721C133.641 151.367 133.789 151.043 133.983 150.77C134.177 150.496 134.411 150.279 134.672 150.133C134.933 149.986 135.214 149.913 135.498 149.918C136.098 149.926 136.674 150.227 137.115 150.764C137.556 151.301 137.831 152.034 137.885 152.823Z" fill="#FAFAFA" />
                  <path d="M145.431 152.823C145.454 153.197 145.418 153.572 145.324 153.926C145.231 154.279 145.083 154.603 144.889 154.877C144.696 155.15 144.461 155.367 144.2 155.514C143.939 155.66 143.658 155.734 143.374 155.728C142.775 155.72 142.2 155.419 141.761 154.882C141.322 154.345 141.05 153.611 140.998 152.823C140.973 152.45 141.008 152.073 141.101 151.719C141.194 151.364 141.342 151.039 141.536 150.765C141.73 150.491 141.965 150.274 142.227 150.128C142.489 149.982 142.771 149.911 143.055 149.919C143.653 149.93 144.226 150.233 144.665 150.769C145.104 151.305 145.377 152.037 145.431 152.823V152.823Z" fill="#FAFAFA" />
                  <path d="M366.135 356.686H140.8C139.848 356.665 138.936 356.178 138.241 355.319C137.546 354.46 137.117 353.291 137.038 352.038L127.523 177.5C127.481 176.901 127.535 176.297 127.683 175.728C127.831 175.159 128.069 174.638 128.381 174.2C128.693 173.762 129.072 173.416 129.493 173.186C129.914 172.956 130.368 172.847 130.823 172.866H356.213C357.164 172.884 358.076 173.368 358.771 174.224C359.467 175.081 359.896 176.248 359.975 177.5L369.501 352.038C369.542 352.645 369.485 353.256 369.332 353.831C369.18 354.406 368.936 354.931 368.617 355.371C368.298 355.811 367.912 356.156 367.484 356.383C367.056 356.61 366.596 356.713 366.135 356.686V356.686Z" fill="white" />
                  <path d="M271.183 290.194L267.509 222.918L256.399 214.087H225.808L229.966 290.194H271.183Z" fill="white" />
                  <path d="M271.183 290.892H229.966C229.83 290.896 229.697 290.83 229.597 290.709C229.496 290.587 229.435 290.418 229.427 290.239L225.28 214.131C225.275 214.036 225.285 213.94 225.309 213.85C225.334 213.76 225.373 213.677 225.423 213.608C225.472 213.537 225.531 213.479 225.597 213.439C225.663 213.399 225.735 213.378 225.808 213.376H256.399C256.495 213.38 256.59 213.415 256.674 213.478L267.784 222.308C267.859 222.365 267.922 222.445 267.968 222.541C268.014 222.637 268.041 222.747 268.048 222.86L271.7 290.137C271.708 290.235 271.699 290.334 271.674 290.427C271.649 290.52 271.609 290.604 271.557 290.674C271.509 290.742 271.451 290.797 271.387 290.834C271.323 290.872 271.253 290.891 271.183 290.892V290.892ZM230.483 289.44H270.6L266.992 223.282L256.3 214.785H226.38L230.483 289.44Z" fill="#407BFF" />
                  <path d="M267.509 222.918L256.399 214.087L260.48 225.794L267.509 222.918Z" fill="#EBEBEB" />
                  <path d="M260.48 226.491C260.379 226.491 260.279 226.452 260.194 226.38C260.108 226.308 260.04 226.206 259.996 226.085L255.915 214.378C255.867 214.241 255.854 214.087 255.875 213.939C255.897 213.79 255.953 213.654 256.036 213.55C256.123 213.452 256.232 213.392 256.346 213.379C256.461 213.366 256.575 213.4 256.674 213.478L267.784 222.308C267.874 222.379 267.946 222.483 267.993 222.606C268.04 222.73 268.059 222.868 268.048 223.006C268.034 223.139 267.991 223.265 267.925 223.368C267.859 223.471 267.772 223.546 267.674 223.587L260.634 226.491H260.48ZM257.609 215.86L260.766 224.937L266.266 222.715L257.609 215.86Z" fill="#407BFF" />
                  <path d="M243.1 248.364C243.116 248.759 243.072 249.154 242.969 249.525C242.865 249.896 242.706 250.235 242.5 250.522C242.294 250.809 242.046 251.037 241.771 251.193C241.496 251.349 241.199 251.429 240.9 251.429C240.272 251.414 239.67 251.092 239.211 250.527C238.752 249.961 238.468 249.19 238.414 248.364C238.386 247.965 238.422 247.563 238.521 247.184C238.619 246.805 238.778 246.459 238.986 246.167C239.195 245.876 239.447 245.647 239.728 245.494C240.009 245.342 240.311 245.271 240.614 245.285C241.243 245.303 241.845 245.628 242.303 246.196C242.762 246.764 243.046 247.537 243.1 248.364Z" fill="#407BFF" />
                  <path d="M258.17 248.364C258.197 248.762 258.159 249.163 258.06 249.54C257.96 249.917 257.801 250.262 257.593 250.551C257.385 250.841 257.133 251.069 256.853 251.22C256.573 251.372 256.272 251.443 255.97 251.429C255.341 251.414 254.738 251.094 254.277 250.528C253.816 249.962 253.53 249.192 253.473 248.364C253.455 247.968 253.499 247.572 253.601 247.199C253.703 246.826 253.863 246.485 254.069 246.197C254.275 245.908 254.524 245.678 254.799 245.522C255.075 245.365 255.373 245.284 255.673 245.285C256.305 245.297 256.91 245.619 257.372 246.188C257.834 246.757 258.118 247.533 258.17 248.364V248.364Z" fill="#407BFF" />
                  <path d="M262.438 268.32C262.304 268.317 262.176 268.248 262.079 268.127C261.981 268.007 261.921 267.842 261.91 267.666C261.635 262.713 255.761 258.69 248.82 258.69C244.211 258.69 240.075 260.506 238.029 263.411C237.609 263.946 237.279 264.593 237.063 265.309C236.847 266.025 236.749 266.794 236.775 267.564C236.78 267.66 236.771 267.756 236.748 267.846C236.725 267.937 236.689 268.021 236.641 268.093C236.545 268.239 236.409 268.328 236.264 268.342C236.118 268.355 235.974 268.291 235.863 268.165C235.753 268.038 235.685 267.859 235.675 267.666C235.642 266.703 235.762 265.742 236.028 264.844C236.293 263.946 236.697 263.131 237.215 262.452C239.415 259.271 243.892 257.281 248.787 257.281C256.41 257.281 262.625 261.798 262.933 267.564C262.937 267.656 262.928 267.748 262.906 267.835C262.883 267.922 262.848 268.002 262.802 268.071C262.756 268.139 262.7 268.196 262.638 268.236C262.575 268.276 262.507 268.3 262.438 268.305V268.32Z" fill="#407BFF" />
                  <path d="M234.487 242.148C234.353 242.144 234.224 242.077 234.124 241.959C234.024 241.826 233.968 241.646 233.968 241.458C233.968 241.27 234.024 241.09 234.124 240.957L236.467 237.631C236.567 237.503 236.702 237.432 236.841 237.432C236.981 237.432 237.115 237.503 237.215 237.631C237.264 237.696 237.303 237.773 237.33 237.857C237.357 237.942 237.37 238.033 237.37 238.125C237.37 238.216 237.357 238.307 237.33 238.392C237.303 238.477 237.264 238.554 237.215 238.618L234.872 241.945C234.822 242.011 234.761 242.063 234.695 242.098C234.629 242.133 234.558 242.15 234.487 242.148V242.148Z" fill="#407BFF" />
                  <path d="M261.338 242.148C261.205 242.14 261.077 242.073 260.975 241.959L258.28 238.633C258.229 238.568 258.189 238.49 258.161 238.404C258.133 238.318 258.119 238.225 258.119 238.132C258.119 238.038 258.133 237.946 258.161 237.86C258.189 237.774 258.229 237.696 258.28 237.631C258.38 237.503 258.515 237.432 258.654 237.432C258.794 237.432 258.928 237.503 259.028 237.631L261.723 240.957C261.772 241.022 261.811 241.099 261.838 241.183C261.865 241.268 261.878 241.359 261.878 241.451C261.878 241.543 261.865 241.633 261.838 241.718C261.811 241.803 261.772 241.88 261.723 241.945C261.618 242.074 261.481 242.146 261.338 242.148V242.148Z" fill="#407BFF" />
                  <path d="M222.486 305.227H224.972L228.558 311.53L228.217 305.227H230.725L231.341 316.614H228.8L225.236 310.354L225.577 316.614H223.069L222.486 305.227Z" fill="#407BFF" />
                  <path d="M232.463 310.92C232.378 310.147 232.419 309.358 232.582 308.607C232.745 307.856 233.027 307.159 233.409 306.563C233.827 306.02 234.328 305.601 234.878 305.333C235.427 305.065 236.014 304.954 236.599 305.009C237.839 304.92 239.056 305.466 239.998 306.534C240.861 307.668 241.361 309.197 241.395 310.804C241.486 311.912 241.373 313.033 241.065 314.072C240.78 314.908 240.303 315.604 239.701 316.062C238.979 316.573 238.166 316.819 237.347 316.773C236.505 316.808 235.668 316.6 234.894 316.163C234.216 315.733 233.644 315.058 233.244 314.217C232.772 313.226 232.503 312.09 232.463 310.92V310.92ZM235.136 310.92C235.125 311.811 235.342 312.681 235.752 313.389C235.924 313.639 236.135 313.836 236.371 313.966C236.606 314.097 236.861 314.158 237.116 314.144C237.36 314.167 237.604 314.111 237.828 313.982C238.052 313.853 238.25 313.655 238.403 313.404C238.719 312.61 238.824 311.7 238.7 310.818C238.705 309.964 238.487 309.133 238.084 308.465C237.908 308.22 237.694 308.027 237.457 307.899C237.22 307.772 236.965 307.712 236.709 307.725C236.47 307.711 236.231 307.772 236.013 307.903C235.795 308.035 235.604 308.232 235.455 308.48C235.133 309.228 235.02 310.102 235.136 310.949V310.92Z" fill="#407BFF" />
                  <path d="M246.565 305.227H250.525C251.178 305.2 251.828 305.343 252.439 305.648C252.922 305.916 253.352 306.328 253.693 306.854C254.039 307.387 254.305 308.003 254.474 308.669C254.652 309.384 254.763 310.126 254.804 310.877C254.888 311.826 254.832 312.788 254.639 313.709C254.488 314.347 254.219 314.926 253.858 315.394C253.557 315.815 253.178 316.125 252.758 316.294C252.246 316.49 251.718 316.598 251.185 316.614H247.236L246.565 305.227ZM249.37 307.812L249.711 314.029H250.36C250.761 314.063 251.164 313.979 251.537 313.782C251.783 313.593 251.965 313.286 252.043 312.925C252.169 312.28 252.199 311.609 252.131 310.949C252.152 310.063 251.947 309.194 251.548 308.48C251.344 308.236 251.103 308.051 250.842 307.936C250.581 307.821 250.304 307.779 250.03 307.812H249.37Z" fill="#407BFF" />
                  <path d="M261.195 314.725H258.17L257.851 316.614H255.134L257.752 305.227H260.7L264.561 316.614H261.767L261.195 314.725ZM260.502 312.271L259.336 308.175L258.61 312.271H260.502Z" fill="#407BFF" />
                  <path d="M263.307 305.227H271.403L271.557 308.044H268.84L269.302 316.614H266.64L266.178 308.044H263.461L263.307 305.227Z" fill="#407BFF" />
                  <path d="M277.607 314.725H274.582L274.263 316.614H271.546L274.164 305.227H277.068L280.918 316.614H278.135L277.607 314.725ZM276.914 312.271L275.737 308.175L275 312.271H276.914Z" fill="#407BFF" />
                  <path d="M389.059 171.502C390.038 170.848 391.259 170.049 392.293 169.222C393.327 168.394 394.493 167.595 395.593 166.723C397.738 164.966 399.916 163.238 401.973 161.32C406.104 157.588 409.977 153.379 413.545 148.742C413.996 148.19 414.392 147.58 414.821 146.985L415.448 146.099L415.756 145.663L415.91 145.431C415.756 145.823 415.91 145.707 415.91 145.329C416.007 144.81 416.062 144.28 416.075 143.746C416.05 140.658 415.78 137.581 415.272 134.567C414.293 128.06 412.808 121.35 411.345 114.785L415.613 112.315C418.307 118.614 420.557 125.226 422.334 132.068C423.332 135.766 423.968 139.617 424.226 143.528C424.293 144.744 424.264 145.966 424.138 147.174C423.975 148.882 423.516 150.517 422.796 151.952L422.609 152.286L422.466 152.519L422.301 152.795L421.96 153.347L421.278 154.436C420.827 155.148 420.387 155.888 419.903 156.571C416.141 162.083 411.945 167.049 407.385 171.386C405.185 173.535 402.853 175.598 400.466 177.471C399.289 178.415 398.09 179.316 396.869 180.202C395.648 181.088 394.46 181.901 393.052 182.773L389.059 171.502Z" fill="#FFB573" />
                  <path d="M379.269 512.97C380.093 512.942 380.911 512.796 381.711 512.534C381.751 512.518 381.788 512.488 381.817 512.447C381.846 512.406 381.866 512.356 381.876 512.302C381.881 512.246 381.875 512.189 381.857 512.138C381.84 512.087 381.812 512.043 381.777 512.011C381.458 511.735 378.664 509.353 377.586 509.992C377.467 510.066 377.364 510.18 377.289 510.323C377.213 510.466 377.168 510.632 377.157 510.806C377.114 511.08 377.125 511.364 377.188 511.63C377.252 511.897 377.366 512.138 377.52 512.331C378.025 512.816 378.647 513.043 379.269 512.97V512.97ZM381.084 512.127C379.489 512.549 378.279 512.476 377.784 511.909C377.685 511.776 377.613 511.611 377.574 511.431C377.536 511.251 377.532 511.061 377.564 510.878C377.568 510.801 377.587 510.727 377.62 510.663C377.653 510.599 377.698 510.548 377.751 510.515C378.334 510.181 379.951 511.256 381.084 512.127Z" fill="#407BFF" />
                  <path d="M381.7 512.532H381.81C381.842 512.506 381.869 512.47 381.888 512.427C381.908 512.384 381.918 512.335 381.92 512.285C381.92 512.126 381.92 508.625 380.908 507.463C380.787 507.309 380.641 507.193 380.481 507.125C380.32 507.057 380.151 507.039 379.984 507.071C379.811 507.073 379.643 507.153 379.509 507.299C379.375 507.444 379.282 507.646 379.247 507.87C379.038 509.322 380.71 511.864 381.59 512.532C381.626 512.546 381.664 512.546 381.7 512.532V512.532ZM380.127 507.608C380.306 507.609 380.478 507.697 380.611 507.855C381.191 508.985 381.491 510.325 381.469 511.69C380.589 510.76 379.544 508.785 379.676 507.957C379.676 507.826 379.753 507.652 380.028 507.608H380.127Z" fill="#407BFF" />
                  <path d="M380.842 135.178C379.742 142.44 377.542 156.964 381.337 161.83C381.337 161.83 379.841 169.092 369.688 169.092C358.512 169.092 364.353 161.83 364.353 161.83C370.447 159.913 370.282 153.943 369.226 148.322L380.842 135.178Z" fill="#FFB573" />
                  <path d="M362.208 164.734C360.459 165.053 361.955 159.055 362.659 158.43C364.309 156.978 385.605 154.959 385.462 158.43C385.374 159.883 384.846 162.787 383.922 163.746C382.998 164.705 377.52 161.713 362.208 164.734Z" fill="#263238" />
                  <path d="M365.684 162.672C364.287 163.296 364.419 157.254 364.892 156.528C365.992 154.843 383.229 149.004 383.735 152.301C383.933 153.754 384.032 156.659 383.438 157.69C382.844 158.721 377.784 157.022 365.684 162.672Z" fill="#263238" />
                  <path d="M359.271 122.163C359.202 122.167 359.133 122.15 359.07 122.112C359.007 122.074 358.951 122.017 358.908 121.945C358.572 121.374 358.139 120.914 357.645 120.604C357.15 120.294 356.607 120.141 356.059 120.159C356.002 120.169 355.944 120.164 355.888 120.143C355.832 120.123 355.78 120.088 355.735 120.041C355.69 119.993 355.652 119.934 355.625 119.867C355.597 119.8 355.58 119.726 355.575 119.65C355.566 119.501 355.6 119.352 355.672 119.236C355.744 119.119 355.847 119.044 355.96 119.026C356.652 118.979 357.343 119.154 357.973 119.536C358.603 119.918 359.154 120.495 359.579 121.219C359.653 121.337 359.688 121.488 359.678 121.641C359.668 121.793 359.612 121.934 359.524 122.032C359.449 122.103 359.362 122.148 359.271 122.163V122.163Z" fill="#263238" />
                  <path d="M357.137 129.266C356.638 131.583 355.898 133.797 354.937 135.845C355.34 136.151 355.79 136.337 356.253 136.39C356.717 136.443 357.184 136.361 357.621 136.15L357.137 129.266Z" fill="#FF5652" />
                  <path d="M357.676 127.509C357.753 128.482 357.412 129.324 356.928 129.382C356.444 129.44 355.993 128.714 355.927 127.727C355.861 126.739 356.191 125.911 356.664 125.853C357.137 125.795 357.599 126.521 357.676 127.509Z" fill="#263238" />
                  <path d="M356.884 125.882L355.058 125.432C355.058 125.432 356.103 127.146 356.884 125.882Z" fill="#263238" />
                  <path d="M392.37 512.257H383.152L383.878 484.08H393.096L392.37 512.257Z" fill="#FFB573" />
                  <path d="M382.426 510.848H392.777C392.955 510.842 393.129 510.923 393.265 511.076C393.401 511.229 393.49 511.442 393.514 511.676L394.691 522.482C394.714 522.755 394.693 523.032 394.63 523.294C394.566 523.556 394.462 523.797 394.322 524.001C394.183 524.206 394.012 524.369 393.822 524.48C393.631 524.591 393.425 524.648 393.217 524.646C389.609 524.573 387.871 524.283 383.317 524.283C380.512 524.283 376.42 524.675 372.559 524.675C368.698 524.675 368.478 519.621 370.095 519.17C377.311 517.108 378.455 514.29 380.886 511.589C381.319 511.114 381.863 510.852 382.426 510.848V510.848Z" fill="#263238" />
                  <g opacity="0.2">
                     <path opacity="0.2" d="M393.096 484.08H383.878L383.504 498.604H392.722L393.096 484.08Z" fill="black" />
                  </g>
                  <path d="M355.707 178.649C350.067 175.754 344.541 172.481 339.152 168.845C333.586 165.254 328.283 160.99 323.312 156.108C321.984 154.734 320.727 153.244 319.55 151.649C319.242 151.213 318.934 150.806 318.637 150.298C318.288 149.778 317.965 149.23 317.669 148.657C316.858 147.096 316.351 145.295 316.195 143.413C316.074 141.63 316.258 139.833 316.734 138.156C317.115 136.808 317.637 135.537 318.285 134.379C319.348 132.461 320.602 130.741 322.014 129.267C324.506 126.646 327.197 124.373 330.044 122.484C331.408 121.525 332.794 120.668 334.202 119.87C335.61 119.071 336.996 118.315 338.503 117.633L340.45 123.225C335.654 127.219 330.682 131.62 327.03 136.529C326.213 137.622 325.493 138.836 324.885 140.145C324.357 141.293 324.368 142.15 324.456 142.077C324.544 142.005 324.456 142.077 324.61 142.222L325.05 142.847C325.215 143.108 325.446 143.37 325.655 143.646C326.587 144.778 327.575 145.826 328.614 146.783C330.885 148.906 333.25 150.846 335.698 152.593C338.173 154.423 340.725 156.151 343.321 157.807C348.513 161.089 353.881 164.241 359.15 167.117L355.707 178.649Z" fill="#FFB573" />
                  <path d="M343.2 119.317L344.718 114.96L337.766 111.837C337.766 111.837 335.566 120.421 338.173 124.371C339.262 124.145 340.292 123.564 341.17 122.682C342.047 121.8 342.745 120.643 343.2 119.317Z" fill="#FFB573" />
                  <path d="M344.685 105.534L339.152 103.413L337.766 111.779L344.685 114.96V105.534Z" fill="#FFB573" />
                  <path d="M416.185 115.381L416.988 105.214L409.86 107.48C409.86 107.48 409.75 117.037 413.523 118.547L416.185 115.381Z" fill="#FFB573" />
                  <path d="M413.556 98.0537L408.903 100.915L409.86 107.407L416.988 105.185L413.556 98.0537Z" fill="#FFB573" />
                  <path d="M382.294 120.915C382.635 132.984 382.965 138.082 378.84 144.836C372.625 155.003 361.328 152.897 358.27 141.205C355.52 130.689 355.432 112.723 363.968 106.797C365.845 105.479 367.945 104.817 370.07 104.872C372.195 104.928 374.274 105.699 376.11 107.113C377.946 108.528 379.478 110.538 380.56 112.953C381.642 115.369 382.239 118.109 382.294 120.915V120.915Z" fill="#FFB573" />
                  <path d="M378.312 144.822C387.288 139.695 393.184 128.845 390.698 112.026C388.311 95.8894 380.061 94.2917 376.651 97.6323C373.241 100.973 364.738 95.991 359.634 101.656C350.834 111.488 359.15 121.99 363.506 128.337C366.102 135.395 369.512 149.833 378.312 144.822Z" fill="#263238" />
                  <path d="M374.583 109.136C375.678 110.99 377.16 112.381 378.847 113.136C380.534 113.891 382.351 113.978 384.074 113.385C385.798 112.792 387.352 111.545 388.545 109.799C389.738 108.054 390.518 105.885 390.788 103.56C391.059 101.236 390.807 98.8578 390.066 96.7197C389.324 94.5816 388.125 92.7771 386.616 91.5288C385.106 90.2805 383.353 89.6431 381.572 89.6954C379.792 89.7476 378.062 90.4871 376.596 91.8227C374.591 93.7667 373.253 96.6824 372.875 99.9289C372.498 103.175 373.112 106.487 374.583 109.136Z" fill="#407BFF" />
                  <path d="M376.915 98.7651C375.628 89.0339 382.591 81.5393 391.919 85.1559C401.247 88.7724 396.319 99.6802 394.394 108.395C392.469 117.109 397.507 125.417 399.597 118.78C401.687 112.142 398.167 110.065 398.167 110.065C398.167 110.065 408.067 113.449 398.871 128.191C389.675 142.934 381.348 126.652 383.603 117.051C385.429 109.339 378.136 107.959 376.915 98.7651Z" fill="#263238" />
                  <path d="M367.598 102.439C363.341 99.5343 356.136 97.1378 352.033 106.259C350.097 110.616 350.845 116.426 350.845 116.426L363.374 117.515L367.598 102.439Z" fill="#263238" />
                  <path d="M349.371 113.55C349.297 113.543 349.228 113.498 349.179 113.425C349.13 113.351 349.104 113.256 349.107 113.158C349.107 112.911 349.426 107.072 352.099 103.412C358.556 94.5524 366.124 101.96 368.28 104.356C368.326 104.421 368.353 104.506 368.355 104.595C368.357 104.685 368.334 104.772 368.291 104.84C368.247 104.908 368.186 104.953 368.119 104.965C368.052 104.978 367.984 104.958 367.928 104.908C365.849 102.555 358.611 95.511 352.528 103.906C349.998 107.377 349.69 113.144 349.69 113.202C349.689 113.253 349.68 113.304 349.662 113.351C349.645 113.397 349.621 113.439 349.59 113.472C349.56 113.505 349.525 113.529 349.487 113.542C349.449 113.556 349.41 113.558 349.371 113.55Z" fill="#263238" />
                  <path d="M365.035 127.814C364.986 130.099 364.345 132.293 363.22 134.031C361.702 136.383 359.92 135.222 359.557 132.578C359.194 130.24 359.557 126.187 361.471 124.779C363.385 123.37 365.057 125.171 365.035 127.814Z" fill="#FFB573" />
                  <path d="M363.055 238.373C363.055 238.373 363.649 322.832 369.193 369.891C373.681 407.901 381.964 495.802 381.964 495.802H394.537C394.537 495.802 395.758 410.936 393.437 373.347C387.684 278.213 402.545 260.421 390.555 238.373H363.055Z" fill="#263238" />
                  <path opacity="0.1" d="M363.055 238.373C363.055 238.373 363.649 322.832 369.193 369.891C373.681 407.901 381.964 495.802 381.964 495.802H394.537C394.537 495.802 395.758 410.936 393.437 373.347C387.684 278.213 402.545 260.421 390.555 238.373H363.055Z" fill="white" />
                  <path opacity="0.3" d="M369.666 276.688C374.066 302.178 370.557 342.324 368.148 360C365.596 333.159 364.309 298.373 363.671 272.854C365.948 268.003 368.104 267.683 369.666 276.688Z" fill="black" />
                  <path d="M380.248 496.121H396.253L397.089 488.713L380.16 487.958L380.248 496.121Z" fill="#407BFF" />
                  <path d="M384.813 167.03C386.32 163.08 394.416 160.596 398.838 160.61L402.138 180.015C402.138 180.015 393.338 197.284 389.664 195.454C385.341 193.348 381.59 175.556 384.813 167.03Z" fill="#407BFF" />
                  <path opacity="0.4" d="M384.813 167.03C386.32 163.08 394.416 160.596 398.838 160.61L402.138 180.015C402.138 180.015 393.338 197.284 389.664 195.454C385.341 193.348 381.59 175.556 384.813 167.03Z" fill="black" />
                  <path d="M375.485 491.371C375.503 491.329 375.513 491.281 375.513 491.233C375.513 491.184 375.503 491.137 375.485 491.095C375.464 491.039 375.431 490.994 375.389 490.965C375.348 490.936 375.3 490.926 375.254 490.935C374.792 491.037 370.722 491.879 370.172 493.419C370.113 493.559 370.082 493.716 370.082 493.876C370.082 494.036 370.113 494.194 370.172 494.334C370.266 494.563 370.402 494.759 370.567 494.903C370.732 495.047 370.921 495.136 371.118 495.162C372.449 495.336 374.418 492.896 375.408 491.414L375.485 491.371ZM370.601 493.607C370.975 492.794 373.087 492.039 374.693 491.647C373.241 493.593 371.965 494.682 371.217 494.552C371.088 494.534 370.964 494.475 370.856 494.379C370.748 494.282 370.661 494.152 370.601 494C370.577 493.944 370.564 493.882 370.564 493.818C370.564 493.754 370.577 493.692 370.601 493.636C370.601 493.636 370.59 493.622 370.601 493.607Z" fill="#407BFF" />
                  <path d="M385.088 486.215L376.662 491.167L372.911 480.841L368.005 467.333L367.422 465.764L375.848 460.812L376.497 462.583L381.249 475.67L385.088 486.215Z" fill="#FFB573" />
                  <path opacity="0.2" d="M381.249 475.669L372.911 480.84L368.005 467.332L376.497 462.583L381.249 475.669Z" fill="black" />
                  <path d="M353.177 238.373C353.177 238.373 326.26 326.797 333.553 368.336C340.153 405.94 369.303 477.152 369.303 477.152L380.6 469.687C380.6 469.687 362.571 383.718 360.624 365.039C356.818 328.729 381.271 277.414 381.271 238.373H353.177Z" fill="#263238" />
                  <path opacity="0.1" d="M353.177 238.373C353.177 238.373 326.26 326.797 333.553 368.336C340.153 405.94 369.303 477.152 369.303 477.152L380.6 469.687C380.6 469.687 362.571 383.718 360.624 365.039C356.818 328.729 381.271 277.414 381.271 238.373H353.177Z" fill="white" />
                  <path d="M375.166 489.613L383.526 481.581C383.667 481.436 383.844 481.364 384.023 481.38C384.201 481.396 384.369 481.499 384.494 481.669L390.269 489.497C390.409 489.702 390.515 489.943 390.58 490.205C390.645 490.467 390.667 490.744 390.645 491.018C390.623 491.292 390.558 491.557 390.453 491.795C390.349 492.034 390.207 492.241 390.038 492.402C387.079 495.133 385.55 496.265 381.876 499.795C379.61 501.974 375.1 506.781 371.976 509.788C368.852 512.794 366.476 508.873 367.576 507.26C372.504 499.998 373.538 495.496 374.308 491.414C374.427 490.701 374.731 490.064 375.166 489.613V489.613Z" fill="#263238" />
                  <path d="M368.577 480.303L383.13 471.516L381.271 463.688L365.684 473.07L368.577 480.303Z" fill="#407BFF" />
                  <path d="M360.316 166.1C359.117 161.975 348.601 157.385 343.321 155.933L341 178.765C341 178.765 349.624 195.119 353.397 193.768C357.863 192.171 362.912 174.945 360.316 166.1Z" fill="#407BFF" />
                  <path opacity="0.4" d="M360.316 166.1C359.117 161.975 348.601 157.385 343.321 155.933L341 178.765C341 178.765 349.624 195.119 353.397 193.768C357.863 192.171 362.912 174.945 360.316 166.1Z" fill="black" />
                  <path d="M348.777 164.952C348.777 164.952 344.377 166.985 353.177 238.372H390.577C389.95 218.271 389.928 205.867 397.177 164.589C391.936 163.161 386.624 162.239 381.282 161.829C375.628 161.291 369.952 161.291 364.298 161.829C357.049 162.672 348.777 164.952 348.777 164.952Z" fill="#407BFF" />
                  <path opacity="0.4" d="M348.777 164.952C348.777 164.952 344.377 166.985 353.177 238.372H390.577C389.95 218.271 389.928 205.867 397.177 164.589C391.936 163.161 386.624 162.239 381.282 161.829C375.628 161.291 369.952 161.291 364.298 161.829C357.049 162.672 348.777 164.952 348.777 164.952Z" fill="black" />
                  <path d="M391.16 235.482L392.843 239.84C392.975 240.188 392.667 240.537 392.238 240.537H352.99C352.649 240.537 352.374 240.319 352.352 240.028L352.011 235.671C352.011 235.366 352.286 235.105 352.649 235.105H390.5C390.626 235.078 390.755 235.1 390.872 235.167C390.988 235.234 391.088 235.343 391.16 235.482Z" fill="#407BFF" />
                  <path opacity="0.3" d="M391.16 235.482L392.843 239.84C392.975 240.188 392.667 240.537 392.238 240.537H352.99C352.649 240.537 352.374 240.319 352.352 240.028L352.011 235.671C352.011 235.366 352.286 235.105 352.649 235.105H390.5C390.626 235.078 390.755 235.1 390.872 235.167C390.988 235.234 391.088 235.343 391.16 235.482Z" fill="white" />
                  <path d="M386.1 241.102H387.112C387.321 241.102 387.475 240.957 387.453 240.797L386.98 234.988C386.98 234.813 386.793 234.683 386.595 234.683H385.572C385.374 234.683 385.22 234.813 385.231 234.988L385.704 240.797C385.693 240.928 385.869 241.102 386.1 241.102Z" fill="#263238" />
                  <path d="M361.471 241.102H362.483C362.681 241.102 362.835 240.957 362.824 240.797L362.351 234.988C362.351 234.813 362.164 234.683 361.955 234.683H360.943C360.734 234.683 360.591 234.813 360.602 234.988L361.075 240.797C361.086 240.928 361.262 241.102 361.471 241.102Z" fill="#263238" />
               </svg>
            </center>

         </>
      )
   } else {
      return state.transactions.map((e) => {
         let userBuzzId = User.user_metadata.buzzID
         let isRead = false
         if (e.isRead.length > 0) {
            let check = e.isRead.filter(e => e.user == userBuzzId)
            if (check.length > 0) {
               isRead = true
            }  
         } else {
            isRead = false
         }
         
         if (e.type == "BUZ REQUEST") {
            return (
               <>
                
                  <div
                     onClick={() => {
                        dispTransactionToView(e)
                        history.push("/transaction-view")
                        markIsRead(e.id,userBuzzId)
                     }}
                     style={{

                        boxShadow: "0px 2px 4px rgba(12, 24, 37, 0.16)", height: "50px", width: "90%", borderRadius: "8px", position: "relative",
                        background: isRead == true ?"white":"lightgrey", padding: "15px", marginBottom: "20px"
                     }}>
                     {/* {e.from == User.user_metadata.buzzID ? <>
                        <img src={debit_icon} style={{ position: "absolute", top: "10px", right: "10px" }} />
                     </> : <>
                        <img src={credit_icon} style={{ position: "absolute", top: "10px", right: "10px" }} />
                     </>} */}

                     {/* <div style={{ marginBottom: "3px", marginTop: "5px", fontSize: "14px" }}>
                        <b> {e.type}</b>

                     </div> */}
                     {/* <b style={{ color: "rgba(7, 63, 116, 1)" }}>₦{separator(e.meta.data.amount)}</b> */}
                     <div style={{ fontSize: "14px" }}>
                        {e.type} of <b>₦{separator(e.meta.amount)} </b>
                        {e.from == User.user_metadata.buzzID ? <>
                           <spam> to </spam>  <b>{e.to}</b>
                        </> : <>
                           <spam> from </spam>  <b>{e.meta.from.name.split(" ")[0]}</b>
                        </>}

                     </div>
                     <div style={{ marginTop: "15px" }}>
                        <small style={{ float: "left", fontSize: "12px" }}>{e.meta.date.date} {e.meta.date.month}.  {e.meta.date.year}</small>
                        <small style={{ float: "right", marginRight: "10px", fontSize: "12px" }}>{e.meta.date.time}</small>
                     </div>
                  </div>
               </>
            )
         }
         else {
            return (
               <>
                  <div
                     onClick={() => {
                        dispTransactionToView(e)
                        history.push("/transaction-view")
                         markIsRead(e.id,userBuzzId)
                     }}
                     style={{

                        boxShadow: "0px 2px 4px rgba(12, 24, 37, 0.16)", height: "50px", width: "90%", borderRadius: "8px", position: "relative",
                        background:isRead == true ?"white":"lightgrey", padding: "15px", marginBottom: "20px"
                     }}>
                     {/* {e.from == User.user_metadata.buzzID ? <>
                        <img src={debit_icon} style={{ position: "absolute", top: "10px", right: "10px" }} />
                     </> : <>
                        <img src={credit_icon} style={{ position: "absolute", top: "10px", right: "10px" }} />
                     </>} */}

                     {/* <div style={{ marginBottom: "3px", marginTop: "5px", fontSize: "14px" }}>
                        <b> {e.type}</b>

                     </div> */}
                     {/* <b style={{ color: "rgba(7, 63, 116, 1)" }}>₦{separator(e.meta.data.amount)}</b> */}
                     <div style={{ fontSize: "14px" }}>
                        <b>₦{separator(e.meta.data.amount)} </b>  {e.type}
                        {e.from == User.user_metadata.buzzID ? <>
                           <spam> to </spam>  <b>{e.meta.receiver.fullname.split(" ")[0]}</b>
                        </> : <>
                           {/* <spam> from </spam>  <b>{e.meta.sender.fullname.split(" ")[0]}</b> */}
                        </>}

                     </div>
                     <div style={{ marginTop: "15px" }}>
                        <small style={{ float: "left", fontSize: "12px" }}>{e.meta.date.date} {e.meta.date.month}.  {e.meta.date.year}</small>
                        <small style={{ float: "right", marginRight: "10px", fontSize: "12px" }}>{e.meta.date.time}</small>
                     </div>
                  </div>
               </>
            )
         }



      })
   }

}