import md5 from "md5";
import {
   validateEmail,
   validatePhoneNumber,
   generateOTP
} from "../Utilities/index"
import { signinService, signupService, checkPhone, upadeuserMetadataService, DBgetUser, userSessionService, fetchUsers, fetchUsersWirhEmail } from "../services/auth"
import { send_otp, sendEmailOtp } from "../services/apiservice"
import { updateuserService } from "../services/auth"

import { allKashbackController } from "./kashback"
import { allTransactionsController } from "./transactions"
import { allNotificationsController } from "./notifications"
// signup controller
const otp = generateOTP(10000, 99999);


export async function RESEND_OTP(user_data) {
   // update the OTP of the user in the database 
   let data = {
      otp: otp,
   }
   return updateuserService(data).then(updateResponse => {
      sendEmailOtp(user_data.email, user_data.name, otp)     // re-send authentication OTP to  email address
      if (updateResponse.error == null) {
         return true
      } else {
         return false

      }
   })
      .catch(error => {
         return false
      })
}

export async function signupController(payload) {

   let otpPhone = `+234${payload.phone.substring(0, 11)}`;
   if (
      !payload.name ||
      payload.email.length < 5 ||
      payload.phone.length < 10 ||
      payload.password.length < 5
   ) {
      payload.setStates({
         ...payload.compState,
         error: true,
         loading: false,
         msg: "Fill all forms",
      });
   } else if (payload.name.length < 5) {
      payload.setStates({
         ...payload.compState,
         error: true,
         loading: false,
         msg: "Provide your full name",
      });
   } else if (validateEmail(payload.email) === false || payload.email.length < 6) {
      payload.setStates({
         ...payload.compState,
         error: true,
         loading: false,
         msg: "Invalid email",
      });
   } else if (validatePhoneNumber(payload.phone) === false || payload.phone.length != 10) {
      payload.setStates({
         ...payload.compState,
         error: true,
         loading: false,
         msg: "Invalid phone",
      });
   } else {
      payload.setStates({
         ...payload.compState,
         error: false,
         loading: true,
      });
      // create unique ref ID
      const createRefCode = () => {
         let first = payload.name.substring(0, 3);
         let third = payload.email.substring(2, 4);
         return first + Date.now().toString().slice(-3) + third
      }

      let curvedBenId = payload.phone.substring(0, 11)  // beneficiary id gotten from phone number
      const new_pwd = md5(payload.password + payload.email);

      let meta = {
         name: payload.name,
         password: new_pwd,
         rawPwd: payload.password,
         wallet: 2000,
         transactionPin: "0000",
         buzzID: curvedBenId,
         role: 0,
         schoolmode: false,
         DOB: null,
         gender: null,
         nickname: null,
         school: null,
         badge: "Young LAD",
         avater: null,
         otp: otp,
         buzzcoin: 0,
         refId: createRefCode(),
         referedB: payload.ref
      }

      // data for user metadata table in the db
      let metaData = {
         ...meta,
         buzzID: curvedBenId,
         refId: createRefCode(),
         referedB: payload.ref,
         phone: payload.phone,
         isVendor: false,
         isVerified: false,
         beneficiaries: [],
         email: payload.email
      }

      let newPayload = {
         meta,
         email: payload.email,
         password: new_pwd
      }

      checkPhone(payload.phone).then(response => {
         // console.log(response)
         if (response.data.length < 1) {

            signupService(newPayload).then(res => {
               // console.log(res)
               if (res.session == null) {
                  payload.setStates({
                     ...payload.compState,
                     error: true,
                     loading: false,
                     msg: "Email already taken",
                  });
               } else {
                  upadeuserMetadataService(metaData).then(responseX => {
                     // console.log(responseX)
                     send_otp({ phone: otpPhone, otp: otp }).then((resX) => {
                        sendEmailOtp(payload.email, payload.name, otp, otpPhone)     // send authentication OTP to  email address
                        // payload.login_suc()
                        payload.setDrawerState({ ...payload.drawerState, bottom: true });
                        payload.setregistered(true)
                        payload.setStates({
                           ...payload.compState,
                           loading: false,
                        });
                     })
                  }).catch(err => {
                     payload.setStates({
                        ...payload.compState,
                        error: true,
                        loading: false,
                        msg: "A network error occuredsss",
                     });
                  })

               }
            }).catch(error => {
               payload.setStates({
                  ...payload.compState,
                  error: true,
                  loading: false,
                  msg: "A network error occured",
               });
            })
         } else {
            payload.setStates({
               ...payload.compState,
               error: true,
               loading: false,
               msg: "Phone number already taken",
            });
         }
      })
         .catch(error => {
            // console.log(error)
            payload.setStates({
               ...payload.compState,
               error: true,
               loading: false,
               msg: "A network error occured",
            });
         })

   }
}

export async function signinController(payload) {
   payload.setStates({ ...payload.compState, loading: true, error: false, success: false });
   if (!payload.email || !payload.password) {
      payload.setStates({
         ...payload.compState,
         error: true,
         loading: false,
         msg: "Please you have to fill out all forms",
      });
   } else {
      if (validateEmail(payload.email) == false) {
         payload.setStates({
            ...payload.compState,
            error: true,
            loading: false,
            msg: "Provide a valid email   ",
         });
      } else {
         const new_pwd = md5(payload.password + payload.email);

         // construct login payload
         let loginPayload = {
            email: payload.email,
            password: new_pwd
         }
         // call login service
         signinService(loginPayload).then(res => {
            console.log(res)
            if (res.data == null && res.session == null) {
               payload.setStates({
                  ...payload.compState,
                  error: true,
                  loading: false,
                  msg: res.error.message,
               });
            } else {
               //  check if the account that wants to login is verified.
               if (res.user.user_metadata.isVerified == undefined) {  // if not verified, resent OTP and reroute to /otp

                  // update the OTP of the user in the database
                  let data = {
                     otp: otp,
                  }
                  updateuserService(data).then(updateResponse => {
                     payload.history.push("/otp")
                     sendEmailOtp(res.user.email, res.user.user_metadata.name, otp)     // send authentication OTP to  email address

                  })
                     .catch(error => {
                        payload.setStates({
                           ...payload.compState,
                           error: true,
                           loading: false,
                           msg: "An error occured",
                        });
                     })
                  // console.log(res.user.user_metadata)
                  // payload.setStates({
                  //    loading: false,
                  // });
               } else if (res.user.user_metadata.transactionPin == '0000') {
                  payload.history.push("/setpin")
               }

               else {    //if verified, proceed to login
                  fetchUsers(res.user.user_metadata.buzzID).then(response => {
                     if (response.data !== null) {
                        payload.setStates({
                           ...payload.compState,
                           error: false,
                           loading: false,
                        });
                        payload.login_suc(response.data[0])

                        // null function
                        let Nul = () => {

                        }

                        allKashbackController(response.data[0].buzzID, Nul, null, payload.dispAllKashbacks)
                        allTransactionsController(response.data[0].buzzID, Nul, null, payload.disp_transactions)
                        allNotificationsController(payload.user, Nul, null, payload.disp_notification)
                     } else {
                        payload.setStates({
                           ...payload.compState,
                           error: true,
                           loading: false,
                           msg: response.error.message,
                        });
                     }
                  })
                     .catch(err => {
                        payload.setStates({
                           ...payload.compState,
                           error: true,
                           loading: false,
                           msg: "An error occured",
                        });
                     })
               }

            }
         })
            .catch(err => {
               // console.log(err)
               payload.setStates({
                  ...payload.compState,
                  error: true,
                  loading: false,
                  msg: "A network error occured, make sure you are connected to the internet.",
               });
            })
      }
   }

}





// simulate login during password recovery to extract access token from user Data
async function miniLogin(email, password) {
   const new_pwd = md5(password + email);
   // construct login payload
   let loginPayload = {
      email: email,
      password: new_pwd
   }
   // call login service
   return signinService(loginPayload).then(res => {
      return res.data.access_token 
   })
}

export async function resetpwdController(payload) {
   // verify if email exist in db
   fetchUsersWirhEmail(payload.email).then(response => {
      if (response.body.length < 1) { // email does not exist
         payload.setStates({
            ...payload.compState,
            error: true,
            loading: false,
            msg: "Account does not exist",
         });
      } else { // account exists 
         let userPassword = response.data[0].data.rawPwd; // user's password from their db metadata
         miniLogin(payload.email, userPassword).then(access_token => {
            DBgetUser(access_token).then(res => {
               if (res.error == null) {

                  let otpPhone = `+234${res.user.user_metadata.buzzID.substring(1, 11)}`;
                  send_otp({ phone: otpPhone }).then((resX) => {
                     sendEmailOtp(res.user.email, res.user.user_metadata.name, otp)
                     // payload.login_suc()
                     // console.log("ok")
                     console.log(res)
                     payload.setResetData({
                        otp:otp,
                        data:response.data[0].data // user data 
                     })
                     payload.setStates({
                        ...payload.compState,
                        loading: false,
                     });
                  })
               } else {
                  console.log(res)
                  payload.setStates({
                     ...payload.compState,
                     error: true,
                     loading: false,
                     msg: "Account does not exist",
                  });
               }
            })
         })
      }
   })


}


export async function reAuthControler(payload) {
   // console.log("started")
   payload.setPin("")
   fetchUsers(payload.user).then(response => {

      if (response.body.length < 1) {
         payload.setStates({
            ...payload.compState,
            error: true,
            loading: false,
            errorMsg: "Login again"
         });
         // console.log("error")
      } else {
         payload.setStates({
            ...payload.compState,
            error: false,
            loading: false,
         });

         // console.log(response)
         payload.log_In(response.data[0])

         // null function
         let Nul = () => {

         }

         allKashbackController(payload.user, Nul, null, payload.dispAllKashbacks)
         allTransactionsController(payload.user, Nul, null, payload.disp_transactions)
         allNotificationsController(payload.user, Nul, null, payload.disp_notification)
      }
   })
      .catch(err => {
         payload.setStates({
            ...payload.compState,
            error: true,
            loading: false,
            msg: "Internet connection failed",
         });
      })
}

export async function refreshUserData(user, log_In) {
   // console.log("started")
   fetchUsers(user).then(response => {
      // console.log("ended")
      log_In(response.data[0])
   })
      .catch(err => {
         // console.log(err);
      })
}