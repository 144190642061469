import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { successAlert } from "../../components/alert"

import {
   Backspace,
   KeyboardBackspace,
   LockOpenOutlined,
   Lock,
   // KeyboardBackspace,
   PeopleAltOutlined,
   PublicOutlined,
   CloseOutlined
} from "@material-ui/icons";
import Naira from "react-naira";
import Logo from "../../statics/assets/logo.png"
import { FormControl, Drawer, Divider } from "@mui/material";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"
import { loader } from "../../components/loader"
// import buzz me request controllers
import { initiateBuzzmeController } from "../../controllers/scan/scan-to-pay"
import { initializeBuzzRequesrController } from "../../controllers/buzzme/request"
import { userSessionService, } from '../../services/auth'
import { fetchUsers } from '../../services/users'
import { session, clearCounterController } from "../../controllers/checksession"
import {
   login,
   disp_session,
   re_auth,
   disp_realtime,
   disp_noti
} from "../../redux";
import Realtime from "../../components/realtime"

function Home({
   appState,
   login_suc,
}) {

   const [stage, setStage] = useState(null)

   const state = appState;
   const history = useHistory();
   const [desc, setDesc] = useState("");
   const [amount, setAmount] = useState(0);
   const [max, setMax] = useState("");
   const [pin, setPin] = useState("");
   const [pinError, setpinError] = useState("");
   const [compState, setStates] = useState({
      loading: false
   });
   const [beneficiary, setbeneficiary] = useState("");
   const [verifyError, setverifyError] = useState("");
   const [stateAlert, setStateAlert] = useState("");
   const [actionType, setActiontype] = useState(null); // set treu if action type is send else set false if action type is request 
   // @==== RESET ALL STATE UON SUCCESS
   const sendToDefault = () => {
      setbeneficiary("");
      setActiontype(null);
      setPin("");
      setAmount(0);
      setDrawerState({ ...drawerState, bottom: false });
      setStates("")
   };
   let User = userSessionService().user.user_metadata
   const append = (e) => {

      let newPin = amount + "" + e;
      setAmount(newPin);
   };

   const clear = (e) => {
      let pinLength = pin;
      let clearOne = pinLength - 1;
      setAmount(0);
      setMax("");
   };

   const buttonValue = (e) => {
      if (e == "clear") {
         return <Backspace />;
      } else if (e == "out") {
         return <KeyboardBackspace />;
      } else {
         return <>{e}</>;
      }
   };

   const showAmount = () => {
      return (
         <>
            {" "}
            <b style={{ opacity: "1", color: "#0a3d62", fontSize: "35px" }}>
               <Naira>{amount}</Naira>
            </b>
         </>
      );
   };

   const pinVal = () => {
      if (pin.length == 1) {
         return (
            <>
               {" "}
               <b style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </b>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
            </>
         );
      } else if (pin.length == 2) {
         return (
            <>
               {" "}
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
            </>
         );
      } else if (pin.length == 3) {
         return (
            <>
               {" "}
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
            </>
         );
      } else if (pin.length == 4) {
         return (
            <>
               {" "}
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
            </>
         );
      } else if (pin.length == 5) {
         return (
            <>
               {" "}
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "1", color: "#0a3d62" }}>
                  <LockOpenOutlined />
               </span>
            </>
         );
      } else {
         return (
            <>
               {" "}
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.4", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.5", color: "orange" }}>
                  <Lock />
               </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <span style={{ opacity: "0.5", color: "orange" }}>
                  <Lock />
               </span>
            </>
         );
      }
   };


   const sendMessage = async (payload) => {
   };





   React.useEffect(() => {

      if (User == null) {
         // logOut()
         history.push("/login")
      } else if (state.userData == null) {
         history.push("/")
      } else {
      }



   }, []);

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }



   const buttons = () => {
      let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "00", 0, "clear"];
      return numbers.map((e) => {
         return (
            <>
               {/* {console.log(state)} */}
               <button
                  className="customInput"
                  style={{
                     width: "60px",
                     padding: "2px 20px",
                     fontWeight: "bold",
                     border: "none",
                     background: "white",
                     //   color: "black",
                     margin: "20px 20px",
                     fontSize: "20px",
                     textAlign: "center",
                     borderRadius: "5px",
                  }}
                  value={e}
                  onClick={() => {
                     if (e == "clear") {
                        clear(e);
                     } else if (e == "out") {
                        history.push("/");
                     } else {
                        append(e);
                     }
                  }}
               >
                  {buttonValue(e)}
               </button>
            </>
         );
      });
   };


   const [reason, setReason] = useState("");
   const [privacy, setPrivacy] = useState(1);
   const [sendTo, setSendTo] = useState("");
   const [beneficiaryData, setBeneficiaryData] = useState()


   const initiateTransaction = () => {
      let payload = {
         setStates, compState,
         amount: amount - amount * state.scannedData.data.store.discount / 100,
         beneficiaryData: state.scannedData,
         setBeneficiaryData,
         User,
         UserMeta: userSessionService().user,
         desc,
         setStage,
         setDrawerState,
         drawerState,
         date: {
            day: daysOfTheWeek(new Date),
            month: monthsOfTheYear(),
            year: new Date().getFullYear(),
            date: new Date().getDate(),
            time: formatAMPM(new Date())
         },
         setPin,
         login_suc,
         sendMessage
      }

      initiateBuzzmeController(payload)
   }

   const appendPin = (e) => {
      setpinError("")
      let newPin = pin + e;
      if (pin.length !== 5) {
         setPin(newPin);
      }
      if (newPin.length == 5) {
         verify(newPin);
      }
      //  console.log(newPin)
   };

   const clearPin = (e) => {
      let pinLength = pin.length;
      let clearOne = pinLength - 1;
      setPin(pin.substring(0, clearOne));
   };

   const verify = (newPin) => {
      if (newPin == state.scannedData.data.store.storepin) {
         initiateTransaction();
      } else {
         setpinError("Incorrect pin");
         setPin("");
         console.log("Not same");
         window.navigator.vibrate([200]);
      }
      //  console.log(state.loggedInUser.user.meta.transactionPin)
      //  console.log(newPin)
   };

   //   AUTHENTICATION KEYBOARD
   const authKeyboaard = () => {
      let pinNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'out', 0, "clear"];
      return pinNumbers.map((e) => {
         return (
            <>
               {/* {console.log(state)} */}
               <button
                  className="customInput"
                  style={{
                     width: "60px",
                     padding: "2px 20px",
                     fontWeight: "bold",
                     border: "none",
                     background: "white",
                     //   color: "black",
                     margin: "15px 20px",
                     fontSize: "20px",
                     textAlign: "center",
                     borderRadius: "5px",
                  }}
                  value={e}
                  onClick={() => {
                     if (e == "clear") {
                        clearPin(e);
                     } else if (e == "out") {
                        setDrawerState({ ...drawerState, bottom: false });
                        setStage(null);
                     } else {
                        appendPin(e);
                     }
                  }}
               >
                  {buttonValue(e)}
               </button>
            </>
         );
      });
   };


   let successPayload = {
      title: "SUCCESS",
      msg: compState.alertMsg,
      error: false,
   };

   let errorPayload = {
      title: "error",
      msg: compState.alertMsg,
      error: true,
   };


   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      // console.log("okk");
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setDrawerState({ ...drawerState, [anchor]: open });
      if (stage == "SENDBUZZ-SUCCESS") {
         history.push("/dashboard")
      }
   };

   return (
      <div id="body bg">
         <Realtime />
         {clearCounterController()}
         {/* {session(history, reauth)} */}
         <>
            {console.log(state)}
            {compState.loading == true && loader()}
            {stateAlert === true &&
               alert(successPayload, setStateAlert, sendToDefault)}
            {stateAlert === false && alert(errorPayload, setStateAlert)}
            {/* {pin == "validated" && <> {cashbackloader()}</>} */}
            <div
               style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: "30px",
                  left: "0px",
                  // zIndex: "10000",
                  background: "",
               }}
            >
               <div style={{ marginBottom: "60px", height: "30px", background: " ", padding: "0px 15px", textAlign: "center" }}>
                  <KeyboardBackspace onClick={() => {
                     history.goBack()
                  }} style={{ float: "left", fontSize: "30px" }} />
                  <img src={Logo} style={{ width: "80px", float: "right" }} />


                  <div style={{ position: "absolute", top: "65px", fontSize: "", background: " ", width: "60%", left: "20%", }} >
                     <b style={{ fontSize: "20px", color: "#0C1825" }}>{state.scannedData.data.store.name}</b>
                     <div>
                        <small style={{ fontSize: "12px" }}> {state.scannedData.data.store.address} </small>
                     </div>
                     <div>
                        <small style={{ fontSize: "12px", color: "crimson" }}>{state.scannedData.data.store.discount}% off your purchase</small>
                     </div>
                  </div>
               </div>

               <div
                  style={{
                     height: "",
                     // background: "black",
                     padding: " ",
                     textAlign: "center",
                  }}
               >
                  <div
                     style={{
                        padding: "15px",
                        // background: "lightgray",
                        color: "white",
                        position: "absolute",
                        top: "21%",
                        width: "100%",
                        textAlign: "center",
                     }}
                  >
                     <div>
                        <small style={{ color: "grey", fontSize: "12px" }}>Enter amount</small>
                     </div>
                     {showAmount()}
                     {/* {verify()} */}
                     <div style={{ color: "crimson" }}>{max}</div>
                  </div>
                  <div style={{ position: "absolute", top: "35%", width: "100%" }}>
                     {buttons()}
                     <br /> <br />
                     <div>
                     </div>
                  </div>
               </div>
            </div>
            <input
               type="button"
               value="NEXT"
               style={{
                  padding: "13px 17px",
                  outline: "none",
                  width: "90%",
                  background: " #073F74",
                  border: "0.5px solid #0C1825",
                  color: "white",
                  borderRadius: "6px",
                  left: "5%",
                  fontWeight: "bold",
                  position: "absolute",
                  bottom: "30px"
               }}

               onClick={() => {
                  if (parseInt(amount) > parseInt(state.userData.wallet)) {
                     setMax("Insufficient wallet balance");
                  } else if ((parseInt(amount) < 1)) {
                     setMax("Provide amount");

                  }
                  else {
                     setMax("");
                     setDrawerState({ ...drawerState, bottom: true });
                     setActiontype("SEND");
                     setverifyError('')
                     setStage("authorize")
                  }
               }}
            />

            <React.Fragment key="bottom">
               <Drawer
                  anchor="bottom"
                  open={drawerState["bottom"]}
                  onClose={toggleDrawer("bottom", false, false)}
               >
                  <div
                     style={{
                        height: " ",
                        //  background: "black",
                        padding: "10px",
                        textAlign: "center",
                     }}
                  >
                     <div
                        style={{
                           width: "",
                           //   marginLeft: "32.5%",
                           //   textAlign: "left",
                           background: " ",
                        }}
                     >
                        <div style={{ color: "crimson" }}>{verifyError}</div>


                     </div>
                     <br />
                  </div>

                  {stage === 'authorize' && (
                     <div
                        style={{
                           height: " 610px",
                           background: " ",
                           // padding: "10px",
                           textAlign: "center",
                        }}
                     >
                        {/* {showAmount()}  */}
                        <s style={{ opacity: "1", color: "#0a3d62", fontSize: "30px" }}>
                           <Naira>{amount}</Naira>
                        </s> &nbsp; &nbsp;
                        <b style={{ opacity: "1", color: "#0a3d62", fontSize: "30px" }}>
                           <Naira>{amount - amount * state.scannedData.data.store.discount / 100}</Naira>
                        </b>

                        <div> <small>{state.scannedData.data.store.discount}% off your purchase from this store</small> </div>

                        <br />
                        <hr />

                        <br />
                        <div>
                           <b>Authorize transaction </b>
                           <div><small>provide Marchant Store Pin </small></div>
                        </div>
                        <div
                           style={{
                              padding: "15px",
                              // background: "black",
                              color: "white",
                              //  marginBottom:"5px"
                              marginTop: "10px"
                           }}
                        >
                           {pinVal()}

                           <div
                              style={{
                                 color: "crimson",
                                 height: "30px",
                                 background: " ",
                                 padding: "5px",
                              }}
                           >

                           </div>
                        </div>
                        {authKeyboaard()}
                        <span style={{ color: "crimson" }}>{pinError}</span>
                     </div>
                  )}

                  {stage == 'SENDBUZZ-SUCCESS' && <>
                     <center style={{ marginTop: "5%", marginBottom: "5%", background: "", width: "90%", marginLeft: "5%", padding: "20px 0px", borderRadius: "6px", height: "300px" }}>
                        {/* <CheckCircle style={{ fontSize: "80px",color:"white" }} /> */}
                        <img src={Logo} style={{ width: "100px", }} />

                        <div style={{ marginTop: "60px", width: "70%", textAlign: "center" }}>
                           <div style={{ color: "black", fontSize: "18px" }}>
                              <b style={{ color: "#0C1825" }}><Naira>{parseInt(amount - amount * state.scannedData.data.store.discount / 100)}</Naira> </b> <br />
                              sent successfully to

                              <br /><b style={{ color: "#0C1825" }}> {state.scannedData.data.store.name}</b>
                           </div>

                           <button
                              style={{
                                 marginTop: "60px",
                                 border: "none",
                                 padding: "10px 20px",
                                 borderRadius: "5px",
                                 color: "white",
                                 background: "#0C1825"
                              }}
                              onClick={() => {
                                 // setDrawerState({ ...drawerState, bottom: false });
                                 setStage(null)
                                 history.push("/dashboard")
                              }}>
                              <b>OK</b>
                           </button>
                        </div>
                     </center>
                  </>}

                  {stage == 'SENDBUZZ-ERROR' && <>
                     <center style={{ marginTop: "5%", marginBottom: "5%", background: "", width: "90%", marginLeft: "5%", padding: "5px 0px", borderRadius: "6px", height: "300px" }}>
                        {/* <CheckCircle style={{ fontSize: "80px",color:"white" }} /> */}
                        <img src={Logo} style={{ width: "100px", }} />

                        <div style={{ marginTop: "30px", width: "70%", textAlign: "center", background: "", padding: "15px", borderRadius: "5px" }}>
                           <div style={{ color: "black", fontSize: "18px" }}>
                              <b style={{ color: "crimson" }}> Network error</b> <br />
                              <small style={{ fontSize: "12px", color: "red" }}>Make sure you are connected to the internet</small>
                           </div>

                           <button
                              style={{
                                 marginTop: "30px",
                                 border: "none",
                                 padding: "10px 20px",
                                 borderRadius: "5px",
                                 color: "white",
                                 background: "crimson"
                              }}
                              onClick={() => {
                                 setDrawerState({ ...drawerState, bottom: false });
                                 setStage(null)
                              }}>
                              <b>OK</b>
                           </button>
                        </div>
                     </center>
                  </>}

               </Drawer>
            </React.Fragment>
         </>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      reauth: () => dispatch(re_auth()),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
