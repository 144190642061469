import React, { useState } from "react";
import { connect } from "react-redux";
import {
   KeyboardBackspace
} from "@material-ui/icons";
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect, useHistory, Link } from "react-router-dom";
import { loader } from "../../components/loader"
import { session, clearCounterController } from "../../controllers/checksession"
import { createCommentController } from "../../controllers/feeds"
import { userSessionService, } from '../../services/auth'
import { transactionComponent } from "../../components/transactions"
import {
   transactions,
   transactionsToView,
   disp_session,
   re_auth,
   disp_realtime,
   disp_noti,
   login,
   disp_feeds,
   viewFeed
} from "../../redux";
import { TextareaAutosize } from "@mui/material";
import PropTypes from "prop-types";
import { SinglePost } from "../../components/giveaway"
import {
   Send,
} from "@material-ui/icons";
import { getGiveawayWinners } from "../../services/giveaway"  // get those who benefited from giveaway

import { successAlert } from "../../components/alert"
// import io from "socket.io-client";
import { cashbackchargecentage, buzzPayChargeOnKashbak, cashbackRegEx, formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"
// const socket = io.connect("https://buzpaysucket.harvoxx.com/");


const formSTyle = {
   width: "100%",
   marginLeft: "0%",
   marginTop: "30px"
}
function Login({
   appState,
   reauth,
   dispFeedView


}) {


   let User = userSessionService()
   const [comment, setComment] = useState("");

   const [compState, setStates] = useState({
      error: false,
   })
   const state = appState;
   let history = useHistory();
   React.useEffect((compState) => {
      document.getElementById("commentInputXX").scrollIntoView();
      if (User == null) {
         // logOut()
         history.push("/login")
      } else if (state.userData == null) {
         reauth()
      } else {
      }
   }, []);


   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }

   const [createpost, setcreatepost] = useState(false);
   const [loader, setloader] = useState(false);
   const [postToComment, setPostToComment] = useState("");

   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });




   const toggleDrawer = (anchor, open, post) => (event) => {
      if (post != false) {
         setPostToComment(post.data.id);
      }
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }
      setcreatepost(false)
      setDrawerState({ ...drawerState, [anchor]: open });
   };


   //  giveaway beneficiary
   const buzUsers = (payload) => {  
      if (payload) {
         payload.getGiveawayWinners(payload.postId).then(res => {
            // console.log(res)
            setloader(false)
         })
      }

   } 


   const allGiveAwayPosts = state.viewfeed;
   function renderFeeds() {
      buzUsers({
         giveawayData: comment, 
         postId: allGiveAwayPosts.id,
         poster: allGiveAwayPosts.poster,
         getGiveawayWinners
      })
      return (
         <SinglePost
            loading={false}
            data={allGiveAwayPosts}
            //  handleUnlikes={handleUnlikes}
            //  handleLike={handleLike}
            history={history}
            toggleDrawer={toggleDrawer}
            state={state}
            dispFeedView={dispFeedView}
            comment={comment}
            setComment={setComment}
            buzUsers={buzUsers}
         />
      );
   }
   SinglePost.propTypes = {
      loading: PropTypes.bool,
   };

   const handleComment = () => {
      document.getElementById("view").scrollIntoView();
      const payload = {
         user: {
            name: User.user.user_metadata.name,
            buzzID: User.user.user_metadata.buzzID
         },
         comment,
         post: {
            ...allGiveAwayPosts.data.post,
            id: allGiveAwayPosts.id
         },
         dispFeedView,
         allGiveAwayPosts

      }
      createCommentController(payload)
   }




   return (
      <>
         {state.loggedIn === false && history.push("/")}
         {/* {compState.loading == true && loader()} */}
         {/* {clearCounterController()} */}
         {/* {session(history, reauth)} */}


         <div style={{ position: "sticky", top: "0px", backgroundColor: "rgb(244 244 244)", padding: "20px", zIndex: "1000" }}>
            <KeyboardBackspace style={{
               fontWeight: ' 700',
               fontSize: ' 30px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
            }}
               onClick={() => {
                  history.push("/giveaway")
               }}
            />

            <b style={{
               fontWeight: ' 700',
               fontSize: ' 17px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
               float: "right",
            }}>
               BuzPay Giveaway
            </b>
         </div>
         <form style={formSTyle} noValidate autoComplete="off" >
            {compState.loading == true &&

               <div style={{ position: "relative", top: "40%", textAlign: " ", width: "100%", background: "white", left: "0px" }}>
                  <center>
                     <CircularProgress style={{ width: "20px", height: "20px" }} />
                  </center>
               </div>
            }


            <div className="" style={{ background: " " }}>
               {renderFeeds(state.feeds)}
            </div>
 

            <span id="commentInputXX" ></span>
            <div
               style={{
                  position: " fixed",
                  // height: "90px",
                  background: "rgb(244 244 244)",
                  borderRadius: "27px",
                  padding: "3px",
                  width: "100%",
                  bottom: "0px"
               }}
            >
               <TextareaAutosize
                  autoFocus
                  id="commentInput"
                  onChange={(e) => {
                     setComment(e.target.value);
                  }}
                  value={comment}
                  aria-label="minimum height"
                  minRows={1}
                  maxRows={4}
                  placeholder="Write a comment...."
                  style={{
                     width: "86%",
                     outline: "none",
                     borderRadius: "27px",
                     border: "none",
                     background: "#f0f0f0",
                     padding: "10px",
                     paddingRight: " ",
                     resize: "none",
                  }}
               />

               {comment.length > 0 &&
                  <Send
                     onClick={() => {
                        // setDrawerState({ ...drawerState, ["bottom"]: false });
                        handleComment();
                        setComment('')


                     }}
                     style={{ position: "absolute", right: "10px", bottom: "10px" }}
                  />}


            </div>


         </form>

      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      disp_transactions: (payload) => dispatch(transactions(payload)),
      dispTransactionToView: (payload) => dispatch(transactionsToView(payload)),
      set_session: (time) => dispatch(disp_session(time)),
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      loadFeeds: (payload) => dispatch(disp_feeds(payload)),
      reauth: () => dispatch(re_auth()),
      dispFeedView: (payload) => dispatch(viewFeed(payload)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
