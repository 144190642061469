import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../statics/assets/loaderAlt.png"
// @======== LOADING TO GENERATE TOKEN
import "../statics/loader.css";





export function loader(type, setpinController, setloading, loading, pin, successAlert, btnAction, message,login_suc,history) {
  if (type == "SETPIN") {
     let data = {
       transactionPin: pin,
       otp:'-'
   }
    setpinController(data, setloading,history, "SET-PIN")
    
    
    if (loading == true) {
      return (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              background: "rgb(0,0,0,0.6)",
              left: "0px",
              top: "0px",
              zIndex: "100000"
            }}
          >
            <div
              style={{
                position: "relative",
                width: "15%",
                // height: "60px",
                background: "lightgrey",
                top: "40%",
                left: "40%",
                borderRadius: "5px",
                textAlign: "center",
                padding: "5px 0px"
              }}
            >
              {/* <img src={loaderImg} /> */}
              <CircularProgress />
            </div>
          </div>
        </>
      );
    } else {
      return successAlert(btnAction, message)
    }
  }
  else  if (type == "VERIFY-ACCOUNT") {
    let data = {
      isVerified: true, 
  }
   setpinController(data, setloading,history, "VERIFY-ACCOUNT")
   
   
   if (loading == true) {
     return (
       <>
         <div
           style={{
             height: "100%",
             width: "100%",
             position: "fixed",
             background: "rgb(0,0,0,0.6)",
             left: "0px",
             top: "0px",
             zIndex: "100000"
           }}
         >
           <div
             style={{
               position: "relative",
               width: "15%",
               // height: "60px",
               background: "lightgrey",
               top: "40%",
               left: "40%",
               borderRadius: "5px",
               textAlign: "center",
               padding: "5px 0px"
             }}
           >
             {/* <img src={loaderImg} /> */}
             <CircularProgress />
           </div>
         </div>
       </>
     );
   } else {
     return successAlert(btnAction, message)
   }
 }  else {
    return (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              background: "rgb(0,0,0,0.9)",
              left: "0px",
              top: "0px",
              zIndex: "100000"
            }}
          >
            <div
              style={{
                position: "relative",
                width: "30%",
                height: "70px",
                background: "",
                top: "40%",
                left: "35%",
                borderRadius: "5px",
                textAlign: "center",
                padding: "9px 0px"
              }}
            >
              {/* <img src={loaderImg} /> */}
            {/* <CircularProgress /> */}
             <img src={Logo} id="loader" />
            </div>
          </div>
        </>
      );
  }

}
