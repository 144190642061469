import { supabase } from '../configurations/index';
const newSupabase = supabase()


export async function insertNotificationService(payload) {
   return newSupabase.from("notifications")
      .insert([
         {
            from: payload.sender,
            to: payload.receiver,
            meta: payload.data,
            type: "BUZZ ALERT",
         },
      ])
}

export const allNotificationsService = (userBuzzID) => {
   return newSupabase.from("notifications")
      .select("*")
      .order('id', { ascending: false })
}

export async function markIsReadService(id,data) {
    return newSupabase
   .from('transactions')
  .update({ isRead:data })
  .eq('id', id)
}
export async function SelectmarkIsReadService(id) {
    return newSupabase
   .from('transactions')
  .select("isRead")
  .eq('id', id)
}