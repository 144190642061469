import { generate_cashback } from "../services/apiservice"
import { updateWalletService, updateBuzCoinWallet } from "../services/buzme"
import { insertBuzzmeTransactionService, allKashbackService } from "../services/cashback"
import { fetchUserProfileWithRef } from "../services/users"

export const createKashbackController = (payload) => {

   const userNewWallet = parseInt(payload.User.wallet) - parseInt(payload.amountPlusCharge)
   const newPayload = {
      amount: userNewWallet,
      user: payload.User.buzzID
   }

   // token generation
   const token = payload.cashbackRegEx(
      payload.User.buzzID,
      `+234${payload.User.buzzID}`,
      payload.User.refId,
      payload.User.password + new Date().getTime(),
      payload.amount
   );

   // insert into transaction payload
   const insertPayload = {
      buzzID: payload.User.buzzID,
      data: {
         sender: {
            fullname: payload.User.data.name,
            email: payload.User.data.email,
            buzzID: payload.User.buzzID,
            phone: `+234${payload.User.buzzID}`,
         },
         receiver: {
            Fullname: '',
            buzzID: "",
            phone: "",
            email: "",
         },
         data: {
            amount: payload.amount,
            amountPlusCharge: payload.amountPlusCharge,
            charge: payload.charge,
            ref: new Date().getTime(),
            token,
            date: payload.date,
            adminCharge: payload.adminTakes
         },
         date: payload.date
      }
   }

   // debite the user
   updateWalletService(newPayload).then(res => {
      // console.log(res)
      if (res.body == null) {
         payload.setStates({
            ...payload.compState,
            loading: false
         })
         payload.setStage(false)
      } else {

         // recored transaction to transation table
         insertBuzzmeTransactionService(insertPayload).then(resp1 => {
            payload.state.kashbacks.push(resp1.data[0])
            payload.dispAllKashbacks(payload.state.kashbacks)
            if (resp1.data == null) {
               payload.setStates({
                  ...payload.compState,
                  loading: false
               })
               payload.setStage(false)
            } else {

               payload.setStates({
                  ...payload.compState,
                  loading: false
               })
               payload.setStage(true)
               payload.login_suc(res.body[0])
               payload.disp_scannedData(resp1.body[0])
               payload.dispTransactionToView(resp1.body[0])

               // crdit who referred with BuzCoin
               console.log("crdit who referred with BuzCoin")

               fetchUserProfileWithRef(payload.User.data.referedB).then(fetched => {
                  // function ParseFloat(str, val) {
                  //    str = str.toString();
                  //    str = str.slice(0, (str.indexOf(".")) + val + 1);
                  //    return Number(str);
                  // }
                  if (fetched.body.length > 0) {
                     let perc = (0.1 * payload.amountPlusCharge) / 100;
                     let newCoinWallet = perc + fetched.body[0].coin_wallet;

                     let roundedCoin = newCoinWallet
                     updateBuzCoinWallet({ coin: roundedCoin, user: payload.User.data.referedB }).then(res => {
                        console.log(res)
                     })


                  }

               })
               generate_cashback({
                  user: payload.User.data.name,
                  token: token,
                  email: payload.User.email,
                  amount: payload.amount
               })

            }
         })
            .catch(error => {
               payload.setStates({
                  ...payload.compState,
                  loading: false
               })
               payload.setStage(false)

            })
      }
   })
      .catch(error => {
         payload.setStates({
            ...payload.compState,
            loading: false
         })
         payload.setStage(false)

      })

}

export const allKashbackController = (user, setStates, compState, dispAllKashbacks) => {
   // console.log("started")
   allKashbackService(user).then(res => {
      // console.log(res)

      if (res.data == null) {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         dispAllKashbacks([])
      } else {
         setStates({
            ...compState,
            loading: false,
            error: false
         })
         dispAllKashbacks(res.data)
         // console.log('ended')
      }
   })
      .catch(err => {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         dispAllKashbacks([])
      })
}