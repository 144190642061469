import { verifyMarchantQrService } from "../../services/scanpay"

import { userSessionService, updateuserService } from "../../services/auth"
import { getAllUsersMetadaService } from "../../services/users"

import {
   send_buzz_alert,
   buzz_request,
} from "../../services/apiservice"
// import * from buzz me services
import { getBeneficiaryService, updateBuzzMeWalletService, updateWalletService, insertBuzzmeTransactionService } from "../../services/buzme"
import md5 from "md5"






export const verifyMarchenQRController = (payload) => {
   console.log(payload)
   // const marchantID = payload.token.split("-")[1];
   const marchantID = payload.token;
   verifyMarchantQrService(marchantID).then(res => {
      if (res.body !== null && res.body.length == 1) {
         payload.disp_scannedData(res.body[0])
         payload.history.push("/scan-to-pay")
         // console.log(res)
      } else {
         payload.setStates({
            ...payload.compState,
            loading: false,
            tokenData: {},
            tokenActive: false,
            error: true,
            errorMsg: "Invalid Marchant"
         })
         payload.setDrawerState({ ...payload.drawerState, bottom: true });
      }
   })
      .catch(error => {
         payload.setStates({
            ...payload.compState,
            loading: false,
            tokenData: {},
            tokenActive: false,
            error: true,
            errorMsg: "A network error occured"
         })
         payload.setDrawerState({ ...payload.drawerState, bottom: true });
      })
}


export const initiateBuzzmeController = (payload) => {
   let amount = parseInt(payload.amount)
   payload.setStage(null)
   payload.setStates({ ...payload.compState, loading: true });
   getAllUsersMetadaService().then(res => {
      if (res.error == null && res.body.length > 0) {

         // get marchant wallet address
         let marchantWalletBalance = res.data.filter(e => e.buzzID == payload.beneficiaryData.buzzID)[0]

         console.log(res.data)
         console.log(payload)
         let marchantNewBuzzmeWallat = parseInt(payload.amount) + parseInt(marchantWalletBalance.wallet)

         // wallet update payload for Marchant
         let wallt_update_payload_for_marchants = {
            amount: marchantNewBuzzmeWallat,
            user: payload.beneficiaryData.buzzID
         }
         // console.log(marchantNewBuzzmeWallat)


         // get user's wallet balance
         let userWalletBalance = res.data.filter(e => e.buzzID == payload.User.buzzID)[0]
         let userBuzzmeWallaet = parseInt(userWalletBalance.wallet) - parseInt(payload.amount)

         // wallet update payload for User
         let wallt_update_payload_for_user = {
            amount: userBuzzmeWallaet,
            user: payload.User.buzzID
         }
         // console.log(userBuzzmeWallaet)


         // create payload to save in the transaction history
         let transactionPayload = {
            sender: {
               fullname: payload.User.name,
               buzzID: payload.User.buzzID,
               phone: `+234${payload.User.buzzID}`,
            },
            receiver: {
               fullname: payload.beneficiaryData.data.name,
               buzzID: payload.beneficiaryData.buzzID,
               phone: `+234${payload.beneficiaryData.buzzID}`,
            },
            data: {
               amount,
               desc: payload.desc,
               ref: new Date().getTime()
            },
            date: payload.date,
            type: "BUZ ALERT"
         };


         // deduct from who is sending
         updateWalletService(wallt_update_payload_for_user).then(response1 => {
            // console.log("giver")
            // console.log(response1)
            payload.login_suc(response1.data[0])
            // add to who is receiving
            updateWalletService(wallt_update_payload_for_marchants).then(response => {
               payload.setPin("")
               payload.setStage("SENDBUZZ-SUCCESS")
               payload.setStates({ ...payload.compState, loading: false });
               payload.sendMessage(transactionPayload)

               let smsPayload = {
                  phone: [
                     `+234${payload.beneficiaryData.buzzID}`,
                  ],
                  sender: userWalletBalance,
                  amount: amount,
                  desc: payload.desc,
                  balance: marchantNewBuzzmeWallat,
               };
               // send_buzz_alert(smsPayload)
               // insert to tansaction
               insertBuzzmeTransactionService(transactionPayload).then(responseX => {
                  let alertPayload = {
                     phone: [
                        `+234${payload.beneficiaryData.buzzID}`,
                     ],
                     sender_balance: userBuzzmeWallaet,
                     amount: amount,
                     desc: payload.desc,
                     receiver_balance: marchantNewBuzzmeWallat,
                     receiver_email: payload.beneficiaryData.email,
                     sender_email: payload.UserMeta.email,
                     means: "Scan To Pay",
                     sender: payload.User.name,
                     receiver: payload.beneficiaryData.data.name,
                     type: "",

                  };
                  // console.log(alertPayload)
                  // console.log(payload)
                  send_buzz_alert(alertPayload)
               })
                  .catch(error1 => {
                     console.log(error1)
                     payload.setPin("")

                     payload.setStage("SENDBUZZ-ERROR")
                     payload.setStates({ ...payload.compState, loading: false });
                  })
            })
               .catch(error2 => {
                  payload.setPin("")
                  console.log(error2)
                  payload.setStage("SENDBUZZ-ERROR")
                  payload.setStates({ ...payload.compState, loading: false });
               })
         })
            .catch(error3 => {
               payload.setPin("")
               console.log(error3)
               payload.setStage("SENDBUZZ-ERROR")
               payload.setStates({ ...payload.compState, loading: false });
            })




      } else {
         payload.setPin("")
         payload.setStage("SENDBUZZ-ERROR")
         payload.setStates({ ...payload.compState, loading: false });
      }
      console.log(res)
   }).catch(error4 => {
      payload.setPin("")
      console.log(error4)
      payload.setStage("SENDBUZZ-ERROR")
      payload.setStates({ ...payload.compState, loading: false });
   })


   // updateBuzzMeWalletService()
}