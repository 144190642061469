import { updateWalletAfterTopupService,saveTopupHistory } from "../services/topupService"
import { formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../Utilities/index"
export const updateWalletAfterTopupController = (payload) => {
   let newBalance = parseInt(payload.user.wallet) + parseInt(payload.amount)
   let newPayload = {
      user: payload.user.buzzID,
      amount: newBalance
   }

   let topupPayload = {
      user: payload.user.buzzID,
      amount: payload.amount,
      meta: {
         ...payload.ref,
         date: {
            day: daysOfTheWeek(new Date),
            month: monthsOfTheYear(),
            year: new Date().getFullYear(),
            date: new Date().getDate(),
            time: formatAMPM(new Date())
         },
      }
   }
   saveTopupHistory(topupPayload).then(res2 => {
      console.log(res2)
   })

   updateWalletAfterTopupService(newPayload).then(res => {
      // payload.setloading(false)
      if (res.error == null && res.body.length > 0) {
         console.log(res)
         payload.toast.success('Topup successful', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
      } else {
         payload.toast.error('An error occured', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
      }
   })
}