import React, { useState } from "react";
import { connect } from "react-redux";
import { userSessionService, } from '../services/auth'
import { transactionHistoryController } from "../controllers/transactions"
import { refreshUserData } from "../controllers/auth"
import { useHistory } from "react-router-dom";
import {
   login,
   disp_session,
   re_auth,
   disp_realtime,
   disp_noti,
   logOut
} from "../redux";
import mp3 from "../statics/audio/Doorbell.mp3";
import { supabase } from '../configurations/index';
import {
   KeyboardBackspace,
   ArrowForwardIosOutlined,
   HelpOutlineOutlined
} from "@material-ui/icons";
import { Drawer } from '@mui/material';



const newSupabase = supabase()

function Login({
   appState,
   login_suc,
   realtime,
   reauth,
   dispNoti
}) {

   var audio = new Audio(mp3);
   const [compState, setStates] = useState({
      loading: true
   })

   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      clearRealtime()
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }
      setDrawerState({ ...drawerState, [anchor]: open });
   };



   const state = appState;
   let history = useHistory();
   let User = userSessionService()


   const dbSub = () => {
      newSupabase
         .from("transactions")
         // .from(`transactions:to=eq.${state.userData.buzzID}`)
         .on("INSERT", (payload) => {
            // return [payload.new];
            // console.log([payload.new])
            // console.log(payload.new)
            let data = payload.new;

            if (data) {
               // console.log(data)
               if (data.type == "BUZ ALERT") {
                  if (data.meta.receiver.buzzID == state.userData.buzzID) {
                     realtime([{ data }])
                     audio.play()
                     setDrawerState({ ...drawerState, bottom: true })
                  }

               }
               else if (data.type == "SCANPAY" || data.type == "Kashback") {
                  // console.log("hello")
                  if (data.meta.sender.buzzID == state.userData.buzzID) {
                     dispNoti(true)
                     audio.play()
                     history.push("/notifications")
                     console.log("hi")

                  }
               }
               else if (data.type == "BUZ REQUEST" && data.from == state.userData.buzzID && data.meta.responded == true) {
                  realtime([{ data }])
                  audio.play()
                  setDrawerState({ ...drawerState, bottom: true })
               }
               else if (data.type == "BUZ REQUEST" && data.isRead.length  == 0) {
                  // const check = data.sendTo.filter(e => e.value == User.user_metadata.buzzID)
                  // console.log(check)
                  if (data.meta.sendTo == state.userData.buzzID) {
                     dispNoti(true)
                     audio.play()
                     history.push("/notifications")
                  }
               }
            }
         })
         .subscribe();
   }


   React.useEffect((compState) => {
      window.scrollTo(0, 0);
      if (User == null) {
         logOut()
         history.push("/onboard")
      } else if (state.userData == null) {
         reauth();
      } else {
         if (state.realtime.length > 0) {
            setDrawerState({ ...drawerState, bottom: true })
         }
         dbSub()
         refreshUserData(User.user.user_metadata.buzzID, login_suc)
         transactionHistoryController(state.userData.buzzID, setStates, compState)
      }
   }, []);

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }

   const clearRealtime = () => {
      let userNewData = {
         ...state.userData,
         wallet: parseInt(state.userData.wallet) + parseInt(state.realtime[0].data.meta.data.amount)
      }
      login_suc(userNewData)
      realtime([])
   }


   return (<>
      <React.Fragment key="bottom">
         <Drawer
            anchor="bottom"
            open={drawerState["bottom"]}
            onClose={toggleDrawer("bottom", false, false)}
         >

            {state.realtime.length > 0 &&

               <div style={{ position: "relative", height: "430px", }}>

                  <div style={{ padding: "10px 20px" }}>
                     <KeyboardBackspace
                        style={{ fontSize: "30px", color: "#0C1825" }}
                        onClick={() => {
                           setDrawerState({ ...drawerState, bottom: false });
                           clearRealtime()
                        }}
                     />
                     <b style={{ fontSize: "18px", color: "#0C1825", float: "right" }}>Notification</b>
                     <div style={{ position: " ", marginTop: "25px", fontSize: "" }} >
                        <b style={{ fontSize: "20px", color: "#0C1825", marginBottom: "20px" }}>Transaction Details</b> <br />

                        <div style={{ marginBottom: "14px", height: "30px", background: " ", padding: "0px ", marginTop: "19px" }}>
                           <small style={{ color: "grey" }}>Transaction type</small>
                           <span style={{ width: " ", float: "right", fontWeight: 600 }} >{state.realtime[0].data.type}</span>
                        </div>
                        <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px " }}>
                           <small style={{ color: "grey" }}>From</small>
                          
                           {state.realtime[0].data.type == "BUZ REQUEST" ? <><span style={{ width: " ", float: "right", fontWeight: 600 }} >{state.realtime[0].data.to}</span></>: <span style={{ width: " ", float: "right", fontWeight: 600 }} >{state.realtime[0].data.meta.sender.fullname}</span>}
                        </div>


                        <div style={{ marginBottom: "14px", height: "30px", background: " ", padding: "0px ", marginTop: "13px" }}>
                           <small style={{ color: "grey" }}>Amount</small>
                           <span style={{ width: " ", float: "right", fontWeight: 600 }} >
                              {state.realtime[0].data.type == "Kashback" && <><p>₦{separator(parseInt(state.realtime[0].data.meta.data.amountPlusCharge) - parseInt(state.realtime[0].data.data.adminCharge))}</p></>}
                              {state.realtime[0].data.type == "BUZ ALERT" && <><p>₦{separator(state.realtime[0].data.meta.data.amount)}</p></>}
                               {state.realtime[0].data.type == "BUZ REQUEST" && <><p>₦{separator(state.realtime[0].data.meta.amount)}</p></>}
                           </span>
                        </div>

                        <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px " }}>
                           <small style={{ color: "grey" }}>Reference</small>
                           <span style={{ width: " ", float: "right", fontWeight: 600 }} >{state.realtime[0].data.meta.data.ref}</span>
                        </div>

                        <br />
                        <div
                           onClick={() => {
                              setDrawerState({ ...drawerState, bottom: false });
                              clearRealtime()
                           }}
                           style={{
                              height: "38px", width: "90%", borderRadius: "8px", position: "relative",
                              background: "#0C1825", padding: "10px 0px", marginBottom: "20px", left: "5%", color: "white"
                           }}>
                           <div style={{ position: "absolute", left: "78px", }}>
                              <b style={{ fontSize: "16px" }}>Confirm</b>
                              <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>
                                 Confirm transaction</span></div>
                           </div>
                           <HelpOutlineOutlined style={{ position: "absolute", left: "25px", top: "20px" }} />
                           <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", top: "20px" }} />

                        </div>

                     </div></div>
               </div>}

         </Drawer>
      </React.Fragment>
   </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      log_out: () => dispatch(logOut()),


   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
