import React, { useState } from "react";
import { connect } from "react-redux";
import { userSessionService, } from '../../services/auth' 
import Logo from "../../statics/assets/logo.png"
import { KeyboardBackspace } from "@material-ui/icons";
import {useHistory } from "react-router-dom";
import { loader } from "../../components/loader"
import { session, clearCounterController } from "../../controllers/checksession"
import { signinController } from "../../controllers/auth"
import {
   login,
   disp_session,
   re_auth
} from "../../redux";
import Realtime from "../../components/realtime"

const inputStyle = {
   height: "55px",
   width: "100%",
   padding: "10px"
}
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   login_suc,
   set_session,
   reauth
}) {



 
 

   let User = userSessionService()

   var QRCode = require("qrcode.react");
 
   const [compState, setStates] = useState("") 
   const state = appState;
   let history = useHistory();
   let userData = state.userData 
   React.useEffect((compState) => { 
   }, []);



   return (
      <>
         <Realtime />
         {state.authenticated === false && history.push("/")}
         {User === null && history.push("/")} 
         {compState.loading == true && loader()}
         {clearCounterController()}
         {session(history,reauth)}
         <form style={formSTyle} noValidate autoComplete="off" >
            <div style={{ marginBottom: "30px", height: "30px", background: " " }}>
               <KeyboardBackspace onClick={() => {
                  history.goBack()
               }} style={{ float: "left", fontSize: "30px" }} />
               <img src={Logo} style={{ width: "80px", float: "right" }} />
            </div>
            <br /> <br /><br /> 
            <div
               style={{
                  padding: "0px",
                  textAlign: "center",
                  background: "white",
                  border: "0.5px solid lightgrey",
                  borderRadius: "5px 5px 0px 0px"
               }}
            >
               <div style={{ fontSIze: "30px", fontWeight: 900, padding: "20px" }}>
                  Scan to pay
               </div>  <br />
               {/* <QRCode value={Data.token.toString()} /> */}
               <QRCode value={userData.data.store.storeID}  />

               <br /><br /><br />
               <div style={{ textAlign: " ", color: "white", background: "#0C1825", padding: "15px", borderRadius: "0px 0px 5px 5px" }}>
                  <b style={{ fontSize: "20px" }}>{userData.data.store.name}</b> <br /> 
                  {<span>{userData.data.store.address}</span>}
               </div>
            </div>
            <br /><br />
         </form>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
