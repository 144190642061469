import { fetchAllFeedsService, insertFeeds, addComments } from "../services/feeds"
import {updateWalletService} from "../services/buzme"

export async function getFeedsController(
  school,
  loadFeeds,
  //   disp_signal,
  setStates,
  compState
) {
  fetchAllFeedsService(school)
    .then((res) => {
      // console.log(res)
      let arry = [];
      if (res.body != null) {
        loadFeeds(res.body);
        //   disp_signal(true);
        setStates({ ...compState, loader: false });
      } else {
        //   disp_signal(false);
        setStates({ ...compState, loader: false });
      }
    })
    .catch((err) => {
      // disp_signal(false);
      setStates({ ...compState, loader: false });
    });
}


export const createGiveawayController = (payload) => {
  // console.log(payload)

  // Subtract the exact amount from the user's wallet . 
  let userID = payload.data.user.buzzid;
  let walletUpdatePayload = {
    user:userID,
    amount:payload.userNewWalletBalance
  }

  // console.log(walletUpdatePayload)

  updateWalletService(walletUpdatePayload).then(res => {
   payload.login_suc(res.body[0])
  }) // user update function

  // payload.setDrawerState({ ...payload.drawerState, bottom: false });

  // @======== CHECK IF USER IS POSTING WITH IMAGE
  if (payload.data.post.image === undefined) {
    // @======== INSERT TO DB
    insertFeeds(payload).then(response => {
      // console.log(response)

      getFeedsController(
        'school',
        payload.loadFeeds,
        //   disp_signal,
        payload.setStates,
        payload.compState
      )
    })
  } else {
    // console.log("OKKK")
    var axios = require("axios");
    var FormData = require("form-data");
    var fs = require("fs");
    var data = new FormData();
    var file = payload.data.post.image;
    data.append("postimage", file);

    var config = {
      method: "post",
      // url: "https://buzz-servre.herokuapp.com/api/v1/make-post/post", 
      url: "https://buzz-server.vercel.app/client/v1/make-post/post",
      // url: "http://localhost:2001/client/v1/make-post/post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response);
        // @======== restructure insertPayload to include the image key gotten from Supabase
        const newDataToUpload = {
          ...payload,
          data: {
            ...payload.data, post: {
              ...payload.data.post,
              image: response.data
            }
          },
        };

        insertFeeds(newDataToUpload).then(response => {

          getFeedsController(
            'school',
            payload.loadFeeds,
            // disp_signal,
            payload.setStates,
            payload.compState
          )

        })
      })
      .catch(function (error) {
        payload.setStates({
          ...payload.compState,
          loading: false
        })
      });
  }


}

export const createCommentController = (payload) => {
  // console.log(payload)

  let statePayload = {
    post: payload.post.id,
    comment: payload.comment,
    user: payload.user,
    benefited: false,
    data: payload.post
  }
  payload.allGiveAwayPosts.comments.push(statePayload)

  payload.dispFeedView(payload.allGiveAwayPosts)
  addComments(payload).then(res => {
    // console.log(res)
  })
    .catch(error => {
      // console.log(error)
    })
}