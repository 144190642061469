import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux"; 
import { AuthenticateScreen } from "../../components/auth"
import {
   
   KeyboardBackspace,
   HelpOutlineOutlined,
   ArrowForwardIosOutlined,
} from "@material-ui/icons";
import Naira from "react-naira";
import Logo from "../../statics/assets/logo.png"
import {Drawer, Divider } from "@mui/material"; 
import { formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"
import { loader } from "../../components/loader"
// import buzz me request controllers 
import { userSessionService, } from '../../services/auth' 
import { session, clearCounterController } from "../../controllers/checksession"
import { initiateBuzzmeController } from "../../controllers/buzzme/send"
import { updateBuzzRequest } from "../../controllers/transactions"
import {
   login, 
   re_auth,
   disp_realtime,
   disp_noti,
   transactionsToView
} from "../../redux";
import Realtime from "../../components/realtime" 
const rec_inputs = {
   // width: "97%",
   padding: "8px",
   height: "70px",
   border: "0.5px solid lightgrey",
   color: "grey",
   outline: "none",
   fontSize: "13px",
   resize: "none",
   borderRadius: "5px",
   margin: "25px"
};

function Home({
   appState,
   login_suc, 
   reauth, 
   dispTransactionToView
}) {

   const state = appState;
   const history = useHistory();
   const [compState, setStates] = useState(""); 
   let User = userSessionService().user.user_metadata
   const Data = state.viewTransaction
   const [pin, setPin] = useState("");
   const [pinError, setpinError] = useState("");


   React.useEffect(() => { 

   }, []);

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }
 

   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      // console.log("okk");
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setDrawerState({ ...drawerState, [anchor]: open });
   };

 

   const [stage, setStage] = useState(null)

   // respond to uzz request
   const initiateTransaction = () => {
      let amount = null
      if (Data.type == "BUZ REQUEST") {
         amount = Data.meta.amount
      }
      let payload = {
         setStates, compState,
         amount,
         beneficiaryData: {
            name: Data.meta.from.name,
            buzzID: Data.from
         },
         User,
         desc: "",
         setStage,
         setDrawerState,
         drawerState,
         date: {
            day: daysOfTheWeek(new Date),
            month: monthsOfTheYear(),
            year: new Date().getFullYear(),
            date: new Date().getDate(),
            time: formatAMPM(new Date())
         },
         setPin,
         login_suc, 
      }
      // setDrawerState({ ...drawerState, bottom: false });
      console.log(payload)
      initiateBuzzmeController(payload)

      // deactivating the response button dispTransactionToView
      let newdispTransactionToView = {
         ...state.viewTransaction,
         meta: {
            ...state.viewTransaction.meta,
            responded: true
         }
      }

      dispTransactionToView(newdispTransactionToView)


      // create a new meta to update the transaction
      let newMeta = {
         ...state.viewTransaction.meta,
         responded: true
      }
      updateBuzzRequest(Data.id, newMeta)
   }

   return (
      <div id="body bg"> 
          <Realtime /> 
         {clearCounterController()}
         {session(history, reauth)}
         <>
            {console.log(Data)}
            {compState.loading == true && loader()}
            <div
               style={{
                  // position: "fixed",
                  // width: "100%",
                  // height: "100%",
                  top: "30px",
                  // left: "0px",
                  // overflowX:"auto",
                  // zIndex: "10000",
                  background: "",
               }}
            >
               <div style={{ marginBottom: "60px", height: "30px", background: " ", padding: "0px 15px" }}>
                  <KeyboardBackspace onClick={() => {
                     history.goBack()
                  }} style={{ float: "left", fontSize: "30px" }} />
                  <img src={Logo} style={{ width: "80px", float: "right" }} />

               </div>


               <div
                  style={{
                     padding: "15px",
                     // background: "lightgray",
                     color: "",
                     position: "absolute",
                     top: "8%",
                     width: "100%",
                     textAlign: "center",
                     left: "0px"
                  }}
               >
                  <div>
                     <small style={{ color: "grey" }}>Amount</small>
                  </div>
                  <b style={{ opacity: "1", color: "#0a3d62", fontSize: "35px" }}>
                     ₦{Data.type == "BUZ REQUEST" ? separator(Data.meta.amount) : separator(Data.meta.data.amount)}
                  </b>  <br />
                  {Data.type == "BUZ REQUEST" ? <>
                     {Data.meta.responded == false ?
                        <small style={{ color: "crimson" }}>Pending</small> :
                        <small style={{ color: "mediumseagreen" }}>Responded</small>}
                  </> : <small style={{ color: "mediumseagreen" }}>Transaction successful</small>}

               </div>
               <div style={{ position: "absolute", top: "28%", width: "100%" }}>
                  <Divider /> <br /><br /><br />

                  <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                     <small style={{ color: "grey" }}>Payment method</small>
                     <span style={{ width: " ", float: "right", fontWeight: 600 }} >{Data.type}</span>
                  </div>
                  <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                     <small style={{ color: "grey" }}>
                        {Data.from == User.buzzID ? <>
                           To
                        </> : <>
                           From
                        </>}
                     </small>
                     <span style={{ width: "", float: "right", fontWeight: 600 }}>
                        {Data.from == User.buzzID ? <>
                           {Data.type == "BUZ REQUEST" ? Data.from : Data.meta.receiver.fullname}
                        </> : <>
                           {Data.type == "BUZ REQUEST" ? Data.to : Data.meta.sender.fullname}
                        </>}
                     </span>
                  </div>
                  <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                     <small style={{ color: "grey" }}>Date</small>
                     <span style={{ width: " ", float: "right", fontWeight: 600 }} >{Data.meta.date.month} {Data.meta.date.date}.  {Data.meta.date.year}</span>
                  </div>

                  <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                     <small style={{ color: "grey" }}>Time</small>
                     <span style={{ width: " ", float: "right", fontWeight: 600 }} >{Data.meta.date.time}</span>
                  </div>

                  {Data.type != "BUZ REQUEST" && <>
                     <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                        <small style={{ color: "grey" }}>Charge</small>
                        <span style={{ width: " ", float: "right", fontWeight: 600 }} >₦{Data.type == "SCANPAY" ? Data.meta.data.charge - Data.meta.data.adminCharge : "0.00"}</span>
                     </div>

                  </>}




                  {Data.type != "BUZ REQUEST" && <> <div style={{ marginBottom: "20px", height: "30px", background: " ", padding: "0px 15px" }}>
                     <small style={{ color: "grey" }}>Transaction ID</small>
                     <span style={{ width: " ", float: "right", fontWeight: 600 }} >
                        {Data.meta.data.ref}
                     </span>  </div>
                  </>}


                  <br />

                  {Data.type == "BUZ REQUEST" ? <>
                     {Data.to == User.buzzID ? <>
                        {Data.meta.responded == true ?
                           <>

                              <div
                                 style={{
                                    height: "38px", width: "90%", borderRadius: "8px", position: "relative",
                                    background: "#0C1825", padding: "10px 0px", marginBottom: "20px", left: "5%", color: "white", opacity: "0.4"
                                 }}>
                                 <div style={{ position: "absolute", left: "78px", }}>
                                    <b style={{ fontSize: "16px" }}>Responded to Request</b>
                                    <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>
                                       Request has been responded to</span></div>
                                 </div>
                                 <HelpOutlineOutlined style={{ position: "absolute", left: "25px", top: "20px" }} />
                                 <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", top: "20px" }} />

                              </div>

                           </>
                           : <>

                              <div
                                 onClick={() => {
                                    setDrawerState({ ...drawerState, bottom: true });
                                 }}
                                 style={{
                                    height: "38px", width: "90%", borderRadius: "8px", position: "relative",
                                    background: "#0C1825", padding: "10px 0px", marginBottom: "20px", left: "5%", color: "white"
                                 }}>
                                 <div style={{ position: "absolute", left: "78px", }}>
                                    <b style={{ fontSize: "16px" }}>Respond to request</b>
                                    <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>
                                       Respond to this request</span></div>
                                 </div>
                                 <HelpOutlineOutlined style={{ position: "absolute", left: "25px", top: "20px" }} />
                                 <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", top: "20px" }} />

                              </div>

                           </>}
                     </> : <>
                        {Data.meta.responded == true ?
                           <>
                              <>
                                 <div
                                    style={{
                                       height: "38px", width: "90%", borderRadius: "8px", position: "relative",
                                       background: "#0C1825", padding: "10px 0px", marginBottom: "20px", left: "5%", color: "white", opacity: "0.4"
                                    }}>
                                    <div style={{ position: "absolute", left: "78px", }}>
                                       <b style={{ fontSize: "16px" }}>Responded to Request</b>
                                       <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>
                                          Request has been responded to</span></div>
                                    </div>
                                    <HelpOutlineOutlined style={{ position: "absolute", left: "25px", top: "20px" }} />
                                    <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", top: "20px" }} />

                                 </div>

                              </>

                           </>
                           : <>
                              <div
                                 style={{
                                    height: "38px", width: "90%", borderRadius: "8px", position: " ",
                                    color: "#0C1825", padding: "10px 0px", marginBottom: "20px", textAlign: "center",
                                 }}>
                                 <b> Pending Request
                                 </b>
                              </div>

                           </>}
                     </>}
                  </> : <>
                     <div
                        onClick={() => {
                           setDrawerState({ ...drawerState, bottom: true });
                        }}
                        style={{
                           height: "38px", width: "90%", borderRadius: "8px", position: "relative",
                           background: "#0C1825", padding: "10px 0px", marginBottom: "20px", left: "5%", color: "white"
                        }}>
                        <div style={{ position: "absolute", left: "78px", }}>
                           <b style={{ fontSize: "16px" }}>Report transaction</b>
                           <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>
                              Report issues with this transaction</span></div>
                        </div>
                        <HelpOutlineOutlined style={{ position: "absolute", left: "25px", top: "20px" }} />
                        <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", top: "20px" }} />

                     </div></>}



               </div>
            </div>

            <React.Fragment key="bottom">
               <Drawer
                  anchor="bottom"
                  open={drawerState["bottom"]}
                  onClose={toggleDrawer("bottom", false, false)}
               >


                  {Data.type == "BUZ REQUEST" ? <>
                     <div
                        style={{
                           fontSize: " ",
                           fontWeight: " ",
                           textAlign: "left",
                           width: " ",
                           marginLeft: "",
                           // background: "#efedc4",
                           padding: " 10px",
                        }}
                     >
                        <div
                           style={{
                              boxShadow: " 1px 1px 3px #888888",
                              padding: "5px 10px",
                              marginBottom: "30px"
                           }}
                        >
                           <spall style={{ fontSize: "11px" }}>
                              You want to Buzz
                           </spall>
                           <div style={{ fontWeight: "bold" }}>
                              ₦{Data.type == "BUZ REQUEST" ? separator(Data.meta.amount) : separator(Data.meta.data.amount)}
                           </div>
                           <spall style={{ fontSize: "11px" }}>
                              to
                           </spall>
                           <div style={{ marginTop: "10px" }}>
                              <b> {`${Data.meta.from.name}`}</b>
                              <div style={{ marginBottom: "6px", marginTop: "-2px", fontSize: "11px", color: "grey" }}> <small>{`${Data.from}`}</small> </div>
                           </div>

                        </div>

                        <AuthenticateScreen setDrawerState={setDrawerState} drawerState={drawerState} pin={pin} setPin={setPin} pinError={pinError} setpinError={setpinError} User={User} functions={initiateTransaction} />
                     </div>

                  </> :
                     <>
                        <div style={{ color: "grey", marginTop: "15px", textAlign: "center" }}>
                           <b >
                              Write to us, we will treat as urgent.
                           </b>
                        </div>
                        <textarea
                           cols="40"
                           rows="20"
                           onChange={(e) => {
                              // setReason(e.target.value);
                           }}
                           // value={reason}
                           style={rec_inputs}
                           placeholder="Enter your request reason *"
                        ></textarea>

                        <div style={{ textAlign: "center" }}>
                           <button
                              onClick={() => {
                                 // sendToDefault()
                                 setDrawerState({ ...drawerState, bottom: false });
                              }}
                              type="button"
                              value="SEND"
                              style={{
                                 padding: "10px",
                                 outline: "none",
                                 width: "150px",
                                 background: "#0C1825",
                                 border: "0.5px solid crimson",
                                 color: "white",
                                 borderRadius: "6px",
                                 //   margin: "10px 19px",
                                 fontWeight: "bold",
                                 margin: "10px",
                              }}
                           >SEND</button>
                           <br /><br /><br />
                        </div>
                     </>}



               </Drawer>
            </React.Fragment>
         </>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      reauth: () => dispatch(re_auth()),
      dispTransactionToView: (payload) => dispatch(transactionsToView(payload)),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
