import { updateuserService } from "../services/auth"

export async function updateUserMetaController(data, setloading,history, type) {
  
   updateuserService(data).then(res => {
      setloading(false)
      if(type == "VERIFY-ACCOUNT"){
         history.push('/setpin')
      }else{
         history.push('/')
      }
      
      
   })
      .catch(error => {
         console.log("false")
         return false
      })
}