import React, { useState } from "react";
import { connect } from "react-redux";

import {
   HomeOutlined,
   SettingsOutlined,
   BarChartOutlined,
   ArrowForwardIosOutlined,
   Person,
   HelpOutlineOutlined,
   KeyboardBackspace,
   CreditCardOutlined,
   LockOutlined,
   VerifiedUserOutlined,
   ExitToAppOutlined
} from "@material-ui/icons";
import userAvater from "../../statics/assets/user.png"
import { session, clearCounterController } from "../../controllers/checksession"
import { Divider } from '@mui/material';
import { userSessionService, } from '../../services/auth'
import QrCode2Outlined from '@mui/icons-material/QrCode2Outlined';

import Realtime from "../../components/realtime"
import { useHistory } from "react-router-dom";
import { loader } from "../../components/loader"
import {
   re_auth,
   disp_session,
   logOut,
   login,
   disp_realtime
} from "../../redux";



const formSTyle = {
   background: "white",
   // height: "1000px"
}
function Login({
   log_out,
   appState,
   set_session,
   reauth,
   login_suc,
   realtime
}) {
   const [compState, setStates] = useState("")
   const state = appState;
   let history = useHistory();
   let User = userSessionService()
   let userData = state.userData
   React.useEffect((compState) => {
      window.scrollTo(0, 0);

      // clearCounterController()
      // session(history)
   }, []);

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }




   return (
      <>
         <Realtime />
         {User == null && history.push("/onboard")}
         {compState.loading == true && loader()}
         {state.authenticated === false && history.push("/")}
         {clearCounterController()}
         {session(history, reauth)}
         {console.log(state)}
         <div style={formSTyle} >
            <div style={{
               marginBottom: "8px",
               // background: "white",
               padding: "5px 10px",
               position: "relative",
               // width: "100%"
               // border: "1px solid red"
               top: "10px"
            }}>

               <div style={{ marginBottom: "1px", height: "30px", background: "  " }}>
                  <KeyboardBackspace onClick={() => {
                     history.goBack()
                  }} style={{ float: "left", fontSize: "30px" }} />
                  <div style={{ width: " ", float: "right", color: "grey" }} onClick={() => {
                  }}  >

                     <b> <small>Coin</small> {User !== null && state.userData.coin_wallet}</b>
                  </div>
               </div>
            </div>



            {/* <div style={{
               marginBottom: "10px",
               background: "grey",
               height: "1px"
            }}>

            </div> */}

            <Divider />






            {/* =========================== */}


            <div style={{ padding: "10px", background: "  ", textAlign: "left", width: "", marginTop: "30px", color: "white" }}>

               {User.user.user_metadata.address == null && <>
                  <center onClick={() => {
                     history.push("/account-details")
                  }} style={{ marginTop: "-21px", marginBottom: "20px", padding: "40px 10px", border: "1px solid #dd8a9a", borderRadius: "8px" }}><small style={{ color: "#dd8a9a" }}>Update your Gender and Residential address</small></center>
               </>}


               {userData.data.isVendor == true && <><div
                  onClick={() => {
                     history.push("/store")
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Your Store Front</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>{userData.data.store.name}</span></div>
                  </div>
                  <QrCode2Outlined style={{ position: "absolute", left: "25px", fontSize: "35px", color: "gold" }} />

                  {userData.data.isVerified == true ? <>
                     <VerifiedUserOutlined style={{ position: "absolute", right: "15px", color: "gold" }} />
                  </> : <>
                     <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />
                  </>}


               </div> </>}





               <div
                  onClick={() => {
                     history.push("/account-details")
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " ",

                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Your Profile</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>{User !== null && User.user.user_metadata.name}</span></div>
                  </div>
                  <Person style={{ position: "absolute", left: "25px", fontSize: "30px" }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>






               <div
                  onClick={() => {
                     history.push("/beneficiaries")
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Saved beneficiaries</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>Manage beneficiaries</span></div>
                  </div>
                  <CreditCardOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>




               <div
                  onClick={() => {
                     history.push('/get-help')
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Get Help</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>Get support or send feedback</span></div>
                  </div>
                  <HelpOutlineOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>



               <div
                  onClick={() => {
                     history.push("/security")
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Security</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>Protect yourself from intruders</span></div>
                  </div>
                  <LockOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>


               <div
                  onClick={() => {
                     log_out()
                  }}
                  style={{
                     height: "30px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#dd8a9a", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Sign Out</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>End your App session</span></div>
                  </div>
                  <ExitToAppOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>

               <div 
                  style={{
                     height: "55px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "rgb(244 244 244)", padding: "4px 0px", marginBottom: "4px", textAlign: " "
                  }}>

                  <div style={{ padding: "5px 10px", right: '', top: "10px", fontSize: "16px", lineHeight: "140%", display: "inline-block",color:"black" }}>
                     <small >
                        BuzPay ID
                     </small>

                     <div style={{ fontSize: "12px", fontWeight: "700px", }}>
                        <b>{User !== null && User.user.user_metadata.buzzID}</b>
                     </div>
                  </div>

                  <div style={{ padding: "5px 10px", float: 'right', top: "10px", fontSize: "16px", lineHeight: "140%", display: "inline-block",color:"black" }}>
                     <small >
                        Referrer ID
                     </small>

                     <div style={{ fontSize: "12px", fontWeight: "700px", }}>
                        <b>{User !== null && User.user.user_metadata.refId}</b>
                     </div>
                  </div>
               </div>






            </div>
            <br /><br /> <br /><br />

            <div style={{ height: "64px", width: "100%", position: "fixed", background: "rgb(244 244 244)", bottom: "0px", left: "0px", borderTop: "1px solid rgba(12, 24, 37, 0.18)", textAlign: "center" }}>
               <center onClick={() => {
                  history.push("/dashboard")
               }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", }}>
                  <HomeOutlined style={{ float: "" }} />
                  <div><small>Home</small></div>
               </center>
               <center onClick={() => {
                  history.push("/transaction")
               }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px" }}>
                  <BarChartOutlined style={{ float: "" }} />
                  <div><small>Transactions</small></div>
               </center>
               <center onClick={() => {
                  history.push("/account")
               }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", color: "grey" }}>
                  <SettingsOutlined style={{ float: "" }} />
                  <div><small>Account</small></div>
               </center>
            </div>

         </div>


      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      log_out: () => dispatch(logOut()),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      realtime: (data) => dispatch(disp_realtime(data)),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
