import React, { useState } from "react";
import { connect } from "react-redux";
import { userSessionService, } from '../../services/auth'
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput'; 
import Logo from "../../statics/assets/logo.png"
import { KeyboardBackspace } from "@material-ui/icons";
import { Redirect, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { session, clearCounterController } from "../../controllers/checksession"
import { updateuserService } from "../../services/auth"
import {
   login,
   disp_session,
   re_auth
} from "../../redux";


const inputStyle = {
   height: "55px",
   width: "100%",
   padding: "10px"
}
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   login_suc,
   set_session,
   reauth
}) {



 
   let User = userSessionService()

   const [gender, setGender] = useState("");
   const [address, setAddress] = useState("");
   const [compState, setStates] = useState("")
   const [ok, setok] = useState(0)
   const state = appState;
   let history = useHistory(); 
   let Update = () => {
      setStates({
         ...compState,
         loading: true
      })
      let payload = {
         gender: gender,
         address: address,
      }
      updateuserService(payload).then(res => {

         if (res.data != null) {
            console.log(res)
            setStates({
               ...compState,
               loading: false
            })
            setAddress("")
            setGender("")

            toast.success('Updated successfully', {
               position: "top-right",
               autoClose: 3000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
            });
            setStates({
               ...compState,
               loading: false
            })
         } else {
            toast.error('An error occured', {
               position: "top-right",
               autoClose: 1000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
            });
         }


      }).catch(error => {
         toast.error('An error occured', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
      })
   }
   React.useEffect((compState) => {
      window.scrollTo(0, 0); 
   }, []);



   return (
      <>
         {state.authenticated === false && history.push("/")}
         {User === null && history.push("/")} 
         {/* {compState.loading == true && loader()} */}
         {clearCounterController()}
         {session(history,reauth)}
         <form style={formSTyle} noValidate autoComplete="off" >
            <div style={{ marginBottom: "30px", height: "30px", background: " " }}>
               <KeyboardBackspace onClick={() => {
                  history.goBack()
               }} style={{ float: "left", fontSize: "30px" }} />
               <img src={Logo} style={{ width: "80px", float: "right" }} />
            </div>


            <b style={{
               position: 'static',
               left: '0%',
               right: ' 42.5%',
               top: '0%',
               bottom: ' 62.03%',
               fontStyle: 'normal',
               fontWeight: ' 700',
               fontSize: ' 17px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
            }}>
               Account Details
            </b> <br />
            <small>
               Update your account details
            </small>
            <br />

            <ToastContainer
               position="top-right"
               autoClose={5000}
               hideProgressBar={false}
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               pauseOnHover
            />


            <FormControl style={{ width: "100%", marginTop: "30px" }}>
               <InputLabel htmlFor="component-outlined">Full Name</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  id="component-outlined"
                  value={User !== null && User.user.user_metadata.name}
                  disabled
                  label="Email Adress"
               />
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "20px" }}>
               <InputLabel htmlFor="component-outlined">Email Adress</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  id="component-outlined"

                  value={User !== null && `${User.user.email}`}
                  disabled
                  label="Email Adress"
               />
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "20px" }}>
               <InputLabel htmlFor="component-outlined">Phone Number</InputLabel>
               <OutlinedInput
                  style={inputStyle}
                  id="component-outlined"
                  value={User !== null && `+234${User.user.user_metadata.buzzID}`}
                  disabled
                  label="Email Adress"
               />
            </FormControl>


            {User.user.user_metadata.address == null ?
               <FormControl style={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel htmlFor="component-outlined">Home Adress</InputLabel>
                  <OutlinedInput
                     style={{
                        height: "55px",
                        width: "100%",
                        padding: "10px",
                        border: "1px solid crimson"
                     }}
                     id="component-outlined"
                     onChange={(e) => {
                        setAddress(e.target.value)
                     }}
                     value={address}
                     required
                     label="Home Adress"
                  />
               </FormControl> : <FormControl style={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel htmlFor="component-outlined">Home Adress</InputLabel>
                  <OutlinedInput
                     style={inputStyle}
                     id="component-outlined"

                     value={User.user.user_metadata.address}
                     disabled
                     label="Home Adress"
                  />
               </FormControl>}


            {User.user.user_metadata.gender == null ?
               <FormControl fullWidth style={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Your gender</InputLabel>
                  <Select
                     style={{
                        height: "55px",
                        width: "100%",
                        padding: "10px",
                        border: "1px solid crimson"
                     }}
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     // value={age}
                     label="Your gender"
                     onChange={(e) => {
                        setGender(e.target.value)
                     }}
                  >
                     <MenuItem value={`Male`}>Male</MenuItem>
                     <MenuItem value={`Female`}>Female</MenuItem>
                  </Select>
               </FormControl>
               :
               <FormControl style={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel htmlFor="component-outlined">Your gender</InputLabel>
                  <OutlinedInput
                     style={inputStyle}
                     id="component-outlined"
                     value={User !== null && `${User.user.user_metadata.gender}`}
                     disabled
                     label="Email Adress"
                  />
               </FormControl>}



            <br /><br />
            {compState.error == true && <><div style={{ color: "crimson", textAlign: "center", marginTop: "" }}>{compState.msg}</div></>}
            <br /><br />

            {address.length < 1 || gender.length < 1 ?
               <button
                  type="button"
                  style={{
                     background: "#0C1825",
                     color: "white",
                     border: "none",
                     outline: "none",
                     padding: "7px 50px",
                     marginTop: "5px",
                     borderRadius: "6px",
                     width: "100%",
                     height: "42px",
                     opacity: "0.4"
                  }}
               >
                  Update Profile{" "}
               </button>
               :
               <button
                  type="button"
                  style={{
                     background: "#0C1825",
                     color: "white",
                     border: "none",
                     outline: "none",
                     padding: "7px 50px",
                     marginTop: "5px",
                     borderRadius: "6px",
                     width: "100%",
                     height: "42px",
                     opacity: compState.loading == true ? "0.4" : "1"
                  }}
                  onClick={(e) => {
                     Update();
                  }}
               >
                  {" "}
                  {compState.loading == true ? "Updating..." : " Update Profile"}
               </button>}

         </form>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
