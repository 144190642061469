import { updateChatService, saveChatService, checkChatService } from "../../services/chatService"
import { formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"


export const chatController = (payload, setAllMsg, AllMsg,setloading) => {
   checkChatService(payload.User.buzzID).then(res => {
      // console.log(res)
      if (res.error == null) {
         if (res.data.length < 1) {
            saveChatService(payload).then(res1 => {
               if (res.error == null) {
                  if (res.body.length > 0) {
                     setAllMsg({
                        ...AllMsg,
                        allMessages: res.body[0].content
                     })
                     console.log(res.body[0].content)
                     // setloading(false)
                  } else {
                     setAllMsg({
                        ...AllMsg,
                        allMessages: []
                     })
                     // setloading(false)
                     // console.log("nothing")
                  }
               }
               fetchMessage(payload.User.buzzID, setAllMsg, AllMsg, setloading)
            })
         } else {
            let msgArry = res.body[0].content

            let newMsg = {
               msg: payload.msg,
               date: {
                  day: daysOfTheWeek(new Date),
                  month: monthsOfTheYear(),
                  year: new Date().getFullYear(),
                  date: new Date().getDate(),
                  time: formatAMPM(new Date())
               },
               user: true
            }

            msgArry.push(newMsg)
            updateChatService(msgArry, payload.User.buzzID).then(res2 => {
               if (res.error == null) {
                  setAllMsg({
                     ...AllMsg,
                     allMessages: res.body[0].content
                  })
               }
            })

         }
      }



   })

   // push to state
   AllMsg.allMessages.push(
      {
         msg: payload.msg,
         date: {
            day: daysOfTheWeek(new Date),
            month: monthsOfTheYear(),
            year: new Date().getFullYear(),
            date: new Date().getDate(),
            time: formatAMPM(new Date())
         },
         user: true
      }
   )

   setAllMsg({
      ...AllMsg,
      allMessages: AllMsg.allMessages
   })


}


export const fetchMessage = (user, setAllMsg, AllMsg, setloading) => {
   // console.log(user)
   checkChatService(user).then(res => {
      // console.log(res)
      if (res.error == null) {
         if (res.body.length > 0) {
            setAllMsg({
               ...AllMsg,
               allMessages: res.body[0].content
            })
            console.log(res.body[0].content)
            setloading(false)
         } else {
            setAllMsg({
               ...AllMsg,
               allMessages: []
            })
            setloading(false)
            // console.log("nothing")
         }
      } else {
         setAllMsg({
            ...AllMsg,
            allMessages: []
         })
         setloading(false)
         console.log("Fetch error")
      }
   })
}