import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"; 
import { chatController, fetchMessage } from "../../controllers/chat/chatController" 
import chatpadiLogo from "../../statics/assets/chatpadi.png"
import CircularProgress from '@mui/material/CircularProgress';
import { userSessionService, } from '../../services/auth'

import {
   login,
   disp_session,
   re_auth,
   logOut
} from "../../redux";
import { TextareaAutosize } from "@mui/material";
import {
   Send,
   AddPhotoAlternateOutlined
} from "@material-ui/icons";
import {
   KeyboardBackspace,
} from "@material-ui/icons";

import io from "socket.io-client";

const socket = io.connect("https://buzpaysucket.harvoxx.com/");


function Login({
   appState, 
   log_out,
   reauth
}) {
 
   const state = appState;
   let history = useHistory();
   let User = state.userData
   let sessionUser =  userSessionService().user.user_metadata
   const [comment, setComment] = useState("");
   const [AllMsg, setAllMsg] = useState({
      allMessages: []
   })
   const [loading, setloading] = useState(true)
   React.useEffect((compState) => {
      window.scrollTo(0, 0);

      if (sessionUser == null) {
         log_out()
         history.push("/login")
      } else if (state.userData == null) {
         reauth()
      } else {
         if (AllMsg.allMessages.length < 1) {
            fetchMessage(User.buzzID, setAllMsg, AllMsg, setloading)
         }

      }
      // clearCounterController()
      // session(history)

      socket.on("receive_chat", (data) => {
         console.log(data)
      });

   }, []);

   const sendChat = async (payload) => {
      await socket.emit("send_chat", payload);
   };


   const handleComment = () => {
      // document.getElementById("view").scrollIntoView();
      const payload = {
         msg: comment,
         User
      }
      chatController(payload, setAllMsg, AllMsg,setloading)
      sendChat(payload)
     

   }


   const ShoeMsg = () => {
      return AllMsg.allMessages.map((e, index) => {
         if (AllMsg.allMessages.length > 0) {
            return (
               <>
                  {e.user == true ? <div className="right-box">
                     <div style={{ marginBottom: "5px" }}>
                        <div className="text-container" style={{ padding: "10px", paddingBottom: "3px", color: "lightgrey" }}>
                           {e.msg}
                           <br />
                           <div style={{ marginTop: "5px", textAlign: "right" }}>
                              <small style={{ float: "", fontSize: "11px", color: "lightgrey", marginRight: "2px" }}>
                                 {e.date.time}
                              </small>
                           </div>
                        </div>

                     </div>
                  </div> : <div className="left-box">
                     <div className="image-container">
                        {/* <img src={Logo} alt="" style={{ width: "20px" }} /> */}
                     </div>
                     <div className="text-container">
                        {e.msg}
                        <br />
                        <div style={{ marginTop: "5px", textAlign: "right" }}>
                           <small style={{ float: "", fontSize: "11px", color: "grey", marginRight: "2px" }}>
                              {e.date.time}
                           </small>
                        </div>
                     </div>
                  </div>}




               </>
            )
         }
      })
   }


   return (
      <>
         {/* {compState.loading == true && loader()} */}
         {state.loggedIn === false && history.push("/")} 

         <section className="messages">
            <div className="messages-container">
               <div style={{ height: "50px", }}> </div>
               <div style={{ height: "", background: "#0C1825", position: "fixed", left: "0px", top: "0px", width: "100%", padding: "10px 15px", }}>
                  <KeyboardBackspace onClick={() => {
                     history.goBack()
                  }} style={{ float: " ", fontSize: "30px", color: "white", marginTop: " " }} />
                  <img src={chatpadiLogo} alt=""
                     style={{
                        width: "20px",
                        color: "white",
                        position: "absolute",
                        right: "15px", top: "15px",
                        fontSize: "13px",
                        background: " ",
                        // height: "100%",
                        top: "3px",
                        right: "110px",
                        padding: "10px",
                     }} />
                  <div style={{
                     width: "50%",
                     color: "white",
                     position: "absolute",
                     right: "15px", top: "15px",
                     fontSize: "13px",
                     background: " ",
                     height: "100%",
                     top: "0px",
                     right: "30px",
                     padding: "10px",
                     textAlign: "right"
                  }}>

                     <span style={{ color: "white", fontSize: " 18px", }}> ChatPadi</span>
                  </div>

                  {/* <small>
                     We are at your service
                  </small> */}
               </div>

               <div className="messages-section">

                  {loading == true ?
                     <center style={{ marginTop: "150px", position: "relative" }}>
                        <CircularProgress style={{ width: "20px", height: "20px" }} />
                     </center> : <>
                        {AllMsg.allMessages.length > 0 ?
                           <ShoeMsg /> :
                           <center style={{ marginTop: "50%" }}>
                              Start a conversation.
                           </center>}

                     </>}
               </div>
               <div
                  style={{
                     position: " fixed",
                     // height: "90px",
                     background: "#f0f0f0",
                     borderRadius: "27px",
                     padding: "3px",
                     width: "100%",
                     bottom: "0px"
                  }}
               >
                  <AddPhotoAlternateOutlined
                     style={{ position: "absolute", left: "15px", bottom: "15px" }}
                  />
                  <TextareaAutosize
                     autoFocus
                     id="commentInput"
                     onChange={(e) => {
                        setComment(e.target.value);
                     }}
                     value={comment}
                     aria-label="minimum height"
                     minRows={1}
                     maxRows={4}
                     placeholder="Write a comment...."
                     style={{
                        width: "70%",
                        outline: "none",
                        borderRadius: "27px",
                        border: "none",
                        background: "#f0f0f0",
                        padding: "10px",
                        paddingRight: " ",
                        resize: "none",
                        marginLeft: "45px"
                     }}
                  />

                  {comment.length > 0 &&
                     <Send
                        onClick={() => {
                           // setDrawerState({ ...drawerState, ["bottom"]: false });
                           handleComment();
                           setComment('')

                        }}
                        style={{ position: "absolute", right: "15px", bottom: "15px" }}
                     />}


               </div>
            </div>
         </section>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
      log_out: () => dispatch(logOut()),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);