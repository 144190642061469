var axios = require("axios");
// const url = "http://localhost:2001/api/v1";

const url = "https://buzz-server.vercel.app/api/v1"

// // generate otp
// const generateOTP = (min, max) => {
//   let randomNum = Math.random() * (max - min) + min;
//   return Math.floor(randomNum);
// };


// send authentication OTP to  email address
export async function sendEmailOtp(email, name, otp, phone) {
  var axios = require("axios");
  var data = JSON.stringify({
    email: email,
    name: name,
    OTP: otp,
    phone: phone
  });

  var config = {
    method: "post",
    url: `${url}/auth/sms`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}

// @======== Send PHONE OTP
export async function send_otp(payload) {
  var data = JSON.stringify({
    phone: payload.phone,
    email: payload.email,
    name: payload.name,
    OTP: payload.otp
  });
  console.log(data);

  var config = {
    method: "post",
    url: `${url}/auth/sms`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return {
        error: false,
        data: response.data,
      };
    })
    .catch(function (err) {
      return {
        error: true,
        data: null,
      };
    });
}

// @========  BUZZ ALERT
export function send_buzz_alert(payload) {
  var data = JSON.stringify({
    phone: payload.phone,
    sender_balance: payload.sender_balance,
    amount: payload.amount,
    desc: payload.desc,
    receiver_balance: payload.receiver_balance,
    receiver_email: payload.receiver_email,
    sender_email: payload.sender_email,
    means: payload.means,
    sender: payload.sender,
    receiver: payload.receiver,
    type: payload.type,
    date: new Date()
  });

  var config = {
    method: "post",
    url: `${url}/notifications/buz-alert`,
    // url: `${url}/auth/sms`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}

// !======== GENERATE CASH BACK
export function generate_cashback(payload) {
  var data = JSON.stringify({
    email: payload.email,
    token: payload.token,
    amount: payload.amount,
    user: payload.user
  });

  var config = {
    method: "post",
    url: `${url}/notifications/create-cashtoken`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}

// !======== RESOLVE CASHBACK EMAIL NOTIFICATIONS
export function resolve_cashback(payload) {
  var data = JSON.stringify({
    tokenOwnerName: payload.tokenOwnerName,
    tokenOwnerEmail: payload.tokenOwnerEmail,
    tokenOwnerPhone: payload.tokenOwnerPhone,

    recieverName: payload.recieverName,
    receiverPhone: payload.receiverPhone,
    receiverEmail: payload.receiverEmail,

    tokenAmount: payload.tokenAmount,
    token: payload.token,
    charge: payload.charge,
    date: new Date()
  });

  var config = {
    method: "post",
    url: `${url}/notifications/resolve-cashtoken`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}

// @========  BENEFITED FROM GIVEAWAY
export function benefit_from_giveaway(payload) {
  var data = JSON.stringify({
    phone: payload.phone,
    name: payload.name,
    amount: payload.amount,
    bal: payload.bal,
  });

  var config = {
    method: "post",
    url: `${url}send-giveaway-benefited-alert`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}

// @======== BUZZ REQUEST ALERT
export function buzz_request(payload) {
  var data = JSON.stringify({
    phones: payload.phones, // array[]
    sender: payload.sender,
    amount: payload.amount,
    desc: payload.desc,
  });

  var config = {
    method: "post",
    url: `${url}send-buzz-request-alert`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}

// @======== BENEFITED FROM GIVEAWAY
export function giveawaybefefit(payload) {
  var data = JSON.stringify({
    phones: payload.phone,
    name: payload.name,
    amount: payload.amount,
  });

  var config = {
    method: "post",
    url: `${url}send-giveaway-benefited-alert`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}
