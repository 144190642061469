import { allTransactionsService, moveBuzzBalanceService, updateBuzzRequestService, transactionHistoryService } from "../services/transactions"
import { getUserMetadata } from "../services/users"
export const allTransactionsController = (user, setStates, compState, disp_transactions) => {
   console.log("Started")
   allTransactionsService(user).then(res => {
      console.log(res)

      if (res.data == null) {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         disp_transactions([])
      } else {
         setStates({
            ...compState,
            loading: false,
            error: false
         })
         disp_transactions(res.data)
         console.log("Ended")
         console.log(res.data)
      }
   })
      .catch(err => {
         setStates({
            ...compState,
            loading: false,
            error: true,
         })
         disp_transactions([])
      })
}

export const moveBuzzBalanceController = (payload, login_suc, setStates, compState) => {
   setStates({
      ...compState,
      loading: true
   })

   getUserMetadata(payload.user).then(resp => {
      if (resp.data !== null && resp.data.length !== 0) {

         let newWallet = parseInt(resp.data[0].buzzme_wallet) + parseInt(resp.data[0].wallet)
         let newPayload = {
            wallet: newWallet,
            buzzme_wallet: 0,
            user: payload.user
         }
         moveBuzzBalanceService(newPayload).then(res => {
            login_suc(res.body[0])
            setStates({
               ...compState,
               loading: false
            })
         })
            .catch(error => {
               setStates({
                  ...compState,
                  loading: false
               })
            })
      }
   })
}

// update the status of a buzz request after they've been responded to 
export const updateBuzzRequest = (id, meta) => {
   let payload = {
      id,
      meta
   }
   updateBuzzRequestService(payload).then(res => {
      console.log("Updated meta")
      console.log(res)
   })
}

export async function transactionHistoryController(userId, setStates, compState) { 
   transactionHistoryService(userId).then(res => {
      if (res.body !== null) {
         if (res.body.length > 0) { 
            // get all kashbacks recied
            let kashbackReceived = res.body.filter((e) => e.to == userId && e.type == "Kashback");

            // all kashback sent
            let AllKashbackSent = res.body.filter((e) => e.from == userId && e.type == "Kashback");

            // all kashbacks
            let allbuzzReceived = res.body.filter((e) => e.to == userId && e.type == "BUZ ALERT");
            // all buzz sent'
            let allBuzzSent = res.body.filter((e) => e.from == userId && e.type == "BUZ ALERT");

            function add(accumulator, a) {
               return accumulator + a;
            }

            let kashbackReceivedArray = [];
            let kashbackSentArry = [];
            let receivedBuzz = [];
            let sentBuzzArry = []


            for (let i = 0; i < kashbackReceived.length; i++) {
               const amount = parseInt(kashbackReceived[i].meta.data.amount);
               kashbackReceivedArray.push(amount);
            }
            for (let i = 0; i < AllKashbackSent.length; i++) {
               const amount = parseInt(AllKashbackSent[i].meta.data.amount);
               kashbackSentArry.push(amount);
            }
            for (let i = 0; i < allbuzzReceived.length; i++) {
               const amount = parseInt(allbuzzReceived[i].meta.data.amount);
               receivedBuzz.push(amount);
            }
            for (let i = 0; i < allBuzzSent.length; i++) {
               const amount = parseInt(allBuzzSent[i].meta.data.amount);
               sentBuzzArry.push(amount);
            }


            let data = {
               BuzzSent: sentBuzzArry.reduce(add, 0),
               BuzzReceived: receivedBuzz.reduce(add, 0),
               KashbackReceived: kashbackReceivedArray.reduce(add, 0),
               KashbackSent: kashbackSentArry.reduce(add, 0),
            };
            setStates({
               ...compState,
               loading: false,
               data,
            });
         } else {
            let data = {
               BuzzSent: 0.00,
               BuzzReceived: 0.00,
               KashbackReceived: 0.00,
               KashbackSent: 0.00,
            };
            setStates({
               ...compState,
               loading: false,
               data,
            });
         }

      }
   })
      .catch(error => {
         let data = {
            BuzzSent: 0.00,
            BuzzReceived: 0.00,
            KashbackReceived: 0.00,
            KashbackSent: 0.00,
         };
         setStates({
            ...compState,
            loading: false,
            data,
         });
      })
}