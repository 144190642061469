import { supabase } from '../configurations/index';
const newSupabase = supabase()


// @======== FETCH ALL FEEDS
export async function fetchAllFeedsService(payload) {
  return newSupabase
    .from("new_feeds")
    .select(
      `*, comments(*), post_likes(*), giveaway_lucky_winners(*)`
    )
    // .eq("school", payload)
    .order("id", { ascending: false });
}


// @======== INSERT FEEDS TO DATABASE
export async function insertFeeds(payload) {
  return newSupabase.from("new_feeds").insert([
    {
      feed_id: payload.postId,
      type: payload.type,
      data: payload.data,
      school: payload.school,
      poster: payload.data.user,
      posterId: payload.userData.id
    },
  ]);
}



// @======== CREATE comment
export async function addComments(payload) { 
  return newSupabase.from("comments").insert([
    {
      post: payload.post.id,
      comment: payload.comment, 
      user: payload.user,
      benefited: false,
      data:payload.post
    },
  ]);
}
