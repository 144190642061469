import React, { useState } from "react";
import { connect } from "react-redux";
import FormControl from '@mui/material/FormControl';
import { verifyTokenValidity } from "../../controllers/scan/scan-to-accept"
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
   HomeOutlined,
   SettingsOutlined,
   BarChartOutlined,
   SignalCellularConnectedNoInternet0Bar,
   KeyboardBackspace,
   Cancel
} from "@material-ui/icons";
import CircularProgress from '@mui/material/CircularProgress';

import { Redirect, useHistory } from "react-router-dom";
import { loader } from "../../components/loader"
import { session, clearCounterController } from "../../controllers/checksession" 
import { allKashbackController } from "../../controllers/kashback"
import { userSessionService, } from '../../services/auth'
import { KashbackComponent } from "../../components/kashback"
import {
   allKashback,
   transactionsToView,
   disp_session,
   re_auth,
   disp_realtime,
   disp_noti,
   login,
   logOut,
   scannedData
} from "../../redux";
import Logo from "../../statics/assets/logo.png"

import { Drawer, Divider } from "@mui/material";
import Realtime from "../../components/realtime" 

const inputStyle = {
   height: "55px",
   width: "100%",
   padding: "10px",
   borderRadius: "8px"
}
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   dispAllKashbacks,
   dispTransactionToView, 
   realtime,
   reauth, 
   disp_scannedData

}) {


   let User = userSessionService()

   const [compState, setStates] = useState({
      error: false,
      loading: true
   })
   const [token, settoken] = useState("");

   const [historyview, sethistoryview] = useState("active")
   const state = appState;
   let history = useHistory();
   React.useEffect((compState) => {
      window.scrollTo(0, 0);
      if (User == null) {
         logOut()
         history.push("/login")
      } else if (state.userData == null) {
         reauth()
      } else {
         
         refreshTransactions()
      }
   }, []);

   const refreshTransactions = () => {
      if (User !== null) {
         setStates({
            ...compState,
            loading: true
         })
         allKashbackController(User.user.user_metadata.buzzID, setStates, compState, dispAllKashbacks)
      }
   }

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }

  
   const tryAgain = () => {
      if (User !== null) {
         setStates({
            ...compState,
            loading: true
         })
         allKashbackController(User.user.user_metadata.buzzID, setStates, compState, dispAllKashbacks)
      } else {

      }
   }


   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      // console.log("okk");
      setStates({
         ...compState,
         error: false,
         errorMsg: ""
      })
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setDrawerState({ ...drawerState, [anchor]: open });
   };

   const resolveToken = () => {
      let payload = {
         token,
         compState, setStates, setDrawerState, drawerState,
         history,
         disp_scannedData
      }
      setStates({
         ...compState,
         loading: true,
      })
      verifyTokenValidity(payload)
   }

   return (
      <> 
<Realtime/>
         {/* {compState.loading == true && loader()} */}
         {clearCounterController()}
         {session(history, reauth)}
         {state.loggedIn === false && history.push("/")}

         <div style={{ marginBottom: "60px", height: "50px", background: "  ", padding: "0px 15px", marginTop: "30px" }}>
            <KeyboardBackspace onClick={() => {
               // refreshTransactions()
               history.goBack()
            }} style={{ float: "left", fontSize: "30px" }} />
            <img onClick={() => {
               history.push("/dashboard")
            }} src={Logo} style={{ width: "80px", float: "right" }} />


            {/* <div style={{ position: "absolute", top: "111px", fontSize: "" }} >
               <b style={{ fontSize: "19px", color: "#0C1825" }}>Kashback</b>
               <div style={{ marginTop: "5px" }} >
                  <small>Kashback history</small>
               </div>


            </div> */}

            <div
               onClick={() => {
                  history.push("/create-kashback")

               }}
               style={{ position: "absolute", top: "106px", background: "#0C1825", left: "20px", width: "", textAlign: "center", padding: "10px", color: "white", borderRadius: "4px" }}>
               Create Cash token
            </div>
            <div
               onClick={() => {
                  // history.push("/scan")
                  setDrawerState({ ...drawerState, bottom: true });
               }}
               style={{ position: "absolute", top: "106px", background: "#0C1825", right: "20px", width: "", textAlign: "center", padding: "10px", color: "white", borderRadius: "4px" }}>
               Accept cash token
            </div>
         </div> <br />
         <Divider style={{ margin: "30px 0px" }} />

         <div style={{height: "40px", width: " ", padding: "15px 15%", position: "sticky", top: "0px", zIndex: "1000", background: "rgb(244 244 244)" }}>

            <span onClick={() => {
               sethistoryview("active")
            }} style={{ float: "left", borderBottom: historyview == "active" && "2px solid #0C1825", color: historyview == "active" ? "#0C1825" : "grey" }}>Active token</span>
            <span onClick={() => {
               sethistoryview("used")
            }} style={{ float: "right", color: historyview == "used" ? "#0C1825" : "grey", borderBottom: historyview == "used" && "2px solid #0C1825" }}>Used token</span>
         </div>

         <form style={formSTyle} noValidate autoComplete="off" >

            {/* {compState.error == true ? <div style={{ position: "fixed", top: "30%", textAlign: " ", width: "100%", background: " ", left: "0px" }}>
               <center>
                  <SignalCellularConnectedNoInternet0Bar style={{ fontSize: "85px", color: "red  " }} /> <br />
                  <small style={{ color: "red" }}>
                     {compState.errorMsg}
                  </small>
                  <br /><br />
                  <b style={{ color: "#0a3d62" }} onClick={() => {
                     tryAgain()
                  }} >Try again</b>
               </center>
            </div> : <div style={{ padding: " ", background: "  ", textAlign: "left", width: "", marginTop: "20px", color: " " }}>
               {compState.loading === false && KashbackComponent(state, history, User.user, dispTransactionToView, historyview)}

            </div>} */}

            {compState.loading == true && <>

               <div style={{ position: "fixed", top: "50%", textAlign: " ", width: "100%", background: " ", left: "0px" }}>
                  <center>
                     <CircularProgress />
                  </center>
               </div>
            </>}



            <div style={{ padding: " ", background: "  ", textAlign: "left", width: "", marginTop: "20px", color: " " }}>
               {compState.loading === false && KashbackComponent(state, history, User.user, dispTransactionToView, historyview)}

            </div>

         </form>

         <br /><br /><br /> <br /><br />

         <div style={{ height: "64px", width: "100%", position: "fixed", background: "rgb(244 244 244)", bottom: "0px", left: "0px", borderTop: "1px solid rgba(12, 24, 37, 0.18)", textAlign: "center" }}>
            <center onClick={() => {
               history.push("/dashboard")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", }}>
               <HomeOutlined style={{ float: "" }} />
               <div><small>Home</small></div>
            </center>
            <center onClick={() => {
               history.push("/transaction")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", color: " " }}>
               <BarChartOutlined style={{ float: "" }} />
               <div><small>Transactions</small></div>
            </center> 
            <center onClick={() => {
               history.push("/account")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px" }}>
               <SettingsOutlined style={{ float: "" }} />
               <div><small>Account</small></div>
            </center>
         </div>

         <React.Fragment key="bottom">
            <Drawer
               anchor="bottom"
               open={drawerState["bottom"]}
               onClose={toggleDrawer("bottom", false, false)}
            >

               <div style={{ position: "relative", height: "380px", padding: "10px 20px" }}>
                  {compState.error !== true ?
                     <div>
                        <KeyboardBackspace
                           style={{ fontSize: "30px", color: "#0C1825" }}
                           onClick={() => {
                              setDrawerState({ ...drawerState, bottom: false });
                           }}
                        /> <br /><br />
                        <div style={{ position: " ", top: "65px", fontSize: "" }} >
                           <b style={{ fontSize: "20px", color: "#0C1825" }}>Accept Cash token</b>
                           <div>
                              <small style={{ color: "#272833", fontSize: "14px" }}>Scan or input Cash token to receive payment</small>
                           </div>
                        </div>

                        <br />
                        <FormControl style={{ width: "100%", marginTop: "20px", borderRadius: "6px" }}>
                           <InputLabel htmlFor="component-outlined">Cash token</InputLabel>
                           <OutlinedInput
                              style={inputStyle}
                              type="number"
                              id="component-outlined"
                              onChange={(e) => {
                                 settoken(e.target.value)
                              }}
                              value={token}
                              required
                              label="Cashback code"
                           />
                        </FormControl> <br /><br />



                        <div style={{ textAlign: "center" }}>

                           {compState.loading == true ? <>

                              <center>
                                 <CircularProgress />
                              </center>
                           </> : <>
                              {token.length < 6 ?
                                 <button 
                                    type="button"
                                    value=""
                                    style={{
                                       padding: "10px",
                                       outline: "none",
                                       width: "190px",
                                       background: "#0C1825",
                                       border: "0.5px solid #0C1825",
                                       color: "white",
                                       borderRadius: "4px",
                                       //   margin: "10px 19px",
                                       fontWeight: "bold",
                                       margin: "10px",
                                       opacity:"0.4"
                                    }}
                                 >Enter token</button> :
                                 <button
                                    onClick={() => {
                                       resolveToken()
                                    }}
                                    type="button"
                                    value=""
                                    style={{
                                       padding: "10px",
                                       outline: "none",
                                       width: "190px",
                                       background: "#0C1825",
                                       border: "0.5px solid #0C1825",
                                       color: "white",
                                       borderRadius: "4px",
                                       //   margin: "10px 19px",
                                       fontWeight: "bold",
                                       margin: "10px",
                                    }}
                                 >Next</button>}
                           </>}

                           <br /><br />

                           OR
                           <br /><br />
                           <b
                              onClick={() => {
                                 history.push("/scan")
                              }}
                              style={{ fontWeight: 500, color: "#0050C8", fontSize: "16px" }}>Scan Qrcode</b>
                           <br />
                        </div>
                     </div> :

                     <div style={{ textAlign: "center" }}> <br />

                        <Cancel style={{ fontSize: "124px", color: "#DA1414" }} />  <br /><br />
                        <b style={{ color: "#DA1414", fontSize: "20px", fontWeight: 800 }}>Error</b> <br />
                        <span style={{ fontSize: "14px", color: "#272833" }}>
                           Cashback token has been used
                        </span>  <br /><br /><br /><br />
                        <button
                           onClick={() => {
                              history.push("/dashboard")
                           }}
                           type="button"
                           value=""
                           style={{
                              padding: "10px",
                              outline: "none",
                              width: "250px",
                              background: "#0C1825",
                              border: "0.5px solid #0C1825",
                              color: "white",
                              borderRadius: "4px",
                              //   margin: "10px 19px",
                              fontWeight: "bold",
                              margin: "10px",
                           }}
                        >Done</button>

                     </div>}

               </div>

            </Drawer>
         </React.Fragment>

      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      dispAllKashbacks: (payload) => dispatch(allKashback(payload)),
      dispTransactionToView: (payload) => dispatch(transactionsToView(payload)),
      set_session: (time) => dispatch(disp_session(time)),
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      reauth: () => dispatch(re_auth()),
      log_out: () => dispatch(logOut()),
      disp_scannedData: (data) => dispatch(scannedData(data)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
