import {
   Backspace,
   KeyboardBackspace,
   LockOpenOutlined,
   Lock,
   // KeyboardBackspace,
   PeopleAltOutlined,
   PublicOutlined,
   CloseOutlined
} from "@material-ui/icons";

export const AuthenticateScreen = ({ not, log_out, history, toast, functions, page, setPin, pin, setpinError, pinError, User, setDrawerState, drawerState, setStates, compState }) => {
   const pinVal = () => {
      if (pin.length == 1) {
         return (
            <>
               {" "}
               <div style={{
                  //  height: "70px", width: "70px", 
                  padding: "20px 25px",
                  fontSize: "20px",
                  background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}>
                  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div>
               </div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>
            </>
         );
      } else if (pin.length == 2) {
         return (
            <>
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>
            </>
         );
      } else if (pin.length == 3) {
         return (
            <>
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div> </div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div> </div>
            </>
         );
      } else if (pin.length == 4) {
         return (
            <>
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "#0C1825", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div> </div>
            </>
         );
      } else {
         return (
            <>
               {" "}
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}> <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div> </div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>&nbsp; &nbsp;
               <div style={{
                  padding: "20px 25px",
                  fontSize: "20px", background: "lightgrey", borderRadius: "8px", display: "inline-block"
               }}>  <div style={{ height: "18px", width: "18px", borderRadius: "18px", backgroundColor: "white" }} ></div></div>
            </>
         );
      }
   };
   // console.log(User)
   const verify = (newPin) => {
      if (newPin == User.transactionPin) {
         // if (actionType == "REQUEST") {
         //    // placeRequest();
         // } else {
         //    // initiateTransaction();
         // }
         functions()
         // setDrawerState({ ...drawerState, bottom: false });
      } else {
         setPin("");
         if (setStates) {
            setpinError(`Incorrect pin, ${2 - parseInt(compState.trial)} trial left`);
            setStates({
               ...compState,
               trial: compState.trial + 1,
               error: true,
               msg: `Incorrect pin. You have ${2 - parseInt(compState.trial)} trial left`
            })
            if (compState.trial == 2) {
               console.log("Logout")
               log_out()
               history.push("/login");
            }
         }  
         if (not) {
            not()
         }
         window.navigator.vibrate([200]);  
         setpinError("Wrong pin")
      } 
   };

   const appendPin = (e) => {
      setpinError("")
      if (compState) {
         setStates({
            ...compState,
            error: false
         })
      }
      let newPin = pin + e;
      if (pin.length !== 4) {
         setPin(newPin);
      }
      if (newPin.length == 4) {
         verify(newPin);
      }
      //  console.log(newPin)
   };

   const clearPin = (e) => {
      let pinLength = pin.length;
      let clearOne = pinLength - 1;
      setPin(pin.substring(0, clearOne));
   };

   const buttonValue = (e) => {
      if (e == "clear") {
         return <Backspace />;
      } else if (e == "out") {
         return <KeyboardBackspace />;
      } else {
         return <>{e}</>;
      }
   };


   //   AUTHENTICATION KEYBOARD
   const authKeyboaard = () => {
      let pinNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'out', 0, "clear"];
      return pinNumbers.map((e, key) => {
         return (
            <>
               {/* {console.log(state)} */}
               <button
                  className="customInput"
                  style={{
                     width: "60px",
                     padding: "2px 20px",
                     fontWeight: "bold",
                     border: "none",
                     background: "rgb(244 244 244)",
                     //   color: "black",
                     margin: "25px 20px",
                     fontSize: "20px",
                     textAlign: "center",
                     borderRadius: "5px",
                  }}
                  key={key}
                  value={e}
                  onClick={() => {
                     if (e == "clear") {
                        clearPin(e);
                     } else if (e == "out") {
                        if (page == "REAUTH") {
                           setDrawerState({ ...drawerState, bottom: true });

                        }
                     } else {
                        // appendPin(e);
                        if (not) {
                           not()
                        }
                        // setpinError("")
                        // if (compState) {
                        //    setStates({
                        //       ...compState,
                        //       error: false
                        //    })
                        // }
                        let newPin = pin + e;
                        // if (pin.length !== 4) {
                        //    setPin(newPin);
                        // }
                        if (newPin.length == 4) return verify(newPin);
                        setPin(newPin);
                        if (setStates) {
                           setStates({
                              ...compState,
                              error: false
                           })
                        }
                     }
                  }}
               >
                  {buttonValue(e)}
               </button>
            </>
         );
      });
   };

   return (
      <>
         <div
            style={{
               height: " 460px",
               background: "rgb(244 244 244)",
               // padding: "10px",
               textAlign: "center",
            }}
         >
            <div>
               {/* <b>Provide your pin</b> */}
               {/* <b>Authorize transaction </b> */}
               {/* <div><small>provide your pin</small></div> */}
            </div>
            <div
               style={{
                  padding: "15px",
                  // background: "black",
                  color: "white",
                  // marginTop: "90px",
                  textAlign: "center"
               }}
            >


               {pinVal()}
               {compState && <div style={{ color: "black", marginTop: "3px" }}>
                  {compState.error == true && <> <b
                     onClick={() => {
                        log_out();
                        history.push("/login");
                     }}
                     style={{ color: "crimson", fontSize: "" }}>{compState.msg}</b></>}
               </div>}
               {/* <br /><br /> */}
            </div>
            <div style={{
               // position: " ",
               // bottom: "0%",
               // left: "0px",
               // height: "60px",
               // backgroundColor: "green",
               // width: "100%"
            }}>
               {authKeyboaard()}
            </div>
            {/* <div>
               <span style={{ color: "crimson" }}>{pinError}</span>
            </div> */}
         </div>
      </>
   )
}