import { supabase } from '../configurations/index';
import { formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../Utilities/index"


const newSupabase = supabase()

export async function checkChatService(user) {
   return newSupabase.from("messages")
      .select("*")
      .eq("userID", user)
}

export async function updateChatService(msg,user) {
   return newSupabase
      .from("messages")
      .update({
         content:msg
      })
      .eq("userID",user)
}

export async function saveChatService(payload) {
   return newSupabase.from("messages")
      .insert([
         {
            content: [{
               msg: payload.msg,
               date: {
                  day: daysOfTheWeek(new Date),
                  month: monthsOfTheYear(),
                  year: new Date().getFullYear(),
                  date: new Date().getDate(),
                  time: formatAMPM(new Date())
               },
               user:true
            }],
            read: "",
            user: payload.User,
            userID: payload.User.buzzID,
         },
      ])
}