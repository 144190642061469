import React, { useState } from "react";
import { connect } from "react-redux";
import {
   ArrowForwardIosOutlined,
   Person,
   HelpOutlineOutlined,
   EmailOutlined,
} from "@material-ui/icons";
import { Drawer, Divider } from "@mui/material";
import {
   HomeOutlined,
   SettingsOutlined,
   BarChartOutlined, 
} from "@material-ui/icons";
import Logo from "../../statics/assets/logo.png"
import { KeyboardBackspace } from "@material-ui/icons";
import { Redirect, useHistory } from "react-router-dom";
import { loader } from "../../components/loader"
import { session, clearCounterController } from "../../controllers/checksession"
import { signinController } from "../../controllers/auth"
import {
   login,
   disp_session,
   re_auth
} from "../../redux";



const rec_inputs = {
   // width: "97%",
   padding: "8px",
   height: "70px",
   border: "0.5px solid lightgrey",
   color: "grey",
   outline: "none",
   fontSize: "13px",
   resize: "none",
   borderRadius: "5px",
   margin: "25px"
};
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   login_suc,
   set_session,
   reauth
}) {


   
   const [compState, setStates] = useState("") 
   const state = appState;
   let history = useHistory(); 
   React.useEffect((compState) => {
      window.scrollTo(0, 0); 
   }, []);



   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      // console.log("okk");
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setDrawerState({ ...drawerState, [anchor]: open });
   };





   return (
      <>
         {compState.loading == true && loader()}
         {clearCounterController()}
         {session(history,reauth)}
         <form style={formSTyle} noValidate autoComplete="off" >
            <div style={{ marginBottom: "30px", height: "30px", background: " " }}>
               <KeyboardBackspace onClick={() => {
                  history.goBack()
               }} style={{ float: "left", fontSize: "30px" }} />
               <img src={Logo} style={{ width: "80px", float: "right" }} />
            </div>
            <b style={{
               position: 'static',
               left: '0%',
               right: ' 42.5%',
               top: '0%',
               bottom: ' 62.03%',
               fontStyle: 'normal',
               fontWeight: ' 700',
               fontSize: ' 17px',
               lineHeight: '30px',
               /* identical to box height */

               letterSpacing: '-0.02em',

               color: '#0C1825',
            }}>
               Get Help
            </b> <br />
            <small>
               How would you want to get to us?
            </small>
            <br />


            <div style={{ padding: " ", background: "  ", textAlign: "left", width: "", marginTop: "80px", color: "white" }}>

               <a style={{ color: "white" }} href="tel:+2349011684637">
                  <div style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                     <div style={{ position: "absolute", left: "78px", }}>
                        <b style={{ fontSize: "16px" }}>Call Us</b>
                        <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>Contact call center</span></div>
                     </div>
                     <Person style={{ position: "absolute", left: "25px", }} />
                     <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

                  </div>

               </a>




               <div
                  onClick={() => {
                     history.push("/chat")
                  }} style={{

                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Chat With Us</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>Send an in-app message</span></div>
                  </div>
                  <HelpOutlineOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>

               <div
                  onClick={() => {
                     setDrawerState({ ...drawerState, bottom: true });
                  }}
                  style={{
                     height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                     background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
                  }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>Send an Email</b>
                     <div style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)", fontSize: "12px" }}><span>We will reply as soon as possible</span></div>
                  </div>
                  <EmailOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div>


               {/* 
               <div style={{
                  height: "34px", width: "100%", borderRadius: "8px", position: "relative",
                  background: "#0C1825", padding: "20px 0px", marginBottom: "20px", textAlign: " "
               }}>
                  <div style={{ position: "absolute", left: "78px", }}>
                     <b style={{ fontSize: "16px" }}>FAQ</b>
                  </div>
                  <LibraryBooksOutlined style={{ position: "absolute", left: "25px", }} />
                  <ArrowForwardIosOutlined style={{ position: "absolute", right: "15px", }} />

               </div> */}
            </div>
         </form>


         <div style={{ height: "64px", width: "100%", position: "fixed", background: "#FFFFFF", bottom: "0px", left: "0px", borderTop: "1px solid rgba(12, 24, 37, 0.18)", textAlign: "center" }}>
            <center onClick={() => {
               history.push("/dashboard")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px", color: " " }}>
               <HomeOutlined style={{ float: "" }} />
               <div><small>Home</small></div>
            </center>
            <center onClick={() => {
               history.push("/transaction")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px" }}>
               <BarChartOutlined style={{ float: "" }} />
               <div><small>Transactions</small></div>
            </center>
            <center onClick={() => {
               history.push("/account")
            }} style={{ height: "100%", width: "30%", background: "", display: "inline-block", padding: "5px" }}>
               <SettingsOutlined style={{ float: "" }} />
               <div><small>Account</small></div>
            </center>
         </div>




         <React.Fragment key="bottom">
            <Drawer
               anchor="bottom"
               open={drawerState["bottom"]}
               onClose={toggleDrawer("bottom", false, false)}
            >
               <div style={{ color: "grey", marginTop: "15px", textAlign: "center", height: "210px", }}>
                   <br/><br/>
                  <b >
                     Write to us, we will treat as urgent.
                  </b>  <br/> <br/><br/>
                  <span style={{ padding: "10px 40px", background: "lightgrey", }}>
                     info@buzzpay.com
                  </span>
               </div>

            </Drawer>
         </React.Fragment>


      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      login_suc: (userMetadata) => dispatch(login(userMetadata)),
      set_session: (time) => dispatch(disp_session(time)),
      reauth: () => dispatch(re_auth()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
