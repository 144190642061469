import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AuthenticateScreen } from "../../components/auth"
import {
   Backspace,
   KeyboardBackspace,
   CheckCircle
} from "@material-ui/icons";

import { cashbackchargecentage, buzzPayChargeOnKashbak, cashbackRegEx, formatAMPM, daysOfTheWeek, monthsOfTheYear } from "../../Utilities/index"


import Naira from "react-naira";
import Logo from "../../statics/assets/logo.png"
import { FormControl, Drawer } from "@mui/material";
import { loader } from "../../components/loader"
import { createKashbackController } from "../../controllers/kashback"
import { userSessionService, } from '../../services/auth'
import { session, clearCounterController } from "../../controllers/checksession"
import {
   login,
   re_auth,
   disp_realtime,
   disp_noti,
   allKashback,
   scannedData,
   transactionsToView
} from "../../redux";
import Realtime from "../../components/realtime"

function Home({
   appState,
   login_suc,
   dispAllKashbacks,
   disp_scannedData,
   reauth,
   dispTransactionToView
}) {

   const [stage, setStage] = useState(null)

   const state = appState;
   const history = useHistory();
   const [amount, setAmount] = useState(0);
   const [max, setMax] = useState("");
   const [pin, setPin] = useState("");
   const [pinError, setpinError] = useState("");
   const [compState, setStates] = useState({
      loading: false
   });
   const [beneficiary, setbeneficiary] = useState("");
   const [verifyError, setverifyError] = useState("");
   const [stateAlert, setStateAlert] = useState("");
   const [actionType, setActiontype] = useState(null); // set treu if action type is send else set false if action type is request 
   // @==== RESET ALL STATE UON SUCCESS
   const sendToDefault = () => {
      setbeneficiary("");
      setActiontype(null);
      setPin("");
      setAmount(0);
      setDrawerState({ ...drawerState, bottom: false });
      setStates("")
   };
   let User = userSessionService().user.user_metadata
   const append = (e) => {

      let newPin = amount + "" + e;
      setAmount(newPin);
   };

   const clear = (e) => {
      let pinLength = pin;
      let clearOne = pinLength - 1;
      setAmount(0);
      setMax("");
   };

   const buttonValue = (e) => {
      if (e == "clear") {
         return <Backspace />;
      } else if (e == "out") {
         return <KeyboardBackspace />;
      } else {
         return <>{e}</>;
      }
   };

   const showAmount = () => {
      return (
         <>
            {" "}
            <b style={{ opacity: "1", color: "#0a3d62", fontSize: "35px" }}>
               <Naira>{amount}</Naira>
            </b>
         </>
      );
   };






   React.useEffect(() => {

      if (User == null) {
         // logOut()
         history.push("/login")
      } else if (state.userData == null) {
         history.push("/")
      } else {

      }



   }, []);

   function separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
   }




   const buttons = () => {
      let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "00", 0, "clear"];
      return numbers.map((e) => {
         return (
            <>
               {/* {console.log(state)} */}
               <button
                  className="customInput"
                  style={{
                     width: "60px",
                     padding: "2px 20px",
                     fontWeight: "bold",
                     border: "none",
                     background: "rgb(244 244 244)",
                     //   color: "black",
                     margin: "20px 20px",
                     fontSize: "20px",
                     textAlign: "center",
                     borderRadius: "5px",
                  }}
                  value={e}
                  onClick={() => {
                     if (e == "clear") {
                        clear(e);
                     } else if (e == "out") {
                        history.push("/");
                     } else {
                        append(e);
                     }
                  }}
               >
                  {buttonValue(e)}
               </button>
            </>
         );
      });
   };




   const initiateTransaction = () => {
      setStates({
         ...compState,
         loading: true
      })
      // percentage plus charge  (To be deducted from the user's account)
      const amountPlusCharge = parseInt(amount) + parseInt(cashbackchargecentage(amount))

      // admin takes
      const adminTakes = parseInt(buzzPayChargeOnKashbak(amount))

      const payload = {
         amount: parseInt(amount),
         amountPlusCharge,
         adminTakes,
         User: state.userData,
         compState, setStates,
         charge: parseInt(cashbackchargecentage(amount)),
         cashbackRegEx,
         login_suc,
         date: {
            day: daysOfTheWeek(new Date),
            month: monthsOfTheYear(),
            year: new Date().getFullYear(),
            date: new Date().getDate(),
            time: formatAMPM(new Date())
         },
         dispAllKashbacks,
         state,
         state, setStage,
         disp_scannedData,
         dispTransactionToView
      }

      createKashbackController(payload)

      // proceed create
      setPin("");
   }

   let successPayload = {
      title: "SUCCESS",
      msg: compState.alertMsg,
      error: false,
   };

   let errorPayload = {
      title: "error",
      msg: compState.alertMsg,
      error: true,
   };


   const [drawerState, setDrawerState] = React.useState({
      bottom: false,
   });

   const toggleDrawer = (anchor, open, post) => (event) => {
      // console.log("okk");
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }

      setDrawerState({ ...drawerState, [anchor]: open });
      if (stage == "SENDBUZZ-SUCCESS") {
         history.push("/dashboard")
      }
   };

   return (
      <div id="body bg">
         {clearCounterController()}
         {session(history, reauth)}
         <Realtime />
         <>
            {compState.loading == true && loader()}
            {stateAlert === true &&
               alert(successPayload, setStateAlert, sendToDefault)}
            {stateAlert === false && alert(errorPayload, setStateAlert)}
            {/* {pin == "validated" && <> {cashbackloader()}</>} */}
            <div
               style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  // top: "-20px",
                  left: "0px",
                  // zIndex: "10000",
                  background: "",
               }}
            >
               <div style={{ marginBottom: "60px", height: "30px", background: " ", padding: "0px 15px" }}>
                  <KeyboardBackspace onClick={() => {
                     history.goBack()
                  }} style={{ float: "left", fontSize: "30px" }} />
                  {/* <img src={Logo} style={{ width: "80px", float: "right" }} /> */}
                  <b style={{ fontSize: "20px", color: "#0C1825", float: "right" }}>Create Cash token</b>
               </div>


               <div
                  style={{
                     height: "",
                     // background: "black",
                     padding: " ",
                     textAlign: "center",
                  }}
               >
                  <div
                     style={{
                        padding: "15px",
                        // background: "lightgray",
                        color: "white",
                        position: "absolute",
                        top: "10%",
                        width: "100%",
                        textAlign: "center",
                     }}
                  >
                     <div>
                        <small style={{ color: "grey", fontSize: "12px" }}>Enter amount</small>
                     </div>
                     {showAmount()}
                     {/* {verify()} */}
                     <div style={{ color: "crimson" }}>{max}</div>
                  </div>
                  <div style={{ position: "absolute", top: "31%", width: "100%" }}>
                     {buttons()}
                     <br /> <br />
                     <div>
                     </div>


                  </div>
               </div>
               <button
                  type="button"
                  value=""
                  style={{
                     padding: "13px 17px",
                     outline: "none",
                     width: "90%",
                     background: " #0C1825",
                     border: "0.5px solid #0C1825",
                     color: "white",
                     borderRadius: "6px",
                     left: "5%",
                     fontWeight: "bold",
                     position: "absolute",
                     bottom: "15px"
                  }}

                  onClick={() => {
                     let amntPlsCharge = parseInt(amount) + parseInt(cashbackchargecentage(amount))
                     if (amount >= 500) {
                        if (amntPlsCharge > parseInt(state.userData.wallet)) {
                           setMax("Insufficient wallet balance");
                        } else if ((parseInt(amount) < 1)) {
                           setMax("Provide amount");

                        }
                        else {
                           setMax("");
                           setDrawerState({ ...drawerState, bottom: true });
                           setverifyError('')
                           setStage("confirm")
                        }
                     } else {
                        setMax("Minimum Cashback amount is 500.00");
                     }
                  }}
               >NEXT</button>
            </div>


            <React.Fragment key="bottom">
               <Drawer
                  anchor="bottom"
                  open={drawerState["bottom"]}
                  onClose={toggleDrawer("bottom", false, false)}
               >
                  <div
                     style={{
                        height: "",
                        background: "rgb(244 244 244)",
                        padding: "10px",
                        textAlign: "center",
                     }}
                  >
                     <div
                        style={{
                           width: "",
                           //   marginLeft: "32.5%",
                           //   textAlign: "left",
                           background: "rgb(244 244 244)",
                        }}
                     >
                        <div style={{ color: "crimson" }}>{verifyError}</div>


                     </div>
                     <br />
                  </div>

                  {stage === 'confirm' && <>
                     <div style={{ padding: " 10px 30px", background: "rgb(244 244 244)" }}>
                        <span style={{ float: "left" }}
                           onClick={() => {
                              setDrawerState({ ...drawerState, bottom: false });
                           }}
                        >
                           <KeyboardBackspace />
                        </span>
                        <br />  <br />
                        <div style={{ textAlign: "center", background: "rgb(244 244 244)" }}>
                           <div>
                              <small>Amount</small>
                           </div>
                           <b> {showAmount()}</b>
                        </div> <br /><br />
                        <div style={{ background: "  ", height: "50px" }}>
                           <span style={{ float: "left" }} >
                              Cash token Charge
                           </span>
                           <span style={{ float: "right" }}>

                              <Naira>{cashbackchargecentage(amount)}</Naira>
                           </span>
                        </div>

                        <div style={{ background: "  ", height: "50px" }}>
                           <span style={{ float: "left" }} >
                              System  Charge
                           </span>
                           <span style={{ float: "right" }}>
                              <Naira>{buzzPayChargeOnKashbak(amount)}</Naira>

                           </span>
                        </div><br /><br />

                        <button
                           type="button"
                           // value="NEXT"
                           style={{
                              padding: "13px 17px",
                              outline: "none",
                              width: "90%",
                              background: " #0C1825",
                              border: "0.5px solid #0C1825",
                              color: "white",
                              borderRadius: "6px",
                              left: "5%",
                              fontWeight: "bold",
                              position: " ",
                              bottom: "30px"
                           }}

                           onClick={() => {
                              setStage("authorize")
                           }}
                        >NEXT</button> <br /><br /><br />
                     </div>

                  </>}

                  {stage === 'authorize' && (
                     <div
                        style={{
                           height: " 500px",
                           background: "rgb(244 244 244)",
                           // padding: "10px",
                           textAlign: "center",
                        }}
                     >
                        <span style={{ float: "left", marginLeft: "20px" }}
                           onClick={() => {
                              setDrawerState({ ...drawerState, bottom: false });
                           }}
                        >
                           <KeyboardBackspace />
                        </span> <br /><br />


                        <div style={{ position: " ", top: "", fontSize: "", background: "rgb(244 244 244)", left: "", width: " ", textAlign: "left" }} >
                           {pinError.length > 0 &&
                              <center style={{ backgroundColor: "#f59dae", color: "white", padding: "6px" }} >{pinError}</center>
                           }
                           <AuthenticateScreen setDrawerState={setDrawerState} drawerState={drawerState} pin={pin} setPin={setPin} pinError={pinError} setpinError={setpinError} User={User} functions={initiateTransaction} />

                        </div>

                     </div>
                  )}

                  {stage == true && <>
                     <center style={{ marginTop: "5%", marginBottom: "5%", background: "rgb(244 244 244)", width: "90%", marginLeft: "5%", padding: "20px 0px", borderRadius: "6px", height: "100%" }}>
                        {/* <CheckCircle style={{ fontSize: "80px",color:"white" }} /> */}
                        {/* <img src={Logo} style={{ width: "100px", }} /> */}

                        <CheckCircle style={{ fontSize: "120px", color: "mediumseagreen" }} />

                        <div style={{ marginTop: "20px", width: "70%", textAlign: "center" }}>
                           <div style={{ color: "black", fontSize: "18px" }}>
                              <b style={{ color: "#272833", fontWeight: 800, fontSize: "20px" }}> ₦{parseInt(amount)} </b> <br />
                              <span style={{ fontSize: "14px", fontWeight: 400, color: "#272833" }}>Cashback was successfull created</span>
                           </div>

                           <button
                              style={{
                                 marginTop: "40px",
                                 border: "none",
                                 padding: "10px 20px",
                                 borderRadius: "5px",
                                 color: "white",
                                 background: "#0C1825",
                                 width: "100%",
                              }}
                              onClick={() => {
                                 // disp_scannedData(state.scannedData)
                                 // setDrawerState({ ...drawerState, bottom: false });
                                 setStage(null)
                                 // history.goBack()
                                 history.push("/view-kashback")
                              }}>
                              <b>View Cash token</b>
                           </button>
                        </div>
                     </center>
                  </>}

                  {stage == false && <>
                     <center style={{ marginTop: "5%", marginBottom: "5%", background: "", width: "90%", marginLeft: "5%", padding: "5px 0px", borderRadius: "6px", height: "300px" }}>
                        {/* <CheckCircle style={{ fontSize: "80px",color:"white" }} /> */}
                        <img src={Logo} style={{ width: "100px", }} />

                        <div style={{ marginTop: "30px", width: "70%", textAlign: "center", background: "", padding: "15px", borderRadius: "5px" }}>
                           <div style={{ color: "black", fontSize: "18px" }}>
                              <b style={{ color: "crimson" }}> Network error</b> <br />
                              <small style={{ fontSize: "12px", color: "red" }}>Make sure you are connected to the internet</small>
                           </div>

                           <button
                              style={{
                                 marginTop: "30px",
                                 border: "none",
                                 padding: "10px 20px",
                                 borderRadius: "5px",
                                 color: "white",
                                 background: "crimson"
                              }}
                              onClick={() => {
                                 setDrawerState({ ...drawerState, bottom: false });
                                 setStage(null)
                              }}>
                              <b>OK</b>
                           </button>
                        </div>
                     </center>
                  </>}

               </Drawer>
            </React.Fragment>
         </>
      </div >
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      dispTransactionToView: (payload) => dispatch(transactionsToView(payload)),
      realtime: (data) => dispatch(disp_realtime(data)),
      dispNoti: (payload) => dispatch(disp_noti(payload)),
      login_suc: (data) => dispatch(login(data)),
      reauth: () => dispatch(re_auth()),
      dispAllKashbacks: (payload) => dispatch(allKashback(payload)),
      disp_scannedData: (data) => dispatch(scannedData(data)),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
