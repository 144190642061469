import React, { useState } from "react";
import { connect } from "react-redux";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
   validateEmail,
} from "../Utilities/index"
import Logo from "../statics/assets/logo.png"
import { KeyboardBackspace } from "@material-ui/icons";
import { Redirect, useHistory } from "react-router-dom";
import { loader } from "../components/loader"
import { session, clearCounterController } from "../controllers/checksession"
import { resetpwdController } from "../controllers/auth"
import {
   disp_session,
   logOut
} from "../redux";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { updateuserService, updateuserPWDService, ChangeUserMetadata } from "../services/auth"
import md5 from "md5";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const inputStyle = {
   height: "55px",
   width: "100%",
   padding: "10px"
}
const formSTyle = {
   width: "90%",
   marginLeft: "5%",
   marginTop: "30px"
}
function Login({
   appState,
   set_session,
   log_out
}) {




   const [resetData, setResetData] = useState(null);

   const [email, setEmail] = useState("");
   const [compState, setStates] = useState("")
   const [password, setPassword] = useState("");
   const [OTP, setOTP] = useState("");
   const [values, setValues] = React.useState({
      showPassword: false,
   });
   const handleClickShowPassword = () => {
      setValues({
         ...values,
         showPassword: !values.showPassword,
      });
   };

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };




   const state = appState;
   let history = useHistory();
   let login = () => {
      let payload = {
         email,
         compState,
         setStates,
         setResetData
      }
      if (email.length < 1) {
         setStates({
            ...payload.compState,
            error: true,
            loading: false,
            msg: "Enter email",
         });
      } else if (validateEmail(email) == false) {
         setStates({
            ...payload.compState,
            error: true,
            loading: false,
            msg: "Invalid email",
         });
      } else {
         setStates({
            ...payload.compState,
            loading: true,
         });
         resetpwdController(payload)
      }
   }
   React.useEffect((compState) => {
      window.scrollTo(0, 0);

      // clearCounterController()
      // session(history)
   }, []);


   let changePWD = () => {
      if (resetData.otp != OTP) return setStates({
         compState,
         error: true,
         loading: false,
         msg: "You entered invalid OTP",
      });

      const new_pwd = md5(password + email);
      let payload = {
         email: email,
         password: password,
         saltedPwd: new_pwd,
         meta: resetData.data,
         setStates,
         compState
      }
      if (password.length < 1) {
         setStates({
            compState,
            error: true,
            loading: false,
            msg: "Enter email",
         });
      } else {
         setStates({
            compState,
            error: false,
            loading: true,
            msg: "",
         });
         updateuserPWDService(payload).then(res => {
            console.log(res)
            if (res.data != null) {
               // change password and raw password in the userMetadata table
               ChangeUserMetadata(payload).then(response => {

                  setPassword("")
                  toast.success('Password updated successfully', {
                     position: "top-right",
                     autoClose: 3000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     onClose: () => {
                        log_out("HARD")
                        history.push("/login")
                     }
                  });
                  setStates({
                     ...compState,
                     loading: false
                  })
               })
            } else {
               toast.error('An error occured', {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });
            }
         })
            .catch(error => {
               toast.error('An error occured', {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });
            })
      }

   }


   return (
      <>

         {compState.loading == true && loader()}
         {/* {clearCounterController()}
         {session(history)} */}

         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />


         <form style={formSTyle} noValidate autoComplete="off" >
            <div style={{ marginBottom: "60px", height: "30px", background: " " }}>
               <KeyboardBackspace onClick={() => {
                  history.goBack()
               }} style={{ float: "left", fontSize: "30px" }} />
               <img src={Logo} style={{ width: "80px", float: "right" }} />
            </div>


            {resetData != null ? <>
               <b style={{
                  position: 'static',
                  left: '0%',
                  right: ' 42.5%',
                  top: '0%',
                  bottom: ' 62.03%',

                  // fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: ' 700',
                  fontSize: ' 17px',
                  lineHeight: '30px',
                  /* identical to box height */

                  letterSpacing: '-0.02em',

                  color: '#073F74',
               }}>
                  Choose New Password
               </b> <br />
               <small>
                  Enter the OTP sent to your registered email
               </small>
               <br />

               <br />

               <FormControl style={{ width: "100%", marginTop: "35px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Enter OTP here</InputLabel>
                  <OutlinedInput
                     onChange={(e) => {
                        setOTP(e.target.value);
                     }}
                     value={OTP}
                     required


                     id="outlined-adornment-password"
                     type='text'
                     style={inputStyle}
                     label="Enter OTP here"
                  />
               </FormControl>


               <FormControl style={{ width: "100%", marginTop: "35px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                  <OutlinedInput
                     onChange={(e) => {
                        setPassword(e.target.value);
                     }}
                     value={password}
                     required


                     id="outlined-adornment-password"
                     type={values.showPassword ? 'text' : 'password'}
                     style={inputStyle}
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     }
                     label="New password"
                  />
               </FormControl>

               <br />
               {compState.error == true && <><div style={{ color: "crimson", textAlign: "center", marginTop: "" }}>{compState.msg}</div></>}
               <br /><br />
               <button
                  type="button"
                  style={{
                     background: "#0C1825",
                     color: "white",
                     border: "none",
                     outline: "none",
                     padding: "7px 50px",
                     marginTop: "5px",
                     borderRadius: "6px",
                     width: "100%",
                     height: "42px"
                  }}
                  onClick={(e) => {
                     changePWD();
                  }}
               >
                  {" "}
                  Reset Password
               </button>
            </> :
               <>
                  <b style={{
                     position: 'static',
                     left: '0%',
                     right: ' 42.5%',
                     top: '0%',
                     bottom: ' 62.03%',

                     // fontFamily: 'Poppins',
                     fontStyle: 'normal',
                     fontWeight: ' 700',
                     fontSize: ' 17px',
                     lineHeight: '30px',
                     /* identical to box height */

                     letterSpacing: '-0.02em',

                     color: '#073F74',
                  }}>
                     Reset Password
                  </b> <br />
                  <small>
                     Enter your email address to reset your password, a one-time-password will be sent to your registered email.
                  </small>
                  <br />

                  <br />
                  <FormControl style={{ width: "100%", marginTop: "40px" }}>
                     <InputLabel htmlFor="component-outlined">Email Adress</InputLabel>
                     <OutlinedInput
                        style={inputStyle}
                        id="component-outlined"
                        onChange={(e) => {
                           setEmail(e.target.value);
                        }}
                        value={email}
                        required
                        label="Email Adress"
                     />
                  </FormControl>
                  <br />
                  {compState.error == true && <><div style={{ color: "crimson", textAlign: "center", marginTop: "" }}>{compState.msg}</div></>}
                  <br /><br />
                  <button
                     type="button"
                     style={{
                        background: "#0C1825",
                        color: "white",
                        border: "none",
                        outline: "none",
                        padding: "7px 50px",
                        marginTop: "5px",
                        borderRadius: "6px",
                        width: "100%",
                        height: "42px"
                     }}
                     onClick={(e) => {
                        login();
                     }}
                  >
                     {" "}
                     Verify Email
                  </button>
               </>}
         </form>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      appState: state.user,
   };
};

const mapDispatchToProps = (dispatch, encoded) => {
   return {
      set_session: (time) => dispatch(disp_session(time)),
      log_out: () => dispatch(logOut()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
