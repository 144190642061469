import { supabase } from '../configurations/index';
const newSupabase = supabase()
 

export async function updateWalletAfterTopupService(payload) {
   return newSupabase
      .from("userMetadata")
      .update({
         wallet: payload.amount, 
      })
   .eq("buzzID", payload.user)
}

export async function saveTopupHistory(payload) {
  return newSupabase.from("topup").insert([
    {
      user: payload.user,
      amount: payload.amount,
      meta: payload.meta,
    },
  ]);
}
 