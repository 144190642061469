import { supabase } from '../configurations/index';
const newSupabase = supabase()


export const allTransactionsService = (userBuzzID) => {
   return newSupabase.from("transactions")
      .select("*")
      .or(`to.eq.${userBuzzID},from.eq.${userBuzzID}`)
   .order('id', { ascending: false })
}

export async function updateBuzzRequestService(payload) {
   return newSupabase
      .from("transactions")
      .update({
         meta: payload.meta
      })
   .eq("id", payload.id)
}



export async function moveBuzzBalanceService(payload) {
   return newSupabase
      .from("userMetadata")
      .update({
         wallet: payload.wallet,
         buzzme_wallet: payload.buzzme_wallet
      })
   .eq("buzzID", payload.user)
}

// @======== GET ALL BUZZ ME REQARDING TO A USER
export async function transactionHistoryService(userId) {
  return newSupabase
    .from("transactions")
    .select("*")
    .or(`from.eq.${userId},to.eq.${userId}`);
}